<template>
  <div class="container-fluid datasets">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-primary">Consumer View</h1>
        <div class="alert alert-info" role="alert">
          This page allows to consume data assets.<br>
          The offer has to be accepted first to get a contract with the provider and start the data transfer.
        </div>
        <h3>Step 1 - Accept Contract Offer</h3>
        <p>If the fields in Step 1 are empty just navigate to Explore > Datasets and select a dataset from the catalogue.
          Then, the form below is prefilled with values of the chosen dataset.
        </p>
        <form>
          <div class="form-group">
            <label for="consumerIP">Consumer IP Address with Port (e.g. 'https://consumer.possible-project.com:443')</label>
            <input
              type="text"
              class="form-control"
              id="consumerIP"
              v-model="consumerIP"
            />
          </div>
          <div class="form-group">
            <label for="offerID">Offer ID</label>
            <input
              type="text"
              class="form-control"
              id="offerID"
              v-model="offerID"
            />
          </div>
          <div class="form-group">
            <label for="assetID">Asset ID</label>
            <input
              type="text"
              class="form-control"
              id="assetID"
              v-model="assetID"
            />
          </div>
          <div class="form-group">
            <label for="policyID">Policy ID</label>
            <input
              type="text"
              class="form-control"
              id="policyID"
              v-model="policyID"
            />
          </div>
          <div class="form-group">
            <label for="fileName">File Name</label>
            <input
              type="text"
              class="form-control"
              id="fileName"
              v-model="fileName"
            />
          </div>
        </form>
        <button class="btn btn-primary mb-3" @click="acceptContract">Accept Contract Offer</button>
        <div v-if="preview" class="alert alert-success"> {{ preview }}</div>
        <h3>Step 2 - Contract Agreement ID</h3>
        <p>After the offer has been accepted provider and consumer have a contract agreement.
          The ID is generated in the background and required for a proper data transfer.</p>
        <button class="btn btn-primary mb-3" @click="getAgreementID">Get Contract Agreement ID</button>
        <div v-if="previewContractAgreement" class="alert alert-success"> {{ previewContractAgreement }}</div>

        <h3>Step 3 - File Transfer</h3>
        <p>Finally, an existing destination to store the transferred data has to be specified.
          If all parameters where set correctly, you should find the submitted file in the defined storage.</p>
        <form>
          <div class="form-group">
            <label for="consumerBucket">Consumer Bucket</label>
            <input
              type="text"
              class="form-control"
              id="consumerBucket"
              v-model="consumerBucket"
            />
          </div>
        </form>
        <button class="btn btn-primary mb-3" @click="transferFile">Start File Transfer</button>
        <div v-if="previewConsumerBucket" class="alert alert-success"> {{ previewConsumerBucket }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "Consumer",
  data() {
    return {
      result: null,
      contentType: "application/json",
      preview: null,
      previewFileTransfer: null,
      previewContractAgreement: null,
      assetID: this.$route.query.assetID,
      policyID: this.$route.query.policyID,
      offerID: this.$route.query.contractOfferID,
      consumerIP: null,
      consumerBucket: null,
      previewConsumerBucket: null,
      contractNegotiationID: null,
      contractAgreementID: null,
      transferProcessID: null,
      fileName: null,
      config: {
        headers: {
            'X-API-Key': this.$env.edc.password,
            'content-type': "application/json"
        }
      },
      permissionEdctype: null,
    };
  },
  methods: {
    acceptContract() {
      var consumerUrl = this.consumerIP + "/management/v2/contractnegotiations"

      var payload = {
        "@context": {
          "@vocab": "https://w3id.org/edc/v0.0.1/ns/",
          "edc": "https://w3id.org/edc/v0.0.1/ns/",
          "odrl": "http://www.w3.org/ns/odrl/2/"
        },
        "@type": "edc:NegotiationInitiateRequestDto",
        "edc:connectorId": "provider",
        "edc:connectorAddress":  this.$env.edc.providerUrl + "protocol",
        "edc:protocol": "dataspace-protocol-http",
        "edc:providerId": "provider",
        "edc:offer": {
          "@type": "edc:ContractOfferDescription",
          "edc:offerId": this.offerID,
          "edc:assetId": this.assetID,
          "edc:policy": {
            "@id": this.policyID,
            "@type": "odrl:Set",
            "odrl:permission": {
              "odrl:target": this.assetID,
              "odrl:action": {
                "odrl:type": "USE"
              }
            },
            "odrl:prohibition": [],
            "odrl:obligation": [],
            "odrl:target": this.assetID
          },
        }
      }
      axios.post(consumerUrl, payload, this.config).then((response) => {
        this.preview = "Contract Offer accepted"
        this.contractNegotiationID = response.data["@id"]
      });
    },
    getAgreementID() {
      let intervalId = null;
      var counter = 0;
      this.previewContractAgreement = "Checking for Agreement"
      const getID = async() => {
        counter++;
        var consumerUrl = this.consumerIP + "/management/v2/contractnegotiations/" + this.contractNegotiationID

        axios.get(consumerUrl, this.config).then((response) => {
          this.previewContractAgreement = response.data
          if(response.data["edc:state"] === "FINALIZED") {
            clearInterval(intervalId);
            this.previewContractAgreement = "Agreement complete"
            this.contractAgreementID = response.data["edc:contractAgreementId"]
          }
          if(response.data["edc:state"] === "TERMINATED") {
            clearInterval(intervalId);
            this.previewContractAgreement = "Agreement failed"
          }
          if (counter >= 10)
          {
            clearInterval(intervalId);
          }
        });
      }
      intervalId = setInterval(getID, 1000);

    },
    transferFile() {
      var consumerUrl = this.consumerIP + "/management/v2/transferprocesses"

      var payload = {
        "@context": {
          "@vocab": "https://w3id.org/edc/v0.0.1/ns/",
          "edc": "https://w3id.org/edc/v0.0.1/ns/",
          "odrl": "http://www.w3.org/ns/odrl/2/"
        },
        "@type": "edc:TransferRequestDto",
        "edc:connectorId": "provider",
        "edc:connectorAddress": this.$env.edc.providerUrl + "protocol",
        "edc:contractId": this.contractAgreementID,
        "edc:protocol": "dataspace-protocol-http",
        "edc:assetId": this.assetID,
        "edc:managedResources": false,
        "edc:dataDestination": {
          "@type": "edc:DataAddress",
          "edc:blobName": this.fileName,
          "edc:bucketName": this.consumerBucket,
          "edc:container": this.consumerBucket,
          "edc:keyName": this.fileName,
          "edc:name": this.fileName,
          "edc:storage": "s3-eu-central-1.ionoscloud.com",
          "edc:type": "IonosS3"
        }
      }

      axios.post(consumerUrl, payload, this.config).then((response) => {
        this.previewConsumerBucket = "Init Transfer"
        this.transferProcessID = response.data["@id"]

        let intervalId = null;
        var counter = 0;
        const checkStatus = async() => {
          counter++;
          var checkUrl = this.consumerIP + "/management/v2/transferprocesses/" + this.transferProcessID;

          axios.get(checkUrl, this.config).then((response) => {
            if(response.data["edc:state"] === "COMPLETED") {
              clearInterval(intervalId);
              this.previewConsumerBucket = "Transfer complete"
            }
            if(response.data["edc:state"] === "TERMINATING") {
              clearInterval(intervalId);
              this.previewConsumerBucket = "Transfer failed"
            }
            if (counter >= 10)
            {
              this.previewConsumerBucket = "Transfer failed"
              clearInterval(intervalId);
            }
          });
        }
        intervalId = setInterval(checkStatus, 1000);

      });

    }
  },
};
</script>
