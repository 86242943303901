<template>
  <div
  :type="getDistributionFormat(distribution)"
  :data-toggle="distributionFormatTruncated(distribution) ? 'tooltip' : false"
  :data-placement="distributionFormatTruncated(distribution) ? 'top' : false"
  >
    <span>{{ truncate(getDistributionFormat(distribution), 10, true) }}</span>
  </div>
    <!-- :title="distributionFormatTruncated(distribution) ? getDistributionFormat(distribution) : false" -->
</template>

<script>
import { truncate } from '@piveau/piveau-hub-ui-modules';
export default {
  name: "DistributionFormat",
  props: [
    'distribution',
    'getDistributionFormat',
    'distributionFormatTruncated',
  ],
  methods: {
    truncate
  }
}
</script>

<style lang="scss" scoped>
  div {
    @apply text-sm bg-primary-700 max-w-max px-2 pt-1 text-white;
  }
</style>
