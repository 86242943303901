<template>
  <div class="distribution-details">
    <h3>{{ getDistributionTitle(distribution) }}</h3>
    <!-- <distribution-description
      :distribution="distribution"
      :distributions="distributions"
      :distributionDescriptionIsExpanded="distributionDescriptionIsExpanded"
      :getDistributionDescription="getDistributionDescription"
      :toggleDistributionDescription="toggleDistributionDescription"
      :distributionDescriptionIsExpandable="distributionDescriptionIsExpandable"
    /> -->
    <!-- <distribution-expanded-content
      :distribution="distribution"
      :distributionIsExpanded="distributionIsExpanded"
      :showLicensingAssistant="showLicensingAssistant"
      :showLicence="showLicence"
      :filterDateFormatEU="filterDateFormatEU"
      :showArray="showArray"
      :showNumber="showNumber"
      :showObject="showObject"
      :showObjectArray="showObjectArray"
      :appendCurrentLocaleToURL="appendCurrentLocaleToURL"
    /> -->
    <!-- <distribution-visible-content
      :distribution="distribution"
      :distributionIsExpanded="distributionIsExpanded"
      :showObject="showObject"
      :showLicence="showLicence"
      :showLicensingAssistant="showLicensingAssistant"
      :filterDateFormatEU="filterDateFormatEU"
    /> -->
    <!-- <distribution-expand
      :distribution="distribution"
      :distributionCanShowMore="distributionCanShowMore"
      :toggleDistribution="toggleDistribution"
      :distributionIsExpanded="distributionIsExpanded"
    /> -->
  </div>
</template>

<script>
import {
  DistributionDescription, DistributionExpandedContent, DistributionExpand
} from '@piveau/piveau-hub-ui-modules';

import DistributionVisibleContent from "./DistributionVisibleContent";

export default {
  name: "DistributionDetails",
  components: {
    DistributionExpand,
    DistributionVisibleContent,
    DistributionExpandedContent,
    DistributionDescription
  },
  props: [
    "getDistributionTitle",
    "distribution",
    "distributions",
    "distributionDescriptionIsExpanded",
    "getDistributionDescription",
    "toggleDistributionDescription",
    "distributionDescriptionIsExpandable",
    "distributionIsExpanded",
    "showLicensingAssistant",
    "showLicence",
    "filterDateFormatEU",
    "showArray",
    "showNumber",
    "showObject",
    "showObjectArray",
    "appendCurrentLocaleToURL",
    "distributionCanShowMore",
    "toggleDistribution"
  ]
}
</script>

<style lang="scss" scoped>
  .distribution-details {
    h3 {
      @apply text-3xl text-primary-700;
    }
  }
</style>