<template>
  <div>
    <span>{{ getDistributionLicence(distribution) }}</span>
  </div>
</template>

<script>
export default {
  name: "DistributionLicence",
  props: [
    'distribution',
    'getDistributionLicence',
  ]
}
</script>

<style lang="scss" scoped>
  div {
    @apply text-primary-700;
  }
</style>
