<template>
  <div class="datasets-wrapper">
    <datasets-top-controls
      :facets="facets"
      :getPage="getPage"
      :getLimit="getLimit"
      class="datasets-top-controls"
      v-if="useFeed"
    />
    <div class="dataset-container">
      <div class="dataset-container--filter">
        <datasets-facets v-if="useDatasetFacets" :dataScope="dataScope"></datasets-facets>
      </div>
      <div class="dataset-container--sets">
        <div class="dataset-container--header" v-if="!getLoading">
          <h1 v-if="showCatalogDetails">{{ getTranslationFor(getCatalog.title, $route.query.locale, getCatalog.languages) }}</h1>
          <h1 v-else>{{ $t('message.header.navigation.data.datasets') }}</h1>
          <datasets-filters></datasets-filters>
          <div class="datasets-infobar" role="status">
            <div>
              {{ getLoading ? $t('message.datasets.loadingMessage'):`${$t('message.datasets.countMessage')} (${getDatasetsCount.toLocaleString('fi')})`}}
            </div>
            <div class="loading-spinner ml-3" v-if="getLoading"></div>
          </div>
        </div>
        <div class="selected-facets--overview">
          <selectedFacetsOverview v-if="getFacets" :selected-facets="getFacets" :available-facets="getAllAvailableFacets"></selectedFacetsOverview>
        </div>
        <div class="dataset-container--items">
          <template v-if="!getLoading">
            <pv-data-info-box
              v-for="dataset in getDatasets"
              :key="dataset.id"
              :to="`/datasets/${dataset.id}`"
              :src="getImg(getCatalogImage(dataset.catalog))"
              :dataset="{
                title: getTranslationFor(dataset.title, $route.query.locale, dataset.languages) || dataset.id,
                description:
                  getTranslationFor(dataset.description, $route.query.locale, dataset.languages),
                catalog: getTranslationFor(dataset.catalog.title, $route.query.locale, []),
                createdDate: dataset.releaseDate,
                updatedDate: dataset.modificationDate,
                formats: removeDuplicatesOf(dataset.distributionFormats).filter((format) => format.id || format.label),
              }"
              :description-max-length="1000"
              :data-cy="`dataset@${dataset.id}`"
              class="datasets-box"
            />
          </template>
          <div class="loading-spinner mx-auto mt-3 mb-3" v-if="getLoading"></div>
        </div>
        <div class="dataset-container--pagination">
          <pagination class="mt-3"
            :items-count="getDatasetsCount"
            :items-per-page="getLimit"
            :get-page="getPage"
            :get-page-count="getPageCount"
            @setPageLimit="setPageLimit"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import {
    debounce,
    has,
    groupBy,
    uniqBy,
    toPairs,
    isArray,
    isNil,
  } from 'lodash-es';
  import $ from 'jquery';

  import { AppLink, truncate, getImg, fileTypes } from '@piveau/piveau-hub-ui-modules';

  import { getTranslationFor } from '../utils/helpers';

  import DatasetsFacets from './DatasetsFacets'
  import DatasetsFilters from './DatasetsFilters'
  import DatasetsTopControls from './DatasetsTopControls'
  import PvDataInfoBox from './PvDataInfoBox'
  import Pagination from '../widgets/Pagination'
  import SelectedFacetsOverview from './datasetsFacets/SelectedFacetsOverview'

  export default {
    name: 'Datasets',
    dependencies: ['DatasetService'],
    components: {
      DatasetsFacets,
      DatasetsFilters,
      DatasetsTopControls,
      AppLink,
      SelectedFacetsOverview,
      Pagination,
      PvDataInfoBox
    },
    props: {
      infiniteScrolling: {
        type: Boolean,
        default: false,
      },
    },
    metaInfo() {
      return {
        title: this.currentSearchQuery ? `${this.currentSearchQuery}` : `${this.$t('message.header.navigation.data.datasets')}`,
        meta: [
          { name: 'description', vmid: 'description', content: `${this.$t('message.datasets.meta.description')}` },
          { name: 'keywords', vmid: 'keywords', content: `${this.$env.metadata.keywords} ${this.$t('message.datasets.meta.description')}}` },
          { name: 'robots', content: 'noindex, follow' },
        ],
      };
    },
    data() {
      return {
        baseUrl: this.$env.api.baseUrl,
        debouncedOnBottomScroll: debounce(this.onBottomScroll, 500),
        facetFields: [],
        lang: this.locale,
        filterCollapsed: true,
        catalogAllowed: false,
        useFeed: this.$env.content.useFeed,
        useDatasetFacets: this.$env.content.datasets.facets.useDatasetFacets
      };
    },
    computed: {
      ...mapGetters('catalogDetails', [
        'getCatalog',
      ]),
      ...mapGetters('datasets', [
        'getDatasets',
        'getDatasetsCount',
        'getFacets',
        'getLimit',
        'getLoading',
        'getOffset',
        'getPage',
        'getPageCount',
        'getAvailableFacets',
        'getAllAvailableFacets',
        'getMinScoring',
      ]),
      showCatalogDetails() {
        return !isNil(this.$route.params.ctlg_id);
      },
      /**
       * @description Returns the current page.
       * @returns {Number}
       *
       * @deprecated use getPage from datasets store instead
       */
      page() {
        return this.$route.query.page;
      },
      /**
       * @description Returns the active facets.
       * @returns {Object}
       */
      facets() {
        const facets = {};
        for (const field of this.facetFields) {
          let urlFacets;
          if (field === 'catalog' && !isNil(this.$route.params.ctlg_id)) urlFacets = this.$route.params.ctlg_id;
          else urlFacets = this.$route.query[field];
          if (!urlFacets) urlFacets = [];
          else if (!Array.isArray(urlFacets)) urlFacets = [urlFacets];
          facets[field] = urlFacets;
        }
        return facets;
      },
      currentSearchQuery() {
        return this.$route.query.query;
      },
      showScoreDisclaimer() {
        return this.getMinScoring > 0;
      },
      dataScope() {
        if (!this.$route.query.dataScope) return null;
        if (isArray(this.$route.query.dataScope) && this.$route.query.dataScope.length > 0) return this.$route.query.dataScope[0];
        if (isArray(this.$route.query.dataScope) && this.$route.query.dataScope.length === 0) return null;
        return this.$route.query.dataScope;
      },
    },
    methods: {
      isNil,
      ...mapActions('datasets', [
        'loadDatasets',
        'loadAdditionalDatasets',
        'setPage',
        'useService',
        'addFacet',
        'removeFacet',
        'setFacets',
        'setFacetOperator',
        'setFacetGroupOperator',
        'setDataServices',
        'setPageCount',
        'setLimit',
        'setLoading',
        'setDataScope',
      ]),
      // The imported Lodash has function. Must be defined in Methods so we can use it in template
      has,
      isArray,
      truncate,
      getTranslationFor,
      getImg,
      /**
       * @description Handler-function for the scroll event.
       */
      onScroll() {
        const items = this.$el.querySelectorAll('.dataset');
        const lastItem = items[items.length - 1];
        if (lastItem) {
          const lastItemPos = lastItem.getBoundingClientRect();
          if (lastItemPos.bottom - window.innerHeight <= 0) {
            this.debouncedOnBottomScroll();
          }
        }
      },
      /**
       * @description Handler-function when bottom of the page is reached.
       */
      onBottomScroll() {
        this.$nextTick(() => {
          this.$Progress.start();
          this.loadAdditionalDatasets()
            .then(() => {
              this.$Progress.finish();
            })
            .catch(() => {
              this.$Progress.fail();
            });
        });
      },
      /**
       * @description Cuts badge format string (max 8 chars)
       * @param label {String} - badge label or id (e.g. csv)
       */
      getBadgeFormat(label) {
        return this.truncate(label, 8, true);
      },
      /**
       * @description Removes the duplicates of the given array
       * @param array {Array} - The array to remove duplicates from
       * @returns {Array}
       */
      removeDuplicatesOf(array) {
        const correctedFormatArray = array.map(format => (
          {
            ...format,
            id: this.getBadgeFormat(format.id),
            label: this.getBadgeFormat(format.label),
          }
        ));
        // sorts after # of occurences (highest occurence first)
        // possibility #1
        const sortedArray = toPairs(groupBy(correctedFormatArray, 'id')).sort((a, b) => b[1].length - a[1].length);
        const onlyFormatObjectsArray = sortedArray.map(arr => arr[1][0]);
        // lodash uniqBy funtion removes duplicate id´s from array of objects
        const uniqById = uniqBy(onlyFormatObjectsArray, 'id');
        const uniqByIdAndLabel = uniqBy(uniqById, 'label');
        return uniqByIdAndLabel;
      },
      initLimit() {
        const limit = parseInt(this.$route.query.limit, 10);
        if (limit > 0) this.setLimit(limit);
      },
      setPageLimit(value) {
        this.setLimit(value);
        this.initDatasets();
      },
      initDataScope() {
        this.setDataScope(this.dataScope);
      },
      /**
       * @description Determines the current page.
       */
      initPage() {
        const page = parseInt(this.$route.query.page, 10);
        if (page > 0) this.setPage(page);
        else this.setPage(1);
      },
      /**
       * @descritption Initialize the active facets by checking the route parameters
       */
      initFacets() {
        const fields = this.$env.content.datasets.facets.defaultFacetOrder;
        for (const field of fields) {
          this.facetFields.push(field);
          // catalog is not in queries anymore, so we have to add to facets differently
          if (field === 'catalog' && !isNil(this.$route.params.ctlg_id)) {
            this.addFacet({ field, facet: this.$route.params.ctlg_id });
          }
          else if (!Object.prototype.hasOwnProperty.call(this.$route.query, [field])) {
            this.$router
              .replace({
                query: Object.assign({}, this.$route.query, { [field]: [] }),
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            for (const facet of this.$route.query[field]) {
              // do not add duplicates!
              if (!this.getFacets[field]?.includes(facet)) {
                this.addFacet({ field, facet });
              }
            }
          }
        }
      },
      initFacetOperator() {
        // Always set facet operator to AND when in catalog details mode
        if (this.showCatalogDetails) this.setFacetOperator('AND');
        else {
          const op = this.$route.query.facetOperator;
          if (op === 'AND' || op === 'OR') this.setFacetOperator(op);
        }
      },
      initFacetGroupOperator() {
        // The facetGroupOperator should be the same as the facetOperator
        // Always set facet operator to AND when in catalog details mode
        if (this.showCatalogDetails) this.setFacetGroupOperator('AND');
        else {
          const op = this.$route.query.facetOperator;
          if (op === 'AND' || op === 'OR') this.setFacetGroupOperator(op);
        }
      },
      /**
       * @descritption Initialize the active data services facet by checking the route parameters
       */
      initDataServices() {
        const ds = this.$route.query.dataServices;
        if (ds === 'true' || ds === 'false') this.setDataServices(ds);
        else {
          this.setDataServices('false');
        }
      },
      initDatasets() {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$Progress.start();
            this.loadDatasets({ locale: this.$route.query.locale })
              .then(() => {
                this.setPageCount(Math.ceil(this.getDatasetsCount / this.getLimit));
                this.$Progress.finish();
                $('[data-toggle="tooltip"]').tooltip({
                  container: 'body',
                });
              })
              .catch(() => {
                this.$Progress.fail();
              })
              .finally(() => this.$root.$emit('contentLoaded'));
          });
        });
      },
      initInfiniteScrolling() {
        if (this.infiniteScrolling) window.addEventListener('scroll', this.onScroll);
      },
      getFileTypeColor(format) {
        return fileTypes.getFileTypeColor(format);
      },
      getCatalogImage(catalog) {
        return this.$env.content.catalogs.useCatalogCountries
          ? `${this.$env.content.catalogs.defaultCatalogImagePath}/${has(catalog, 'country.id') ? catalog.country.id : this.$env.content.catalogs.defaultCatalogCountryID}`
          : `${this.$env.content.catalogs.defaultCatalogImagePath}/${has(catalog, 'id') ? catalog.id : this.$env.content.catalogs.defaultCatalogID}`;
      }
    },
    watch: {
      /**
       * @description Watcher for active facets
       */
      // eslint-disable-next-line object-shorthand
      facets: {
        handler(facets) {
          this.setFacets(facets);
        },
        deep: true,
      },
      // eslint-disable-next-line object-shorthand
      page(pageStr) {
        const page = parseInt(pageStr, 10);
        if (page > 0) this.setPage(page);
        else this.setPage(1);
      },
      dataScope: {
        handler() {
          this.initDataScope();
        },
        deep: true,
      },
    },
    created() {
      this.useService(this.DatasetService);
      this.initDataScope();
      this.initLimit();
      this.initPage();
      this.initFacetOperator();
      this.initFacetGroupOperator();
      this.initDataServices();
      this.initFacets();
      this.initDatasets();
      this.initInfiniteScrolling();
    },
    mounted() {
      // This is supposed to fix the browser issue (https://gitlab.fokus.fraunhofer.de/piveau/organisation/piveau-scrum-board/-/issues/2344)
      if (this.$route.query.refresh === 'true') {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.loadDatasets({ locale: this.$route.query.locale })
              .then(() => {
                this.$router.push({ query: { locale: this.$route.query.locale } });
              })
              .catch(() => {
                this.$Progress.fail();
              });
          });
        });
      }
    },
    beforeDestroy() {
      $('.tooltip').remove();
      if (this.infiniteScrolling) window.removeEventListener('scroll', this.onScroll);
    },
  };
</script>

<style lang="scss" scoped>
  .dataset-container {
    @apply flex w-full max-w-screen-2xl mx-auto xl:px-12;
  }

  .dataset-container--filter {
    @apply w-1/4 2xl:w-80 bg-primary-700 py-12;
  }

  .dataset-container--sets {
    @apply w-3/4 pt-12 pb-8;

    .dataset-container--header {
      @apply px-32;
      h1 {
        @apply text-primary-700 text-5xl font-bold mb-8;
      }

      .datasets-infobar {
        @apply text-primary-700 text-xl py-2 border-b-2 border-primary-700 my-8;
      }
    }

    .datasets-box {
      @apply px-32 pt-8 mb-8;

      &:hover {
        @apply bg-primary-100 shadow-md shadow-primary-400 transition-all duration-200;
      }
    }

    .dataset-container--pagination {
      @apply px-32;
    }

    .selected-facets--overview {
      @apply px-32 mb-8;
    }
  }

  .content {
    padding: 0 !important;
    margin: 0 !important;
    background-color: inherit !important;
  }
</style>
