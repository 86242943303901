import { has } from "lodash";
import { defineStore } from "pinia";
import axios from 'axios';

const defaultState = {
  resourceType: '', // SoftwareOffering | LegalPerson | ...
  id: '',
  name: '',
  description: '',
  details: {},
};


export const useResourceDetailsStore = defineStore('resourceDetails', {
  state: () => defaultState,
  getters: {
    getResourceType: (state) => state.resourceType,
    getID: (state) => state.id,
    getName: (state) => state.name,
    getDescription: (state) => state.description,
    getDetails: (state) => state.details
  },
  actions: {
    setInitialState() {
      this.$state = defaultState;
    },
    setResourceType(resourceType: string) {
      this.resourceType = resourceType;
    },
    loadDetails(baseUrl: string, endpoint: string, id: string) {

      // Reset state
      this.setInitialState();

      return new Promise((resolve, reject) => {
        this.id = id;
        const params = {};
        const reqStr = `${baseUrl}${endpoint}${id}`;
        axios.get(reqStr, {
          params,
        })
          .then((response) => {
            resolve(response);
            let details = response.data.result ? response.data.result : response.data;
            this.name = has(this.details, 'name') ? details.name : this.id;
            this.description = has(this.details, 'description') ? details.description : '';
            this.details = details;
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  }
})



/*** RESOURCE DETAILS - DATA STRUCTURE ***/
/*

// SoftwareOffering

{
  softwareOfferings: <string[]>[],
  aggregationOf: [],
  dataAccountExport: [{}],
  dataProtectionRegime: [],
  dependsOn: [],
  description: '',
  endpoint: [{}],
  id: '',
  keyword: [{}],
  name: '',
  offeredBy: '',
  policy: [],
  providedBy: '',
  provisionType: '',
  resource: '',
  serviceOfferingLocations: [],
  termsAndConditions: [{}],
}

// LegalPerson

{
  legalPerson: <string[]>[],
  parentOrganization: [],
  description: '',
  id: '',
  legalForm: '',
  subOrganization: [],
  registrationNumber: {
    leoCode: '',
    vatid: '',
    euid: '',
    local: '',
    eori: ''
  },
  leiCode": '',
  resource": '',
  legalAddress: {
    postalCode: [],
    countryName: '',
    locality: [],
    streetAddress: [],
    gps: ''
  },
  legalName: '',
  headquarterAddress: {
    postalCode: [],
    countryName: '',
    locality: [],
    streetAddress: [],
    gps: ''
  },
}

*/