<template>
    <div class="mt-5 resource-properties">
        <!-- RESOURCE DETAILS FEATURES -->
        <div class="col-12 mb-5 p-0 resource-properties-list" v-for="key in getResourceDetailsFeatures">
            <resource-details-feature
                :id="key"
                :title="$t(`message.datasetDetails.${key}`)"
                :featureKey="key"
            />
        </div>
        <!-- RESOURCE DETAILS ADDITIONAL -->
        <div class="col-12 mb-5 p-0 resource-properties-list">
            <resource-details-additional
                id="additionalInfo"
                :title="$t('message.datasetDetails.additionalInfo')"
                :featureKeys="getResourceDetailsAdditional"
            />
        </div>
    </div>
</template>
  
<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceDetailsStore } from "@/store/resourceDetailsStore";

import { isNil } from "lodash";
import { AppLink } from '@piveau/piveau-hub-ui-modules';

import ResourceDetailsFeature from './ResourceDetailsFeature'
import ResourceDetailsAdditional from './ResourceDetailsAdditional'

export default {
    name: "ResourceDetailsProperties",
    components: {
        AppLink,
        ResourceDetailsFeature,
        ResourceDetailsAdditional,
    },
    data() {
        return {
            specialKeys: [
                'id',
                'name',
                'description',
            ],
            featureKeys: [
                'distributions',
                'keyword',
            ],
        };
    },
    computed: {
        ...mapStores(useResourceDetailsStore),
        ...mapState(useResourceDetailsStore, [
            'getDetails', 
        ]),
        getResourceDetails() {
            return Object.keys(this.getDetails).filter(key => !isNil(this.getDetails[key]));
        },
        getResourceDetailsFeatures() {
            return this.getResourceDetails.filter(key => this.featureKeys.includes(key));
        },
        getResourceDetailsAdditional() {
            return this.getResourceDetails.filter(key => !this.featureKeys.includes(key) && !this.specialKeys.includes(key));
        },
    },
    methods: {
        isNil,
    },
    mounted() {}
}
</script>

<style scoped lang="scss">
</style>
  