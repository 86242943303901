<template>
  <div class="filters-group ds">
    <div class="row">
      <div class="col">
        <div class="input-group">
          <input type="text" class="form-control rounded-lg ds-input"
                :aria-label="$t('message.datasets.searchBar.placeholder')"
                :placeholder="$t('message.datasets.searchBar.placeholder')"
                v-model="query"
                @keyup.enter="changeQuery(query)"
                @click="autocompleteData.show = autocompleteData.suggestions.length > 0 && query.length > 0 ? !autocompleteData.show : false">
          <div class="input-group-append ml-2">
            <button class="btn btn-sm btn-primary d-flex align-items-center search-button ds-input" type="button" @click="changeQuery(query)">
              <i class="material-icons align-bottom">search</i>
            </button>
          </div>
          <div class="suggestion-list-group" v-if="autocompleteData.show">
            <ul class="list-group suggestion-list">
              <button class="list-group-item list-group-item-action"
                      v-for="suggestion in autocompleteData.suggestions"
                      :key="suggestion.id"
                      @click="handleSuggestionSelection(suggestion)">
                {{ getTranslationFor(suggestion.title, $route.query.locale, suggestion.languages) }}
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <ul class="d-flex justify-content-between flex-wrap-reverse nav nav-tabs" id="myTab" role="tablist">
          <div v-if="useTabs" class="d-flex cursor-pointer">
            <li v-for="item in tabItems"
                  class="nav-item mb-0" role="tab"
                  :title="$t(`message.tooltip.${item.id}`)"
                  data-toggle="tooltip"
                  data-placement="top">
                <router-link
                  :to="{name: item.name, query: { locale: $route.query.locale }}"
                  class="nav-link"
                  active-class="router-link-active"
                  role="presentation">
                     {{ $t(`message.header.navigation.data.${item.id}`) }}
                </router-link>
            </li>
          </div>
          <div v-if="useSort" class="btn-group border-1 mb-1 double-button" role="group" aria-label="Button group with nested dropdown">
            <button
              type="button"
              class="custom-button pl-2 pr-2 border-radius-start d-flex align-items-center inactive-styles"
              :class="{'button-color-grey': isSortSelectedLabelActive($t('message.sort.lastUpdated'))}"
              :title="$t('message.tooltip.lastModified')"
              data-toggle="tooltip"
              data-placement="top"
              @click="setSortMethod('modified', 'desc', $t('message.sort.lastModified'))"
            >
              {{ $t('message.sort.lastUpdated') }}
            </button>
            <button
              type="button"
              class="custom-middle-button pl-2 pr-2 d-flex align-items-center inactive-styles"
              :class="{'button-color-grey': isSortSelectedLabelActive($t('message.sort.relevance'))}"
              :title="$t('message.tooltip.relevance')"
              data-toggle="tooltip"
              data-placement="top"
              @click="setSortMethod('relevance', 'desc', $t('message.sort.relevance'))"
            >
              {{ $t('message.sort.relevance') }}
            </button>
            <div class="btn-group" role="group">
              <button v-if="isSortSelectedLabelInDropdown()" class="button-color-grey d-flex align-items-center custom-dropdown-button border-radius-end" id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-expanded="false">
                <div class="pl-2 h-100 d-flex align-items-center">
                  {{ sortSelectedLabel }}
                </div>
                <i class="pr-2 material-icons small-icon dropdown-icon">arrow_drop_down</i>
              </button>
              <button v-else class="d-flex align-items-center custom-dropdown-button border-radius-end inactive-styles" id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-expanded="false">
                <div class="pl-2">
                  {{ $t('message.catalogsAndDatasets.more') }}
                </div>
                <i class="pr-2 material-icons small-icon dropdown-icon">arrow_drop_down</i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
              <button class="dropdown-item" @click="setSortMethod('name', 'asc', $t('message.sort.nameAZ'))">
                {{ $t('message.sort.nameAZ') }}</button>
              <button class="dropdown-item" @click="setSortMethod('name', 'desc', $t('message.sort.nameZA'))">
                {{ $t('message.sort.nameZA') }}</button>
              <button class="dropdown-item" @click="setSortMethod('issued', 'desc', $t('message.sort.lastCreated'))">
                {{ $t('message.sort.lastCreated') }}</button>
              </ul>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useResourceStore } from "@/store/resourceStore";

import { getTranslationFor } from '@piveau/piveau-hub-ui-modules';

export default {
  name: "ResourceFilters",
  data() {
    return {
      query: '',
      autocompleteData: {
        suggestions: {},
        show: true,
      },
      useCatalogs: this.$env.content.useCatalogs,
      useTabs: this.$env.content.useTabs,
      useSort: this.$env.content.useSort,
      sortSelected: '',
      sortSelectedLabel: this.$t('message.sort.relevance'),
    }
  },
  computed: {
    tabItems() {
      let tabItems = [
        {
          id: 'datasets',
          name: 'Datasets',
        },
        {
          id: 'catalogues',
          name: 'Catalogues',
        },
      ];

      // Dynamically add resource nav items
      Object.keys(this.$env.content.resources).forEach(resourceType => {
        let resourceData = this.$env.content.resources[resourceType];

        tabItems.push({
          id: resourceType,
          name: resourceData.name,
        });
      });

      return tabItems;
    },
  },
  methods: {
    getTranslationFor,
    ...mapActions(useResourceStore, [
      'setQuery',
      'setSort',
    ]),
    initQuery() {
      let query = this.$route.query.query;
      if (!query) {
        query = '';
        this.setQuery('');
      } else {
        this.query = query;
        this.setQuery(query);
      }
    },
    changeQuery(query) {
      this.$router.replace({ query: { ...this.$route.query, query, page: 1 } })
      .catch(error => { console.error(error); });
      this.setQuery(query);
    },
    handleSuggestionSelection(suggestion) {
      /* eslint-disable no-underscore-dangle */
      this.$router.push(
        { path: this.$route.path.slice(-1) === '/' ? `${this.$route.path}${suggestion.idName}` : `${this.$route.path}/${suggestion.idName}` }
      ).catch(
        error => { console.error(error); }
      );
    },
    initSort() {
      let sort = this.$route.query.sort;
      if (sort) {
        sort = sort.split(',')[0].toLowerCase();
        if (sort.includes('name')) {
          if (sort.includes('desc')) {
            this.sortSelectedLabel = this.$t('message.sort.nameZA');
            this.setSortMethod('name', 'desc', this.$t('message.sort.nameZA'));
          } else {
            this.sortSelectedLabel = this.$t('message.sort.nameAZ');
            this.setSortMethod('name', 'asc', this.$t('message.sort.nameAZ'));
          }
        } else {
          if (sort === 'relevance+desc') {
            this.sortSelectedLabel = this.$t('message.sort.relevance');
            this.setSortMethod('relevance', 'desc', this.$t('message.sort.relevance'));
          }
          if (sort === 'modified+desc') {
            this.sortSelectedLabel = this.$t('message.sort.lastUpdated');
            this.setSortMethod('modified', 'desc', this.$t('message.sort.lastUpdated'));
          }
          if (sort === 'issued+desc') {
            this.sortSelectedLabel = this.$t('message.sort.lastCreated');
            this.setSortMethod('issued', 'desc', this.$t('message.sort.lastCreated'));
          }
        }
      } else this.setSort(`relevance+desc, modified+desc, name+asc`);
    },
    setSortMethod(method, order, label) {
      this.sortSelectedLabel = label;

      if (method === 'relevance') this.sortSelected = `${method}+${order}, modified+desc, name+asc`;
      if (method === 'modified') this.sortSelected = `${method}+${order}, relevance+desc, name+asc`;
      if (method === 'name') this.sortSelected = `${method}+${order}, relevance+desc, modified+desc`;
      if (method === 'issued') this.sortSelected = `${method}+${order}, relevance+desc, name+asc`;

      this.$router.replace({ query: Object.assign({}, this.$route.query, { sort: this.sortSelected }) }).catch(() => {});
      this.setSort(this.sortSelected);

      return this.sortSelected;
    },
    isSortSelectedLabelActive(label) {
      if (label === this.sortSelectedLabel) return true;
      return false;
    },
    isSortSelectedLabelInDropdown() {
      if (this.sortSelectedLabel === this.$t('message.sort.nameAZ')
        || this.sortSelectedLabel === this.$t('message.sort.nameZA')
        || this.sortSelectedLabel === this.$t('message.sort.lastCreated')) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.initQuery();
    this.$nextTick(() => {
      this.initSort();
    });
  }
}
</script>