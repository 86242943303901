<template>
  <div class="download-container">
    <div class="download-container--button">
      <span>Linked actions</span>
      <i class="material-icons expand">expand_more</i>
      <i class="material-icons less">expand_less</i>
    </div>
    <div class="download-container--content">
      <ul>
        <li v-for="format in formats" :key="format.id">
          <app-link class="text-decoration-none" :to="createLinkedDataURL(format)" target="_blank" >
            <div>{{ format.label }}</div>
          </app-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AppLink from '../../../widgets/AppLink';

  export default {
    name: "LinkedDataButtonsDropdown",
    components: {AppLink},
    props: {
      distribution: Object,
      distributions: Object,
      resourceType: String
    },
    data: () => ({
      formats:
        [
          {id: "rdf", label: "RDF/XML"},
          {id: "ttl", label: "Turtle"},
          {id: "n3", label: "Notation3"},
          {id: "nt", label: "N-Triples"},
          {id: "jsonld", label: "JSON-LD"}
        ]
    }),
    methods: {
      createLinkedDataURL(format) {
        if (this.resourceType === 'distributions') {
          return `${this.$env.api.hubUrl}distributions/${this.distribution.id}.${format.id}`;
        } if (this.resourceType === 'datasets') {
          return `${this.$env.api.hubUrl}datasets/${this.distribution.id}.${format.id}?useNormalizedId=true&locale=${this.$route.query.locale}`;
        } if (this.resourceType === 'catalogues') {
          return `${this.$env.api.hubUrl}catalogues/${this.distribution.id}.${format.id}`;
        }
        return '';
      },
    }
  }
</script>

<style lang="scss" scoped>
  .download-container {
    @apply relative inline-block;

    &:hover {
      .download-container--content {
        @apply block;
      }
      .less {
        @apply block;
      }
      .expand {
        @apply hidden;
      }
    }

    .less {
      @apply hidden;
    }

    .download-container--button {
      @apply text-primary-700 cursor-pointer flex items-center;
    }
    .download-container--content {
      @apply hidden absolute max-w-max right-auto left-auto bg-white;

      ul {
        @apply px-4 py-2 w-48;

        li {
          @apply cursor-pointer border-b-2 border-primary-100 mb-2 pb-2 text-primary-700;

          &:hover {
            @apply border-primary-700;
          }

          &:last-child {
            @apply mb-0;
          }
        }
      }
    }
  }
</style>
