<template>
    <div class="resource-description">
      <p class="text-muted">{{ getDescription }}</p>
    </div>
  </template>
  
  <script>
  import { mapState, mapStores, mapActions } from "pinia";
  import { useResourceDetailsStore } from "@/store/resourceDetailsStore";
  
  export default {
    name: 'ResourceDetailsDescription',
    data() {
      return {};
    },
    computed: {
      ...mapStores(useResourceDetailsStore),
      ...mapState(useResourceDetailsStore, [
        'getDescription', 
      ]),
    },
    methods: {},
  };
  </script>
  
  