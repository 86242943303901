<template>
  <div class="dataset-description">
    {{ getDatasetDescription }}
  </div>
</template>

<script>

import { getTranslationFor, truncate } from "@piveau/piveau-hub-ui-modules";

import {mapGetters} from "vuex";

export default {
  name: "DatasetDetailsDescription",
  data() {
    return {
      isDatasetDescriptionExpanded: false,
      datasetDescriptionLength: 1500,
      INITIAL_DATASET_DESCRIPTION_LENGTH: 1500,
      MAX_DATASET_DESCRIPTION_LENGTH: 100000
    }
  },
  computed: {
    ...mapGetters('datasetDetails', [
      'getDescription',
      'getLanguages'
    ]),
    getDatasetDescription() {
      return getTranslationFor(this.getDescription, this.$route.query.locale, this.getLanguages);
    },
    getDatasetDescriptionLength() {
      return this.getDatasetDescription ? this.getDatasetDescription.length : 0;
    }
  },
  methods: {
    truncate,
    toggleDatasetDescription() {
      this.isDatasetDescriptionExpanded = !this.isDatasetDescriptionExpanded;
      if (this.datasetDescriptionLength === this.INITIAL_DATASET_DESCRIPTION_LENGTH) this.datasetDescriptionLength = this.MAX_DATASET_DESCRIPTION_LENGTH;
      else this.datasetDescriptionLength = this.INITIAL_DATASET_DESCRIPTION_LENGTH;
    }
  }
}
</script>

<style scoped>
  .dataset-description {
    @apply mb-16;
  }
</style>
