import { render, staticRenderFns } from "./ResourceTypeFacet.vue?vue&type=template&id=4482908f&scoped=true&"
import script from "./ResourceTypeFacet.vue?vue&type=script&lang=js&"
export * from "./ResourceTypeFacet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4482908f",
  null
  
)

export default component.exports