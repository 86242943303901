<template>
    <div class="container-fluid datasets">
        <div class="row">
            <div class="col-12">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BasePage"
}
</script>

<style>

</style>