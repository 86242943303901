<template>
  <div class="dataset-properties" ref="resourceProperties">
    <!-- <h5>{{ $t('message.datasetDetails.additionalInfo') }}</h5> -->
    <div data-cy="additional-information" v-show="infoVisible">
      <div class="property-wrapper" v-if="showArray(getLandingPagesResource)">
        <div class="property-label">
           {{ $t('message.metadata.landingPage') }}
        </div>
        <div class="property-info">
          <div v-for="(landingPage, i) of getLandingPagesResource" :key="i">
            <app-link v-if="!isNil(landingPage)" :to="landingPage">{{ truncate(landingPage, 75) }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getSources)">
        <div class="property-label">
           {{ $t('message.metadata.sources') }}
        </div>
        <div class="property-info">
          <div v-for="(source, i) of getSources" :key="i">
            <app-link v-if="!isNil(source) && isString(source)" :to="source">{{ source }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getLanguages)">
        <div class="property-label">
           {{ $t('message.metadata.languages') }}
        </div>
        <div class="property-info">
          <div v-for="(language, i) of getLanguages" :key="i">
            <app-link v-if="!isNil(language) && isString(language.label) && isString(language.resource)" :to="language.resource">{{ language.label }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getPublisher)">
        <div class="property-label">
           {{ $t('message.metadata.publisher') }}
        </div>
        <div class="property-info">
          <div v-if="has(getPublisher, 'name') && !isNil(getPublisher.name)">
            {{ $t('message.metadata.name') }}:
            {{ getPublisher.name }}
          </div>
          <div v-if="has(getPublisher, 'email') && !isNil(getPublisher.email)">
            {{ $t('message.metadata.email') }}:
            <app-link :to="`mailto:${removeMailtoOrTel(getPublisher.email)}`">{{ removeMailtoOrTel(getPublisher.email) }}</app-link>
          </div>
          <div v-if="has(getPublisher, 'homepage') && !isNil(getPublisher.homepage)">
            {{ $t('message.metadata.homepage') }}:
            <a :href="getPublisher.homepage">{{ truncate(getPublisher.homepage, 75) }}</a>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getContactPoints) && showContactPoint(getContactPoints)">
        <div class="property-label">
           {{ $t('message.metadata.contactPoints') }}
        </div>
        <div class="property-info">
          <div v-for="(contactPoint, i) in getContactPoints" :key="i">
            <div v-if="has(contactPoint, 'name') && !isNil(contactPoint.name)">
              {{ $t('message.datasetDetails.contactPoints.organizationName') }}:
              {{contactPoint.name}}
            </div>
            <div v-if="has(contactPoint, 'email') && !isNil(contactPoint.email)">
              {{ $t('message.metadata.email') }}:
              <app-link :to="`mailto:${removeMailtoOrTel(contactPoint.email)}`">{{ removeMailtoOrTel(contactPoint.email) }}</app-link>
            </div>
            <div v-if="has(contactPoint, 'telephone') && !isNil(contactPoint.telephone)">
              {{ $t('message.metadata.telephone') }}:
              <app-link :to="`tel:${removeMailtoOrTel(contactPoint.telephone)}`">{{ removeMailtoOrTel(contactPoint.telephone) }}</app-link>
            </div>
            <div v-if="has(contactPoint, 'address') && !isNil(contactPoint.address)">
              {{ $t('message.metadata.address') }}:
              {{contactPoint.address}}
            </div>
            <div v-if="has(contactPoint, 'url') && showArray(contactPoint.url)">
              URL:
              <div class="ml-1">
                <div v-for="(url, i) of contactPoint.url" :key="i">
                  <div v-if="showString(url)">
                    <app-link :to="url">{{ truncate(url, 75) }}</app-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getPoliticalGeocodingLevelURI)">
        <div class="property-label">
           {{ $t('message.metadata.politicalGeocodingLevelURI') }}
        </div>
        <div class="property-info">
          <div v-if="has(element, 'label') && !isNil(element.label)"> {{ $t('message.metadata.label') }}: {{ element.label }}</div>
          <div v-if="has(element, 'resource') && !isNil(element.resource)"> {{ $t('message.metadata.resource') }}: <a :href="element.resource">{{ element.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getPoliticalGeocodingURI)">
        <div class="property-label">
          {{ $t('message.metadata.politicalGeocodingURI') }}
        </div>
        <div class="property-info">
          <div v-if="has(element, 'label') && !isNil(element.label)"> {{ $t('message.metadata.label') }}: {{ element.label }}</div>
          <div v-if="has(element, 'resource') && !isNil(element.resource)"> {{ $t('message.metadata.resource') }}: <a :href="element.resource">{{ element.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getAvailability)">
        <div class="property-label">
          {{ $t('message.metadata.politicalGeocodingURI') }}
        </div>
        <div class="property-info">
          <div v-if="has(getAvailability, 'label') && !isNil(getAvailability.label)"> {{ $t('message.metadata.label') }}: {{ getAvailability.label }}</div>
          <div v-if="has(getAvailability, 'resource') && !isNil(getAvailability.resource)"> {{ $t('message.metadata.resource') }}: <a :href="getAvailability.resource">{{ getAvailability.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getContributorID)">
        <div class="property-label">
          {{ $t('message.metadata.contributorID') }}
        </div>
        <div class="property-info">
          <div v-if="has(element, 'label') && !isNil(element.label)"> {{ $t('message.metadata.label') }}: {{ element.label }}</div>
          <div v-if="has(element, 'resource') && !isNil(element.resource)"> {{ $t('message.metadata.resource') }}: <a :href="element.resource">{{ element.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getGeocodingDescriptionDe)">
        <div class="property-label">
          {{ $t('message.metadata.geocodingDescription') }}
        </div>
        <div class="property-info">
          {{ getTranslationFor(getGeocodingDescriptionDe) }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getLegalBasis)">
        <div class="property-label">
          {{ $t('message.metadata.legalBasis') }}
        </div>
        <div class="property-info">
          {{ getTranslationFor(getLegalBasis) }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getQualityProcessURI)">
        <div class="property-label">
          {{ $t('message.metadata.qualityProcessURI') }}
        </div>
        <div class="property-info">
          <a :href="getQualityProcessURI">{{ getQualityProcessURI }}</a>
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getTypeDe)">
        <div class="property-label">
          {{ $t('message.metadata.type') }}
        </div>
        <div class="property-info">
          {{ getTypeDe }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getReferences)">
        <div class="property-label">
          {{ $t('message.metadata.references') }}
        </div>
        <div class="property-info">
          <a :href="getReferences">{{ getReferences }}</a>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getContributor)">
        <div class="property-label">
          {{ $t('message.metadata.contributor') }}
        </div>
        <div class="property-info">
          <div v-if="has(element, 'name') && !isNil(element.name)"> {{ $t('message.metadata.name') }}: {{ element.name }}</div>
          <div v-if="has(element, 'type') && !isNil(element.type)"> {{ $t('message.metadata.type') }}: {{ element.type }}</div>
          <div v-if="has(element, 'homepage') && !isNil(element.homepage)"> {{ $t('message.metadata.homepage') }}: <a :href="element.homepage">{{ element.homepage }}</a></div>
          <div v-if="has(element, 'email') && !isNil(element.email)"> {{ $t('message.metadata.email') }}: <a :href="'mailto:' + element.email">{{ element.email }}</a></div>
          <div v-if="has(element, 'resource') && !isNil(element.resource)"> {{ $t('message.metadata.resource') }}: <a :href="element.resource">{{ element.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getOriginator)">
        <div class="property-label">
          {{ $t('message.metadata.originator') }}
        </div>
        <div class="property-info">
          <div v-if="has(element, 'name') && !isNil(element.name)"> {{ $t('message.metadata.name') }}: {{ element.name }}</div>
          <div v-if="has(element, 'type') && !isNil(element.type)"> {{ $t('message.metadata.type') }}: {{ element.type }}</div>
          <div v-if="has(element, 'homepage') && !isNil(element.homepage)"> {{ $t('message.metadata.homepage') }}: <a :href="element.homepage">{{ element.homepage }}</a></div>
          <div v-if="has(element, 'email') && !isNil(element.email)"> {{ $t('message.metadata.email') }}: <a :href="'mailto:' + element.email">{{ element.email }}</a></div>
          <div v-if="has(element, 'resource') && !isNil(element.resource)"> {{ $t('message.metadata.resource') }}: <a :href="element.resource">{{ element.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getMaintainer)">
        <div class="property-label">
          {{ $t('message.metadata.maintainer') }}
        </div>
        <div class="property-info">
          <div v-if="has(element, 'name') && !isNil(element.name)"> {{ $t('message.metadata.name') }}: {{ element.name }}</div>
          <div v-if="has(element, 'type') && !isNil(element.type)"> {{ $t('message.metadata.type') }}: {{ element.type }}</div>
          <div v-if="has(element, 'homepage') && !isNil(element.homepage)"> {{ $t('message.metadata.homepage') }}: <a :href="element.homepage">{{ element.homepage }}</a></div>
          <div v-if="has(element, 'email') && !isNil(element.email)"> {{ $t('message.metadata.email') }}: <a :href="'mailto:' + element.email">{{ element.email }}</a></div>
          <div v-if="has(element, 'resource') && !isNil(element.resource)"> {{ $t('message.metadata.resource') }}: <a :href="element.resource">{{ element.resource }}</a></div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getModificationDate)">
        <div class="property-label">
          {{ $t('message.metadata.updated') }}
        </div>
        <div class="property-info">
          {{ filterDateFormatEU(getModificationDate) }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getReleaseDate)">
        <div class="property-label">
          {{ $t('message.metadata.created') }}
        </div>
        <div class="property-info">
          {{ filterDateFormatEU(getReleaseDate) }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getCatalogRecord)">
        <div class="property-label">
          {{ $t('message.metadata.catalogRecord') }}
        </div>
        <div class="property-info">
          <div v-if="getCatalogRecord.issued" class="catalogue-label">{{ `${$t('message.metadata.addedToDataEuropaEU')}:\n${filterDateFormatEU(getCatalogRecord.issued)}` }}</div>
          <div v-if="getCatalogRecord.modified" class="catalogue-label" :class="{'mt-1': getCatalogRecord.issued}">{{ `${$t('message.metadata.updatedOnDataEuropaEU')}:\n${filterDateFormatEU(getCatalogRecord.modified)}` }}</div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getSpatial)">
        <div class="property-label">
          {{ $t('message.metadata.spatial') }}
        </div>
        <div class="property-info">
          <div v-for="(spatial, i) of getSpatial" :key="i">
            <div v-if="has(spatial, 'coordinates') && !isNil(spatial.coordinates)">
              {{ $t('message.metadata.coordinates') }}:
              {{ spatial.coordinates }}
            </div>
            <div v-if="has(spatial, 'type') && !isNil(spatial.type)">
              {{ $t('message.metadata.type') }}:
              {{ spatial.type }}
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getSpatialResource)">
        <div class="property-label">
          {{ $t('message.metadata.spatialResource') }}
        </div>
        <div class="property-info">
          <div v-for="(spatialResource, i) of getSpatialResource.map(s => s.resource || '')" :key="i">
            <app-link v-if="!isNil(spatialResource)" :to="spatialResource">{{ truncate(spatialResource, 75) }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getConformsTo)">
        <div class="property-label">
          {{ $t('message.metadata.conformsTo') }}
        </div>
        <div class="property-info">
          <div v-for="(conformTo, i) in getConformsTo" :key="i">
            <div v-if="has(conformTo, 'title') && !isNil(conformTo.title)">
              {{ $t('message.metadata.label') }}:
              {{ conformTo.title}}
            </div>
            <div v-if="has(conformTo, 'resource') && !isNil(conformTo.resource)">
              {{ $t('message.metadata.resource') }}:
              <app-link :to="conformTo.resource"
                        target="_blank"
                        @click="$emit('track-link', conformTo.resource, 'link')">
                {{ truncate(conformTo.resource, 75) }}
              </app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getProvenances)">
        <div class="property-label">
          {{ $t('message.metadata.provenances') }}
        </div>
        <div class="property-info">
          <div v-for="(provenance, i) in getProvenances" :key="i">
            <div v-if="has(provenance, 'label') && !isNil(provenance.label)">
              {{ $t('message.metadata.label') }}:
              {{ provenance.label }}
            </div>
            <div v-if="has(provenance, 'resource') && !isNil(provenance.resource)">
              {{ $t('message.metadata.resource') }}:
              <app-link :to="provenance.resource">{{ truncate(provenance.resource, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getRelatedResources)">
        <div class="property-label">
          {{ $t('message.metadata.relatedResources') }}
        </div>
        <div class="property-info">
          <div v-for="(resource, i) of getRelatedResources" :key="i">
            <app-link v-if="!isNil(resource)" :to="resource">{{ truncate(resource, 75) }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getIdentifiers)">
        <div class="property-label">
          {{ $t('message.metadata.identifiers') }}
        </div>
        <div class="property-info">
          <div v-for="(identifier, i) of getIdentifiers" :key="i">
            <app-link :to="appendCurrentLocaleToURL(identifier)" v-if="showString(identifier)">
              {{ truncate(identifier, 75) }}
            </app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getOtherIdentifiers)">
        <div class="property-label">
          {{ $t('message.metadata.otherIdentifiers') }}
        </div>
        <div class="property-info">
          <div v-for="(otherIdentifier, i) of getOtherIdentifiers" :key="i">
            <div  v-if="has(otherIdentifier, 'identifier') && !isNil(otherIdentifier.identifier)">
              {{ $t('message.metadata.identifier') }}:
              <app-link :to="otherIdentifier.resource || otherIdentifier.identifier">
                {{ otherIdentifier.identifier }}
              </app-link>
            </div>
            <div v-if="has(otherIdentifier, 'scheme') && !isNil(otherIdentifier.scheme)">
              {{ $t('message.metadata.scheme') }}:
              <app-link :to="otherIdentifier.scheme">
                {{ otherIdentifier.scheme }}
              </app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getResource)">
        <div class="property-label">
          URIref
        </div>
        <div class="property-info">
          <a :href="appendCurrentLocaleToURL(getResource)">{{ truncate(getResource, 75) }}</a>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getDocumentations)">
        <div class="property-label">
          {{ $t('message.metadata.documentations') }}
        </div>
        <div class="property-info">
          <div v-for="(documentation, i) of getDocumentations" :key="i">
            <app-link v-if="!isNil(documentation)" :to="documentation">{{ truncate(documentation, 75) }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getFrequency)">
        <div class="property-label">
          {{ $t('message.metadata.frequency') }}
        </div>
        <div class="property-info">
          <div v-if="has(getFrequency, 'title') && !isNil(getFrequency.title)">
            {{ getFrequency.title }}
          </div>
          <div v-if="has(getFrequency, 'resource') && !isNil(getFrequency.resource)">
            <app-link :to="getFrequency.resource">{{ truncate(getFrequency.resource, 75) }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getAccessRights)">
        <div class="property-label">
          {{ $t('message.metadata.accessRights') }}
        </div>
        <div class="property-info">
          <span v-if="has(getAccessRights, 'label') && !isNil(getAccessRights.label)">{{ getAccessRights.label }}</span>
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getAccrualPeriodicityLabel)">
        <div class="property-label">
          {{ $t('message.metadata.accrualPeriodicity') }}
        </div>
        <div class="property-info">
          <span v-if="!isNil(getAccrualPeriodicityLabel)">{{ getAccrualPeriodicityLabel }}</span>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getCreator)">
        <div class="property-label">
          {{ $t('message.metadata.creator') }}
        </div>
        <div class="property-info">
          <div v-if="has(getCreator, 'name') && !isNil(getCreator.name)">
            {{ $t('message.metadata.name') }}:
            {{ getCreator.name }}
          </div>
          <div v-if="has(getCreator, 'email') && !isNil(getCreator.email)">
            {{ $t('message.metadata.email') }}:
            <app-link :to="`mailto:${removeMailtoOrTel(getCreator.email)}`">{{ truncate(removeMailtoOrTel(getCreator.email), 75) }}</app-link>
          </div>
          <div v-if="has(getCreator, 'homepage') && !isNil(getCreator.homepage)">
            {{ $t('message.metadata.homepage') }}:
            <app-link :to="getCreator.homepage">{{ truncate(getCreator.homepage, 75) }}</app-link>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getHasVersion)">
        <div class="property-label">
          {{ $t('message.metadata.hasVersion') }}
        </div>
        <div class="property-info">
          <div v-for="(hasVersion, i) of getHasVersion" :key="i">
            <div v-if="!isNil(hasVersion) && isString(hasVersion)">
              <app-link :to="hasVersion">{{ truncate(hasVersion, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getIsVersionOf)">
        <div class="property-label">
          {{ $t('message.metadata.isVersionOf') }}
        </div>
        <div class="property-info">
          <div v-for="(isVersionOf, i) of getIsVersionOf" :key="i">
            <div v-if="!isNil(isVersionOf) && isString(isVersionOf)">
              <app-link :to="isVersionOf">{{ truncate(isVersionOf, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getTemporal)">
        <div class="property-label">
          {{ $t('message.metadata.temporal') }}
        </div>
        <div class="property-info">
          <div v-for="(temporal, i) of getTemporal" :key="i">
            <div v-if="has(temporal, 'gte') && !isNil(temporal.gte) && has(temporal, 'lte') && !isNil(temporal.lte)">{{ filterDateFormatEU(temporal.gte) }} - {{ filterDateFormatEU(temporal.lte) }}</div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showString(getVersionInfo)">
        <div class="property-label">
          {{ $t('message.metadata.versionInfo') }}
        </div>
        <div class="property-info">
          <span v-if="!isNil(getVersionInfo)">{{ getVersionInfo }}</span>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getVersionNotes)">
        <div class="property-label">
          {{ $t('message.metadata.versionNotes') }}
        </div>
        <div class="property-info">
          {{ getTranslationFor(getVersionNotes) }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getAttributes)">
        <div class="property-label">
          {{ $t('message.metadata.attributes') }}
        </div>
        <div class="property-info">
          <div v-for="(attribute, i) of getAttributes" :key="i">
            <div v-if="showString(attribute)">
              <app-link :to="attribute">{{ truncate(attribute, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getDimensions)">
        <div class="property-label">
          {{ $t('message.metadata.dimensions') }}
        </div>
        <div class="property-info">
          <div v-for="(dimension, i) of getDimensions" :key="i">
            <div v-if="showString(dimension)">
              <app-link :to="dimension">{{ truncate(dimension, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showNumber(getNumSeries)">
        <div class="property-label">
          {{ $t('message.metadata.numSeries') }}
        </div>
        <div class="property-info">
          {{ getNumSeries }}
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getHasQualityAnnotations)">
        <div class="property-label">
          {{ $t('message.metadata.qualityAnnotations') }}
        </div>
        <div class="property-info">
          <div v-for="(hasQualityAnnotation, i) of getHasQualityAnnotations" :key="i">
            <div v-if="showString(hasQualityAnnotation)">
              <app-link :to="hasQualityAnnotation">{{ truncate(hasQualityAnnotation, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getStatUnitMeasures)">
        <div class="property-label">
          {{ $t('message.metadata.unitsOfMeasurement') }}
        </div>
        <div class="property-info">
          <div v-for="(statUnitMeasure, i) of getStatUnitMeasures" :key="i">
            <div v-if="showString(statUnitMeasure)">
              <app-link :to="statUnitMeasure">{{ truncate(statUnitMeasure, 75) }}</app-link>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getIsReferencedBy)">
        <div class="property-label">
          {{ $t('message.metadata.isReferencedBy') }}
        </div>
        <div class="property-info">
          <div v-for="(reference, i) of getIsReferencedBy" :key="i">
            <div v-if="showString(reference)">
              <a :href="appendCurrentLocaleToURL(reference)">{{ truncate(reference, 75) }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getQualifiedAttributions)">
        <div class="property-label">
          {{ $t('message.metadata.qualifiedAttribution') }}
        </div>
        <div class="property-info">
          <div v-for="(qualifiedAttribution, i) of getQualifiedAttributions" :key="i">
            <div v-if="showString(qualifiedAttribution)">
              <a :href="appendCurrentLocaleToURL(qualifiedAttribution)">{{ truncate(qualifiedAttribution, 75) }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getWasGeneratedBy)">
        <div class="property-label">
          {{ $t('message.metadata.wasGeneratedBy') }}
        </div>
        <div class="property-info">
          <div v-for="(wasGeneratedBy, i) of getWasGeneratedBy" :key="i">
            <div v-if="showString(wasGeneratedBy)">
              <a :href="appendCurrentLocaleToURL(wasGeneratedBy)">{{ truncate(wasGeneratedBy, 75) }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObjectArray(getQualifiedRelations)">
        <div class="property-label">
          {{ $t('message.metadata.qualifiedRelation') }}
        </div>
        <div class="property-info">
          <div v-for="(qualifiedRelation, i) of getQualifiedRelations" :key="i">
            <div v-if="has(qualifiedRelation, 'relation') && !isNil(qualifiedRelation.relation)">
              {{ $t('message.metadata.relation') }}:
              <div v-for="(relation, i) of qualifiedRelation.relation" :key="i" class="d-inline-table">
                <div v-if="showString(relation)">
                  <a :href="appendCurrentLocaleToURL(relation)">{{ truncate(relation, 75) }}</a>
                </div>
              </div>
            </div>
            <div v-if="has(qualifiedRelation, 'had_role') && !isNil(qualifiedRelation.had_role)">
              {{ $t('message.metadata.role') }}:
              <div v-for="(role, i) of qualifiedRelation.had_role" :key="i" class="d-inline-table">
                <div v-if="showString(role)">
                  <a :href="appendCurrentLocaleToURL(role)">{{ truncate(role, 75) }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getSample)">
        <div class="property-label">
          {{ $t('message.metadata.sample') }}
        </div>
        <div class="property-info">
          <div v-for="(sample, i) of getSample" :key="i">
            <div v-if="showString(sample)">
              <a :href="appendCurrentLocaleToURL(sample)">{{ truncate(sample, 75) }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getSpatialResolutionInMeters)">
        <div class="property-label">
          {{ $t('message.metadata.spatialResolutionInMeters.label') }}
        </div>
        <div class="property-info">
          <div v-if="showNumber(getSpatialResolutionInMeters[0])">
            {{ $t('message.metadata.spatialResolutionInMeters.value', {number: getSpatialResolutionInMeters[0]}) }}
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showObject(getType)">
        <div class="property-label">
          {{ $t('message.metadata.type') }}
        </div>
        <div class="property-info">
          <div v-if="has(getType, 'label') && !isNil(getType.label)">
            {{ $t('message.metadata.label') }}:
            {{ getType.label }}
          </div>
          <div v-if="has(getType, 'resource') && !isNil(getType.resource)">
            {{ $t('message.metadata.resource') }}:
            <a :href="appendCurrentLocaleToURL(getType.resource)">{{ truncate(getType.resource, 75) }}</a>
          </div>
        </div>
      </div>
      <div class="property-wrapper" v-if="showArray(getSpatialResolutionInMeters)">
        <div class="property-label">
          {{ $t('message.metadata.temporalResolution') }}
        </div>
        <div class="property-info">
          <div v-for="(temporalResolution, i) of getTemporalResolution" :key="i">
            {{ formatDatetime(temporalResolution) }}
          </div>
        </div>
      </div>
      <div class="additional-information-overlay" ref="overlay" v-show="showMoreVisible && !expanded" v-if="useOverlay"></div>
    </div>
  </div>
</template>

<script>
import {isArray, isNil, isString, has} from "lodash";
import {mapGetters} from "vuex";
import { Tooltip, AppLink, truncate, getTranslationFor } from "@piveau/piveau-hub-ui-modules";
import DatasetDetailsFeatureHeader from "./features/DatasetDetailsFeatureHeader";
import { formatDatetime, removeMailtoOrTel, appendCurrentLocaleToURL } from '../utils/helpers';

// imported globally
// import DatasetDetailsFeatureHeader
//   from "@/modules/datasetDetails/features/DatasetDetailsFeatureHeader";
// import PvShowMore from "@/modules/widgets/PvShowMore";

export default {
  name: "DatasetDetailsProperties",
  components: {
    DatasetDetailsFeatureHeader,
    Tooltip, AppLink},
  props: {
    filterDateFormatEU: Function,
    showObjectArray: Function,
    showArray: Function,
    showObject: Function,
    showNumber: Function,
    showString: Function
  },
  data() {
    return {
      infoVisible: true,
      initialHeight: 0,
      restrictedHeight: 200,
      expanded: false,
      useOverlay: this.$env.content.datasetDetails.useOverlay,
    };
  },
  computed: {
    ...mapGetters('datasetDetails', [
      // DCAT-AP.de
      'getDataset',
      'getAvailability',
      'getPoliticalGeocodingLevelURI',
      'getPoliticalGeocodingURI',
      'getContributorID',
      'getGeocodingDescriptionDe',
      'getLegalBasis',
      'getQualityProcessURI',
      'getTypeDe',
      'getReferences',
      'getContributor',
      'getOriginator',
      'getMaintainer',
      //
      'getSources',
      'getAccessRights',
      'getAccrualPeriodicity',
      'getAttributes',
      'getCatalogRecord',
      'getConformsTo',
      'getContactPoints',
      'getCreator',
      'getDimensions',
      'getDocumentations',
      'getFrequency',
      'getHasQualityAnnotations',
      'getHasVersion',
      'getIdentifiers',
      'getIsVersionOf',
      'getIsReferencedBy',
      'getLandingPages',
      'getLanguages',
      'getModificationDate',
      'getNumSeries',
      'getOtherIdentifiers',
      'getProvenances',
      'getPublisher',
      'getRelatedResources',
      'getReleaseDate',
      'getResource',
      'getSample',
      'getSpatial',
      'getSpatialResolutionInMeters',
      'getSpatialResource',
      'getStatUnitMeasures',
      'getTemporal',
      'getTemporalResolution',
      'getType',
      'getVersionInfo',
      'getVersionNotes',
      'getQualifiedAttributions',
      'getQualifiedRelations',
      'getWasGeneratedBy'
    ]),
    // Provides resource data only of landing pages
    // Example: [{ format: 'bar', resource: 'foo' }, ...] -> ['foo']
    getLandingPagesResource() {
      return isArray(this.getLandingPages) && this.getLandingPages.map(value => value && value.resource);
    },
    // Returns the label property of accrual periodicity
    getAccrualPeriodicityLabel() {
      return !isNil(this.getAccrualPeriodicity) && has(this.getAccrualPeriodicity, 'label') ? this.getAccrualPeriodicity.label : '';
    },
    showMoreVisible() {
      return this.initialHeight > this.restrictedHeight;
    }
  },
  methods: {
    isNil,
    truncate,
    isString,
    has,
    removeMailtoOrTel,
    appendCurrentLocaleToURL,
    getTranslationFor,
    formatDatetime,
    toggleInfo() {
      this.infoVisible = !this.infoVisible;
    },
    showContactPoint(contactPoints) {
      return Object.keys(contactPoints[0]).filter(contactPoint => contactPoint !== 'resource' && contactPoint !== 'type').length > 0;
    },
    toggleExpanded() {
      this.expanded = ! this.expanded;
      // this.adaptHeight();
    },
    adaptHeight() {
      // this.$refs.resourceProperties.style['flex'] = this.expanded ? "0 0 100%": `0 0 ${this.restrictedHeight}px`;
      // this.$refs.resourceProperties.style['max-height'] = this.expanded ? "100%": `${this.restrictedHeight}px`;
      // this.$refs.resourceProperties.style['overflow-y'] = this.expanded ? "auto": "hidden";
    }
  },
  mounted() {
    // this.initialHeight = this.$refs.resourceProperties.clientHeight;
    // if (this.useOverlay) this.$refs.overlay.style.bottom = (this.$refs.resourceProperties.offsetHeight - this.$refs.resourceProperties.clientHeight) + "px";
    // this.adaptHeight();
  }
}
</script>

<style scoped lang="scss">
  .dataset-properties {
    @apply bg-primary-700 text-white px-12 py-16 overflow-y-scroll max-h-96;

    .property-wrapper {
      @apply grid gap-3 mb-4;
      grid-template-columns: 1fr 2fr;

      &:last-child{
        @apply mb-0;
      }

      .property-label {
        @apply break-all;
      }
      .property-info {
        @apply break-all;
      }
    }
  }

.arrow {
  cursor: pointer;
}

.additional-information-overlay {
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0, white 100%);
  pointer-events: none;
}
</style>
