var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datasets-wrapper"},[(_vm.useFeed)?_c('datasets-top-controls',{staticClass:"datasets-top-controls",attrs:{"facets":_vm.facets,"getPage":_vm.getPage,"getLimit":_vm.getLimit}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dataset-container"},[_c('div',{staticClass:"dataset-container--filter"},[(_vm.useDatasetFacets)?_c('datasets-facets',{attrs:{"dataScope":_vm.dataScope}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"dataset-container--sets"},[(!_vm.getLoading)?_c('div',{staticClass:"dataset-container--header"},[(_vm.showCatalogDetails)?_c('h1',[_vm._v(_vm._s(_vm.getTranslationFor(_vm.getCatalog.title, _vm.$route.query.locale, _vm.getCatalog.languages)))]):_c('h1',[_vm._v(_vm._s(_vm.$t('message.header.navigation.data.datasets')))]),_vm._v(" "),_c('datasets-filters'),_vm._v(" "),_c('div',{staticClass:"datasets-infobar",attrs:{"role":"status"}},[_c('div',[_vm._v("\n            "+_vm._s(_vm.getLoading ? _vm.$t('message.datasets.loadingMessage'):((_vm.$t('message.datasets.countMessage')) + " (" + (_vm.getDatasetsCount.toLocaleString('fi')) + ")"))+"\n          ")]),_vm._v(" "),(_vm.getLoading)?_c('div',{staticClass:"loading-spinner ml-3"}):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"selected-facets--overview"},[(_vm.getFacets)?_c('selectedFacetsOverview',{attrs:{"selected-facets":_vm.getFacets,"available-facets":_vm.getAllAvailableFacets}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"dataset-container--items"},[(!_vm.getLoading)?_vm._l((_vm.getDatasets),function(dataset){return _c('pv-data-info-box',{key:dataset.id,staticClass:"datasets-box",attrs:{"to":("/datasets/" + (dataset.id)),"src":_vm.getImg(_vm.getCatalogImage(dataset.catalog)),"dataset":{
              title: _vm.getTranslationFor(dataset.title, _vm.$route.query.locale, dataset.languages) || dataset.id,
              description:
                _vm.getTranslationFor(dataset.description, _vm.$route.query.locale, dataset.languages),
              catalog: _vm.getTranslationFor(dataset.catalog.title, _vm.$route.query.locale, []),
              createdDate: dataset.releaseDate,
              updatedDate: dataset.modificationDate,
              formats: _vm.removeDuplicatesOf(dataset.distributionFormats).filter(function (format) { return format.id || format.label; }),
            },"description-max-length":1000,"data-cy":("dataset@" + (dataset.id))}})}):_vm._e(),_vm._v(" "),(_vm.getLoading)?_c('div',{staticClass:"loading-spinner mx-auto mt-3 mb-3"}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"dataset-container--pagination"},[_c('pagination',{staticClass:"mt-3",attrs:{"items-count":_vm.getDatasetsCount,"items-per-page":_vm.getLimit,"get-page":_vm.getPage,"get-page-count":_vm.getPageCount},on:{"setPageLimit":_vm.setPageLimit}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }