<template>
  <div class="pagination-wrapper">
    <!-- PAGINATION -->
    <div v-if="usePagination" class="pagination-container">
      <div class="pagination-previous" :class="{ 'disabled': !clickedPageValid(getPage - 1) }" @click="pageClickedHandler(getPage - 1)">
        <i class="page-link material-icons" v-if="usePaginationArrows">keyboard_arrow_left</i>
        <button class="">{{ $t('message.pagination.previousPage') }}</button>
      </div>
      <div class="pagination-item"
          v-for="(page, i) in getPagesDisplayed(getPage, getPageCount)"
          :key="i"
          :class="{ 'active': page === getPage}"
          @click="pageClickedHandler(page)">
        <button class="">{{ page.toLocaleString('fi') }}</button>
      </div>
      <div class="pagination-next" :class="{ 'disabled': !clickedPageValid(getPage + 1) }" @click="pageClickedHandler(getPage + 1)">
        <button class="">{{ $t('message.pagination.nextPage') }}</button>
        <i class="page-link material-icons" v-if="usePaginationArrows">keyboard_arrow_right</i>
      </div>
    </div>
    <div v-if="useItemsPerPage" class="pagination-items">
      <span>{{ $t('message.pagination.itemsPerPage') }}</span>
      <label>
        <select @change="setPageLimit($event)">
          <option
            v-for="(item, index) in defaultItemsPerPageOptions"
            :key="index"
            :selected="item === itemsPerPage"
          >
            {{ item }}
          </option>
        </select>
        <i class="material-icons">expand_more</i>
      </label>
      <!-- <div class="" role="group" :aria-label="$t('message.pagination.itemsPerPage')" >
        <div class="btn-group items-per-page-dropdown" role="group">
          <button
            class="value-display list-group-item col d-flex flex-row justify-content-between p-0 align-items-center"
            id="itemsPerPageDropdown" type="button" data-toggle="dropdown" aria-expanded="false">
            <div class="pl-2 d-flex align-items-center">
              {{ itemsPerPage }}
            </div>
            <div class="items-per-page-icon ecl-select__icon text-white">
              <i class="material-icons">keyboard_arrow_down</i>
            </div>
          </button>
          <ul class="dropdown-menu ec-ds-dropdown-items" aria-labelledby="itemsPerPageDropdown">
            <button
              class="dropdown-item"
              @click="setPageLimit(item)"
              v-for="(item, index) in defaultItemsPerPageOptions"
              :key="index">{{ item }}</button>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  components: {},
  props: {
    itemsCount: {
      type: Number,
      default: () => 0,
    },
    itemsPerPage: {
      type: Number,
      default: () => 0,
    },
    getPage: {
      type: Number,
      default: () => 1,
    },
    getPageCount: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      pagesDisplayed: [],
      usePagination: this.$env.routing.pagination.usePagination,
      usePaginationArrows: this.$env.routing.pagination.usePaginationArrows,
      useItemsPerPage: this.$env.routing.pagination.useItemsPerPage,
      defaultItemsPerPageOptions: this.$env.routing.pagination.defaultItemsPerPageOptions,
    };
  },
  computed: {},
  methods: {
    getPagesDisplayed(currentPage, lastPage) {
      const current = currentPage;
      const last = lastPage;
      const delta = currentPage < 1000 ? 2 : 1;
      const left = current - delta;
      const right = current + delta + 1;
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= last; i += 1) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }
      return rangeWithDots;
    },
    pageClickedHandler(page) {
      if (this.clickedPageValid(page)) this.setPage(page);
    },
    clickedPageValid(page) {
      return typeof page === 'number' && page > 0 && page <= this.getPageCount;
    },
    setPage(page) {
      this.$emit('setPage', page);
      this.$router.push({ query: { ...this.$route.query, page, limit: this.itemsPerPage } });
      this.scrollTo(0, 0);
    },
    setPageLimit(event) {
      const limit = event.target.value;

      this.$emit('setPage', 1);
      this.$emit('setPageLimit', limit);
      this.$router.push({ query: { ...this.$route.query, page: 1, limit } });
      this.scrollTo(0, 0);
    },
    scrollTo(x, y) {
      window.scrollTo(x, y);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
  .pagination-wrapper {
    @apply flex justify-between mb-16;

    .pagination-container {
      @apply flex items-center text-primary-700;

      .pagination-previous {
        @apply p-4 flex items-center;
        i {
          @apply mr-4;
        }

        &:hover {
          @apply bg-primary-700 text-white cursor-pointer transition-all duration-150;
        }
      }

      .pagination-item {
        @apply px-4 py-2;

        &:hover {
          @apply bg-primary-700 text-white rounded-full cursor-pointer transition-all duration-150;
        }
      }

      .active {
        @apply border-2 border-primary-700 rounded-full bg-white;
      }

      .pagination-next {
        @apply p-4 flex items-center;

        i {
          @apply ml-4;
        }

        &:hover {
          @apply bg-primary-700 text-white cursor-pointer transition-all duration-150;
        }
      }
    }

    .pagination-items {
      @apply flex items-center;
      span {
        @apply mr-8 text-primary-700;
      }
      label {
        @apply relative;

        select {
          @apply px-10 py-4 bg-primary-700 text-white appearance-none;
        }

        i {
          @apply absolute right-2 top-4 text-white;
        }
      }
    }
  }

.page-item {
  display: flex;

  &.disabled {
    color: rgba(0,0,0,0.7);
  }

  .page-link {
    line-height: normal;
  }
}

.items-per-page {
  display: inline;
  margin-left: auto;

  .items-per-page-dropdown {
    width: 100px;
    height: 48px;
  }

  .items-per-page-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 2.75rem;
    background-color: var(--primary);
    .material-icons {
      font-size: 30px;
    }
  }
}
</style>
