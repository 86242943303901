<template>
  <div class="preview-container">
    <div class="preview-container--button">
      <a :href="getGeoLink" @click="$emit('track-link', getGeoLink, 'link')">{{ $t('message.datasetDetails.preview') }}</a>
    </div>
  </div>
</template>

<script>
import AppLink from '../../../widgets/AppLink';
import {has, isNil} from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "DistributionOptionsDropdown",
  components: {
    AppLink
  },
  props: [
    'showTooltipVisualiseButton',
    'isUrlInvalid',
    'getVisualisationLink',
    'distribution',
    'openIfValidUrl',
    'previewLinkCallback'
  ],
  data() {
    return {
      visualisationLinkFormats: [
        'csv',
        'xlsx',
        'xls',
      ],
      geoLinkFormats: {
        wms: 'WMS',
        geojson: 'GeoJSON',
        fiware_cb: 'fiware_cb',
        'fiware-cb': 'fiware_cb',
      },
      geoLink: this.$env?.datasetDetails?.distributions?.geoLink,
    };
  },
  computed: {
    ...mapGetters('datasetDetails', [
      'getCatalog',
      'getID'
    ]),
    getGeoLink() {
      let f = '';

      if (this.distribution.format !== undefined) {
        const format = this.distribution.format.label;

        let f = format.toLowerCase();

        // Use correct Case Sensitive strings
        f = this.geoLinkFormats[f];
      }

      if (this.geoLink) {
        const geoLinkVariables = {
          catalog: this.getCatalog.id,
          dataset: this.getID,
          distribution: this.distribution.id,
          type: f,
          lang: this.$route.query.locale,
          accessUrl: this.distribution?.accessUrl[0],
        }
        // Inject variables into geo link
        for (let linkVariable in geoLinkVariables) {
          this.geoLink = this.geoLink.replace(`{${linkVariable}}`, geoLinkVariables[linkVariable]);
        }
        // Return Geo Visualisation Link
        return this.geoLink;
        // return `/geo-viewer/?dataset=${distributionID}&type=${f}&lang=${this.$route.query.locale}`;
      }
      // Return default Geo Visualisation Link if no link in user-config provided
      return `/geo-viewer/?catalog=${this.getCatalog.id}&dataset=${this.getID}&distribution=${this.distribution.id}&type=${f}&lang=${this.$route.query.locale}`;
    }
  },
  methods: {
    showOptionsDropdown(distribution) {
      return this.showVisualisationLink(distribution) || this.showGeoLink(distribution);
    },
    showGeoLink(distribution) {
      if (!has(distribution, 'format.label') || isNil(distribution.format.label) || !has(distribution, 'id') || isNil(distribution.id) || !has(distribution, 'accessUrl[0]')) return false;
      const f = distribution.format.label.toLowerCase();
      return Object.keys(this.geoLinkFormats).includes(f);
    },
    showVisualisationLink(distribution) {
      if (!has(distribution, 'format.label') || isNil(distribution?.format?.label)
        || (isNil(distribution?.downloadUrls[0]) && isNil(distribution?.accessUrl[0]))) return false;
      const f = distribution?.format?.id?.toLowerCase();
      return f && this.visualisationLinkFormats.includes(f);
    }
  }
}
</script>

<style scoped lang="scss">
  .preview-container {
    @apply relative inline-block;

    &:hover {
      .preview-container--content {
        @apply block;
      }
      .less {
        @apply block;
      }
      .expand {
        @apply hidden;
      }
    }

    .less {
      @apply hidden;
    }

    .preview-container--button {
      @apply text-primary-700 cursor-pointer flex items-center;
    }
    .preview-container--content {
      @apply hidden absolute max-w-max right-auto left-auto bg-white;

      ul {
        @apply px-4 py-2 w-48;

        li {
          @apply cursor-pointer border-b-2 border-primary-100 mb-2 pb-2 text-primary-700;

          &:hover {
            @apply border-primary-700;
          }

          &:last-child {
            @apply mb-0;
          }
        }
      }
    }
  }
</style>
