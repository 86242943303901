<template>
  <div class="row flex-column dsd-features resource-features">

    <connecter-details></connecter-details>

    <dataset-details-keywords
      class="dsd-keywords-feature resource-keywords-feature"
      v-if="showObjectArray(getKeywords) && keywordsisVisible"
      :showKeyword="showKeyword"
    />
    <dataset-details-categories-key
      class="dsd-categories-feature resource-categories-feature"
      v-if="showObjectArray(getCategories)"
      :trackGoto="trackGoto"
    />
    <dataset-details-subject
     class="dsd-subject-feature resource-subject-feature"
      v-if="showObjectArray(getSubject)"
    />

    <!-- Documentation -->
    <dataset-details-pages
      class="dsd-pages-feature resource-pages-feature"
      v-if="showObjectArray(getPages)"
      :pages="pages"
      :increaseNumDisplayedPages="increaseNumDisplayedPages"
      :nonOverflowingIncrementsForPages="nonOverflowingIncrementsForPages"
      :trackGoto="trackGoto"
    />

    <dataset-details-visualisations
      class="dsd-visualisations-feature resource-visualisations-feature"
      v-if="showObjectArray(getVisualisations)"
      :trackGoto="trackGoto"
    />

    <dataset-details-data-services
      class="dsd-data-services-feature resource-data-services-feature"
      v-if="showObjectArray(getDataServices)"
      :getDataServices="getDataServices"
      :nonOverflowingIncrementsForPages="nonOverflowingIncrementsForPages"
      :increaseNumDisplayedPages="increaseNumDisplayedPages"
      :trackGoto="trackGoto"
    />

    <dataset-details-is-used-by
      class="dsd-is-used-by resource-is-used-by"
      v-if="showObject(getExtendedMetadata)"
    />

    <dataset-details-relations
      class="dsd-relations-feature resource-relations-feature"
      v-if="showArray(getRelations)"
    />

    <dataset-details-map
      class="dsd-map-feature resource-map-feature"
      v-if="showObjectArray(getSpatial)"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {has, isEmpty} from "lodash";

import ConnecterDetails from '../../gaiax/ConnecterDetails.vue';
import {
  DatasetDetailsKeywords,
  DatasetDetailsSubject,
  DatasetDetailsPages,
  DatasetDetailsVisualisations,
  DatasetDetailsDataServices,
  DatasetDetailsIsUsedBy,
  DatasetDetailsRelations,
  DatasetDetailsMap,
  DatasetDetailsCategoriesKey,
  } from '@piveau/piveau-hub-ui-modules';

export default {
  name: "DatasetDetailsFeatures",
  components: {
    ConnecterDetails,
    DatasetDetailsMap,
    DatasetDetailsCategoriesKey,
    DatasetDetailsRelations,
    DatasetDetailsIsUsedBy,
    DatasetDetailsDataServices,
    DatasetDetailsVisualisations,
    DatasetDetailsPages,
    DatasetDetailsSubject,
    DatasetDetailsKeywords
  },
  props: {
    getKeywords: Array,
    pages: Object,
    increaseNumDisplayedPages: Function,
    nonOverflowingIncrementsForPages: Function,
    showKeyword: Function,
    trackGoto: Function,
    showObjectArray: Function,
    showArray: Function,
    showObject: Function
  },
  data() {
    return {
      keywordsisVisible: (this.$env.content.datasetDetails.keywords.isVisible === false) ? false : true,
    }
  },
  computed: {
    ...mapGetters('datasetDetails', [
      'getSubject',
      'getPages',
      'getVisualisations',
      'getDistributions',
      'getExtendedMetadata',
      'getRelations',
      'getCategories',
      'getSpatial'
    ]),
    getDataServices() {
      if (this.getDistributions) {
        const accessServiceList = this.getDistributions
          .filter(distribution => has(distribution, 'accessService') && !isEmpty(distribution.accessService))
          .map(distribution => ({
            endpoint_url: distribution.accessService[0].endpoint_url,
            title: distribution.accessService[0].title,
            description: distribution.accessService[0].description,
            availability: has(distribution.accessService[0], 'availability') ? distribution.accessService[0].availability : {}   // field added for DCAT-AP.de
          }));
        const uniqueAccessServiceList = [...new Map(
          accessServiceList
            .filter(accessService => accessService?.endpoint_url?.length)
            .map(accessService => [
              accessService.endpoint_url[0], accessService,
            ]),
        ).values()];
        return uniqueAccessServiceList;
      }
      return [{}];
    }
  }
}
</script>

<style scoped>

</style>
