<template>
  <div>
    <p
      v-if="description"
      class="dataset-info--description"
    >
      {{
        descriptionMaxLength
          ? truncatedDescription
          : getDescription
      }}
    </p>
    <span v-else>
      <p class="text-muted font-italic m-0" data-cy="dataset-description">
        {{ $t("message.catalogsAndDatasets.noDescriptionAvailable") }}
      </p>
    </span>
  </div>
</template>

<script>
import { truncate } from '@piveau/piveau-hub-ui-modules';

export default {
  props: {
    // description: {
    //   type: Object,
    //   default: null,
    // },
    description: {
      type: String,
      default: null,
    },
    descriptionMaxLength: {
      type: Number,
      default: null,
    },
  },

  computed: {
    getDescription() {
      // fix for undefined lang key
      return this.description;
      // return this.description?.en;
    },
    truncatedDescription() {
      return this.descriptionMaxLength && truncate(this.getDescription, this.descriptionMaxLength);
    },
  }
};
</script>

<style lang="scss" scoped>
  .dataset-info--description {
    @apply text-primary-700 mb-8;
  }
</style>