<template>
  <div class="facet-container">
    <div class="faceset-select--container">
      <facet-title
        :title="header"
        :tooltip="toolTipTitle"
        :title-id="myTitleId"
      />
      <div class="faceset-select--select">
        <div class="select-container">
          <div class="select-container--content" v-show="expand">
            <div
              @click="facetClicked(fieldId, item)"
              v-for="(item, index) in items.slice(0, numItemsAllowed)"
              :key="`field@${index}`"
              :class="{active: facetIsSelected(fieldId, item)}"
            >
              {{ getFacetTranslationWrapper(fieldId, item.id, $route.query.locale, item.title) }}
              ({{ getFacetCount(item) }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatasetsFacetsItem from './DatasetsFacetsItem';

import FacetTitle from "./FacetTitle";

export default {
  name: 'ExpandableSelectFacet',
  components: {FacetTitle, DatasetsFacetsItem},
  props: {
    header: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    toolTipTitle: {
      type: String,
      default: '',
    },
    fieldId: {
      type: String,
      default: '',
    },
    getFacetTranslationWrapper: Function,
    facetIsSelected: Function,
    facetClicked: Function,
  },
  data() {
    return {
      id: null,
      isExpanded: false,
      isGrown: false,
      numItemsAllowed: this.$env.content.datasets.facets.MIN_FACET_LIMIT || 5,
      minItems: this.$env.content.datasets.facets.MIN_FACET_LIMIT || 5,
      maxItems: this.$env.content.datasets.facets.MAX_FACET_LIMIT || 50,
      expand: true
    };
  },
  computed: {
    myId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-${this.id}`;
    },
    myListId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-list-${this.id}`;
    },
    myTitleId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-title-${this.id}`;
    },
  },
  methods: {
    handleGrowToggle() {
      this.isGrown = !this.isGrown;
      this.numItemsAllowed = this.isGrown
        ? this.maxItems
        : this.minItems;
    },
    getFacetCount(facet) {
      if (this.fieldId === 'scoring') return '';
      return facet.count;
    }
  }
};

</script>

<style lang="scss" scoped>
  .faceset-select--container {
    @apply mb-16;

    .faceset-select--header {
      @apply border-b-2 border-white mb-4 pb-4 flex justify-between items-center text-white;
      span {
        @apply text-white;
      }
    }

    .faceset-select--select {
      .select-container {
        .select-container--content {
          @apply bg-primary-700 border border-white mt-4;
          div {
            @apply p-4 text-white border-b border-white;
            cursor: pointer;

            &:last-child {
              @apply border-b-0;
            }
            
            &:hover {
              @apply bg-primary-300 transition-all duration-150;
            }
          }

          .active {
            @apply bg-primary-400;
          }
        }
      }

      label {
        @apply flex items-center text-white;

        select {
          @apply text-white appearance-none bg-primary-700 w-full;
        }
      }
    }
  }

  .select-facet--header {
    @apply text-white flex items-center;
  }

.facet-header {
  // background-color: #fffade;
  // background-color: rgba(255, 204, 0, 0.1);
  background-color: rgba(0, 29, 133,0.05);
  // background-color: #cddbe8;

  // background-color: #001d85;
  // color: white !important;
}
.tooltip-icon {
  font-size: 15px;
}

@media (min-width: 768px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.list-item-container {
  margin-bottom: -1px;
}

.btn-color {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover {
    background-color: #196fd2;
    border-color: #196fd2;
  }
}
</style>
