<template>
  <div class="resource-header">
    <div class="d-flex flex-row align-items-center col-12 col-lg-10 offset-lg-1 mb-3 px-0 resource-header-title">
      <div class="d-none d-lg-block resource-title-tag">
        <span>
          {{ getResourceType }}
        </span>
      </div>
      <h1 class="d-none d-lg-block dataset-details-title" data-cy="dataset-title">{{ getName }}</h1>
    </div>
  </div>
</template>

<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceDetailsStore } from "@/store/resourceDetailsStore";

export default {
  name: 'ResourceDetailsHeader',
  data() {
    return {};
  },
  computed: {
    ...mapStores(useResourceDetailsStore),
    ...mapState(useResourceDetailsStore, [
      'getResourceType', 
      'getName', 
    ]),
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.dataset-details-title {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
@media (min-width: 768px) {
  .dataset-details-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .dataset-details-title {
    font-size: 1.75rem;
  }
}
</style>