import { render, staticRenderFns } from "./DatasetDetailsDataset.vue?vue&type=template&id=eb6dc8ec&scoped=true&"
import script from "./DatasetDetailsDataset.vue?vue&type=script&lang=ts&"
export * from "./DatasetDetailsDataset.vue?vue&type=script&lang=ts&"
import style0 from "./DatasetDetailsDataset.vue?vue&type=style&index=0&id=eb6dc8ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb6dc8ec",
  null
  
)

export default component.exports