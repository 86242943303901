<template>
  <div>
    <dataset-details-header-title />
    <dataset-details-header-catalogue />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { has, isNil, isObject } from 'lodash-es';

  import { AppLink, DatasetDate, dateFilters, PropertyValue } from '@piveau/piveau-hub-ui-modules';
  import DatasetDetailsHeaderTitle from "./DatasetDetailsHeaderTitle";
  import DatasetDetailsHeaderCatalogue
    from "./DatasetDetailsHeaderCatalogue";

  export default {
    name: 'datasetDetailsHeader',
    components: {
      PropertyValue,
      DatasetDetailsHeaderCatalogue,
      DatasetDetailsHeaderTitle,
      DatasetDate,
      AppLink,
    },
    dependencies: 'DatasetService',
    data() {
      return {
        hidePublisher: this.$env.content.datasetDetails.header.hidePublisher,
        hideDate: this.$env.content.datasetDetails.header.hideDate,
      };
    },
    computed: {
      // import store-getters
      ...mapGetters('datasetDetails', [
        'getCatalog',
        'getCountry',
        'getLanguages',
        'getPublisher',
        'getModificationDate'
      ]),
      getPublisherName() {
        if (has(this.getPublisher, 'name') && !isNil(this.getPublisher.name)) {
          return this.getPublisher.name;
        } else {
          return "";
        }
      }
    },
    methods: {
      has,
      isNil,
      isObject,
      filterDateFormatUS(date) {
        return dateFilters.formatUS(date);
      },
      filterDateFormatEU(date) {
        return dateFilters.formatEU(date);
      },
      showObject(object) {
        return !isNil(object) && isObject(object) && !Object.values(object).reduce((keyUndefined, currentValue) => keyUndefined && currentValue === undefined, true);
      },
      filterDateFromNow(date) {
        return dateFilters.fromNow(date);
      }
    },
  };
</script>

<style scoped>
  div {
    @apply mb-8;
  }
</style>
