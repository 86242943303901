<template>
    <div class="d-none d-md-block">
        <facet-title :title="title" :tooltip="toolTipTitle"></facet-title>
        <div class="list-item-container" v-for="resourceType in getResourceTypes">
            <button @click="switchResourceType(resourceType)" class="d-flex facet list-group-item list-group-item-action justify-content-between align-items-center">
                <span class="text-truncate">{{ resourceType }}</span>
            </button>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapStores } from "pinia";
import { useResourceStore } from "@/store/resourceStore";
import { FacetTitle } from '@piveau/piveau-hub-ui-modules';

export default {
    name: 'ResourceTypeFacet',
    components: {
        FacetTitle,
    },
    props: {},
    data() {
        return {
            title: 'Resource Type',
            toolTipTitle: 'Select a Resource Type',
        };
    },
    computed: {
        ...mapStores(useResourceStore),
        ...mapState(useResourceStore, [
            'getResourceTypes', 
        ]),
    },
    methods: {
        switchResourceType(resourceType) {
            this.$router.push(`/${resourceType}`).catch(error => {});;
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
</style>
  