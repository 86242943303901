<template>
  <div class="dsd-feature resource-feature">
    <dataset-details-feature-header
      title="Data Space Connector"
      :arrowDown="!isVisible"
      :onClick="toggleVisualisation"
    />
    <ul v-if="isVisible" class="list list-unstyled col-12">
      <div>
        <table class="table">
          <tbody>
          <tr>
            <td>Produced By</td>
            <td>{{ datasetDetails.produced_by }}</td>
          </tr>
          <tr>
            <td>Exposed through</td>
            <td>{{ datasetDetails.exposed_through[0] }}</td>
          </tr>
          <tr>
            <td>Obsolete Datetime</td>
            <td>{{ datasetDetails.obsolete }}</td>
          </tr>
          <tr>
            <td>Expiration Datetime</td>
            <td>{{ datasetDetails.expiration }}</td>
          </tr>
          <tr>
            <td>Contains PII</td>
            <td>{{ datasetDetails.contains_pii }}</td>
          </tr>
          <tr>
            <td>Legal Basis</td>
            <td>{{ datasetDetails.legal_basis }}</td>
          </tr>
          <tr>
            <td>Contract Offer ID</td>
            <td>{{ datasetDetails.contract_offer_id }}</td>
          </tr>
          <tr>
            <td>Asset ID</td>
            <td>{{ datasetDetails.asset_id }}</td>
          </tr>
          <tr>
            <td>Policy ID</td>
            <td>{{ datasetDetails.has_poss_x_policy.uid }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </ul>
  </div>
</template>

<script>
import { has, isNil } from "lodash";
import { mapGetters } from "vuex";
import axios from "axios";

import { AppLink, getTranslationFor, truncate } from '@piveau/piveau-hub-ui-modules';

export default {
  name: "ConnectorDetails",
  components: {
    AppLink,
  },
  props: {},
  data() {
    return {
      isVisible: true,
      datasetDetails: null
    };
  },
  computed: {
    // Access Dataset from here (https://gitlab.fokus.fraunhofer.de/piveau/ui/piveau-ui/-/blob/master/packages/piveau-hub-ui-modules/lib/store/modules/datasetDetailsStore.ts)
    ...mapGetters('datasetDetails', [
        'getID',
    ]),
  },
  methods: {
    truncate,
    toggleVisualisation() {
        this.isVisible = !this.isVisible;
    },
    getDatasetDetails() {
      let url =  this.$env.api.baseUrl + "/datasets/" + this.getID
      axios.get(url).then((response) => {
        this.datasetDetails = response.data.result
      })
    }
  },
  mounted() {
    this.getDatasetDetails()
  }
}
</script>

<style scoped lang="scss">
.heading, .arrow {
  cursor: pointer;
}
</style>
