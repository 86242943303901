<template>
  <h1 data-cy="dataset-title">{{ getTranslationFor(getTitle) }}</h1>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getTranslationFor } from '@piveau/piveau-hub-ui-modules';

  export default {
    name: "DatasetDetailsHeaderTitle",
    methods: {getTranslationFor},
    computed: {
      ...mapGetters('datasetDetails', [
      'getTitle',
      ]),
    }
  }
</script>

<style scoped lang="scss">
  h1 {
    @apply text-4xl text-primary-700 mt-8 mb-2;
  }
</style>
