<template>
  <div class="container dataset-facets">
    <div class="row mx-3 mr-md-0">
      <div class="col">
        <datasets-map-facet v-if="useFacetsMap"></datasets-map-facet>
        <settings-facet class="row facet-field mb-3" v-if="useSettingsFacet"></settings-facet>
        <resource-type-facet class="row facet-field mb-3" v-if="useResourceTypeFacet"></resource-type-facet>
        <!-- TODO -->
        <!-- <data-services-facet class="row facet-field mb-3" v-if="useDataServicesFacet"></data-services-facet> -->
        <!-- <scoring-facet class="row facet-field mb-3" v-if="useScoringFacet"></scoring-facet> -->
        <div 
          class="row facet-field mb-3"
          v-for="(field, index) in getSortedFacets"
          :key="`facet@${field.id}`"
          :class="{'mt-3': (index > 0)}"
        >
          <select-facet
            :fieldId="field.id"
            :items="sortByCount(field.items)"
            :header="getFacetTitle(field.id)"
            :toolTipTitle="getToolTipTitle(field.id)"
            :getFacetTranslationWrapper="getFacetTranslationWrapper"
            :facetIsSelected="facetIsSelected"
            :facetClicked="facetClicked"
            class="col pr-0"
          />
        </div>
        <div>
          <pv-show-more
            v-if="showMoreFacetsVisible"
            :label="facetCutoff >= 0? $t('message.datasetFacets.moreFilters') : $t('message.datasetFacets.lessFilters')"
            :upArrow="facetCutoff === -1"
            :action="toggleFacetCutoff"
            class="p-0 row facets-show-more"
          />
        </div>
        <pv-button
          v-if="useFacetClearButton"
          label="Clear filters"
          class="row mt-5 facets-clear"
          :action="clearFacets"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceStore } from "@/store/resourceStore";

import {
  isObject,
  isArray,
  isString,
  has,
  isNil,
  isNumber,
} from 'lodash-es';
import Vue from 'vue';

import ResourceTypeFacet from './ResourceTypeFacet'
import { DatasetsMapFacet, SettingsFacet, getFacetTranslation } from '@piveau/piveau-hub-ui-modules';

export default {
  name: 'resourceFacets',
  components: {
    DatasetsMapFacet,
    SettingsFacet,
    ResourceTypeFacet,
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', vmid: 'description', content: this.description },
        { name: 'keywords', vmid: 'keywords', content: this.keywords },
      ],
    };
  },
  data() {
    return {
      title: '',
      description: '',
      keywords: '',
      useFacetsMap: this.$env.content.facets.useFacetsMap,
      useSettingsFacet: this.$env.content.facets.useSettingsFacet,
      useResourceTypeFacet: this.$env.content.facets.useResourceTypeFacet,
      useDataServicesFacet: this.$env.content.facets.useDataServicesFacet,
      useScoringFacet: this.$env.content.facets.useScoringFacet,
      facetCutoff: this.$env.content.facets.cutoff,
      useFacetClearButton: this.$env.content.facets.useFacetClearButton,
      defaultFacetOrder: this.$env.content.facets.defaultFacetOrder,
    };
  },
  computed: {
    ...mapStores(useResourceStore),
    ...mapState(useResourceStore, [
      'getSearchResults', 
      'getLoading', 
      'getLimit', 
      'getPage', 
      'getItemsCount', 
      'getPageCount', 
      'getFacets', 
      'getSelectedFacets', 
    ]),
    baseUrl() {
      return this.$env.api.baseUrl;
    },
    resourceTypeEndpoint() {
      return this.$env.content.resourceEndpoints.resourceType;
    },
    getAllVisibleFacets() {
      return Object.values(this.getFacets).filter(facet => facet.items.length > 0).map(facet => facet.id);
    },
    getSortedFacets() {
      const activeFacets = [];
      const inactiveFacets = [];

      let facets = Object.keys(this.getFacets).map(facet => this.getFacets[facet]);

      this.defaultFacetOrder.forEach((facet) => {
        facets.forEach((field) => {
          if (facet === field.id) {
            if (this.getAllVisibleFacets.includes(field.id)) activeFacets.push(field);
            else inactiveFacets.push(field);
          }
        });
      });

      const sortedFacets = activeFacets.concat(inactiveFacets);

      if (this.facetCutoff > 0) {
        if (this.facetCutoff < activeFacets.length) this.facetCutoff = activeFacets.length;
        return sortedFacets.slice(0, this.facetCutoff);
      } else {
        return sortedFacets;
      }
    },
    showMoreFacetsVisible() {
      return this.$env.content.datasets.facets.cutoff > 0 && this.$env.content.datasets.facets.cutoff < this.getAllVisibleFacets.length;
    },
  },
  methods: {
    isObject,
    isArray,
    isString,
    has,
    isNil,
    isNumber,
    getFacetTranslation,
    ...mapActions(useResourceStore, [
      'loadResourceTypes',
      'setSelectedFacets',
    ]),
    sortByCount(items) {
      return items.slice().sort((a, b) => {
        const n = b.count - a.count;
        if (n !== 0) return b.count - a.count;
        if (a.name < b.name) return -1;
        return 1;
      });
    },
    getFacetTitle(fieldId) {
      return Vue.i18n.t(`message.datasetFacets.facets.${fieldId.toLowerCase()}`);
    },
    getToolTipTitle(fieldId) {
      return Vue.i18n.t(`message.helpIcon.${fieldId.toLowerCase()}`);
    },
    getFacetTranslationWrapper(fieldId, facetId, userLocale, fallback) {
      return this.getFacetTranslation(fieldId, facetId, userLocale, fallback);
    },
    facetIsSelected(fieldId, item) {
      const facet = item.id;

      if (!Object.prototype.hasOwnProperty.call(this.$route.query, fieldId)) return false;

      let qField = this.$route.query[fieldId];
      if (!Array.isArray(qField)) qField = [qField];

      return qField.indexOf(facet) > -1;
    },
    facetClicked(field, item) {
      const facet = item.id;
      this.toggleFacet(field, facet);
    },
    toggleFacet(field, facet) {   
      // Add new facet to URL
      if (!Object.prototype.hasOwnProperty.call(this.$route.query, [field])) {
        this.setSelectedFacets(field, [facet]);
        this.setRouteQuery({ [field]: [facet], page: 1 });
        return;
      }

      // Adjust existing facet in URL
      let facets = this.$route.query[field].slice();
      if (!Array.isArray(facets)) facets = [facets];
      const index = facets.indexOf(facet);

      if (index > -1) {
        facets.splice(index, 1);
      } else {
        facets.push(facet);
      }

      this.setSelectedFacets(field, facets);
      this.setRouteQuery({ [field]: facets, page: 1 });
    },
    toggleFacetCutoff() {
      this.facetCutoff = this.facetCutoff >= 0 ? -1 : this.$env.content.datasets.facets.cutoff;
    },
    clearFacets() {
      Object.keys(this.getSelectedFacets).forEach(facet => this.setSelectedFacets(facet, []));
      this.replaceRouteQuery({ page: 1 });
    },

    // Helper functions
    setRouteQuery(query) {
      return this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(error => {});
    },
    replaceRouteQuery(query) {
      return this.$router.push({ query: Object.assign({}, this.$route.query.locale, query) }).catch(error => {});
    },
  },
  watch: {},
  created() {
    this.$nextTick(() => {
      this.$Progress.start();
      this.loadResourceTypes(this.baseUrl, this.resourceTypeEndpoint)
        .then(() => {
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.fail();
        });
    });
  }
};
</script>