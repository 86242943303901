<template>
  <!-- :title="$t('message.tooltip.datasetDetails.distribution')" -->
  <h2
      data-toggle="tooltip"
      data-placement="top"
      data-cy="dataset-distributions">
    {{ $t('message.metadata.distributions') }}
  </h2>
</template>

<script>
import {mapGetters} from "vuex";

import {
  DownloadAllDistributions,
} from '@piveau/piveau-hub-ui-modules';

export default {
  name: "DistributionsHeader",
  components: {DownloadAllDistributions},
  props: [
    'getDistributionDescription',
    'openModal',
    'getDistributionTitle',
    'showDownloadUrls',
    'isUrlInvalid'
  ],
  data() {
    return {
      downloadAllTop: this.$env.content.datasetDetails.bulkDownload.buttonPosition === "top"
    };
  },
  computed: {
    ...mapGetters('datasetDetails', [
      'getDistributions',
      'getLanguages'
    ])
  }
}
</script>

<style scoped>
  h2 {
    @apply text-primary-400 text-sm border-b-2 border-primary-100 pb-4 mb-4;
  }
</style>
