<template>
  <div class="list-group w-100 radio-facet" role="group" :aria-labelledby="myTitleId">
    <facet-title
      :title="title"
      :tooltip="toolTipTitle"
      :title-id="myTitleId"
    />
    <div class="faceset-radio">
      <span>{{ property }}</span>
      <div class="faceset-radio-wrapper">
        <div class="" v-for="(id, index) in optionIds">
          <input type="radio" :id="id" :name="title" class="custom-control-input" @click="onChange(id)" :checked="option === id">
          <label class="custom-control-label" :for="id">{{ optionLabels[index] }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacetTitle from "./FacetTitle";
export default {
  name: "RadioFacet",
  components: {FacetTitle},
  props: {
    title: String,
    toolTipTitle: String,
    property: String,
    initialOption: String,
    optionIds: Array,
    optionLabels: Array,
    change: Function
  },
  data() {
    return {
      option: this.initialOption
    };
  },
  computed: {
    myId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-${this.id}`;
    },
    myTitleId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-title-${this.id}`;
    }
  },
  methods: {
    onChange(id) {
      this.option = id;
      this.change(id);
    }
  },
  mounted() {
    // this.id = this.myId; // eslint-disable-line
    this.id = this._uid; // eslint-disable-line
  },
}
</script>

<style scoped lang="scss">
  .faceset-radio {
    @apply text-white flex justify-between mb-16;

    .faceset-radio-wrapper {
      @apply flex;
      div {
        @apply flex items-center mr-2;

        input {
          @apply mr-2;
        }
      }
    }
  }
</style>
