<template>
  <sub-navigation>
    <div class="d-flex container-fluid justify-content-between px-0 resource-nav">
      <dataset-details-navigation-pages />
      <!-- <dataset-details-navigation-links
        :datasetId="datasetId"
      /> -->
    </div>
  </sub-navigation>
</template>

<script>
import { mapGetters } from 'vuex';

import { getTranslationFor } from '@piveau/piveau-hub-ui-modules';

import DatasetCitationModal from "../citation/DatasetCitationModal";
import DatasetDetailsNavigationPages from "./DatasetDetailsNavigationPages";
import DatasetDetailsNavigationLinks from "./DatasetDetailsNavigationLinks";

  export default {
    name: 'datasetDetailsNavigation',
    components: {
      DatasetDetailsNavigationLinks,
      DatasetDetailsNavigationPages,
      // SubNavigation,
      DatasetCitationModal
    },
    props: {
      datasetId: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters('datasetDetails', [
        'getTitle',
        'getLanguages'
      ])
    },
    methods: {
      getTranslationFor
    }
  };
</script>
