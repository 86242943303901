<template>
  <div class="datasets-container d-flex flex-column p-0 bg-transparent">
    <resource-top-controls
      :facets="getFacets"
      :getPage="getPage"
      :getLimit="getLimit"
      class="datasets-top-controls"
      v-if="useFeed"
    ></resource-top-controls>
    <div class="container-fluid datasets content">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title text-primary">{{ getResource.title }}</h1>
        </div>
      </div>
      <div class="row">
        <resource-facets class="col-md-3 col-12 mb-3 mb-md-0 px-0" id="facets"></resource-facets>
        <section class="col-md-9 col-12">
          <resource-filters></resource-filters>
          <div class="alert alert-primary mt-3 d-flex flex-row" role="status"
              :class="{ 'alert-danger': getItemsCount <= 0 && !getLoading}">
            <div>
              {{ getLoading ? $t('message.datasets.loadingMessage') : `${getItemsCount.toLocaleString('fi')} ${$t('message.datasets.countMessage')}`}}
            </div>
            <div class="loading-spinner ml-3" v-if="getLoading"></div>
          </div>
          <selectedFacetsOverview v-if="getFacets" :available-facets="getFacets" :selected-facets="getSelectedFacets"></selectedFacetsOverview>
          <div v-if="getSearchResults.length > 0">
            <div v-for="resource in getSearchResults" :key="resource.id">
              <pv-data-info-box class="mt-3" 
                :resource="resource" 
                :to="`${getResource.path}/${resource.id}`"
                :description-max-length="1000">
              </pv-data-info-box>
            </div>
          </div>
        </section>
      </div>
      <div class="row">
        <div class="column col-12 col-md-9 offset-md-3">
          <pagination class="mt-3"
            :items-count="getItemsCount"
            :items-per-page="getLimit"
            :get-page="getPage"
            :get-page-count="getPageCount"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceStore } from "@/store/resourceStore";

import PvDataInfoBox from '../modules/datasets/PvDataInfoBox'
import Pagination from '../modules/widgets/Pagination'
import ResourceFacets from './ResourceFacets'
import ResourceFilters from './ResourceFilters'
import ResourceTopControls from './ResourceTopControls'
import { SelectedFacetsOverview } from '@piveau/piveau-hub-ui-modules';

export default {
  name: "ResourceSearchPage",
  components: {
    PvDataInfoBox,
    ResourceTopControls,
    ResourceFacets,
    ResourceFilters,
    SelectedFacetsOverview,
    Pagination,
  },
  data() {
    return {
      useFeed: this.$env.content.useFeed,
      resources: this.$env.content.resources,
      defaultFacetOrder: this.$env.content.facets.defaultFacetOrder,
    };
  },
  metaInfo() {
    return {
      title: this.searchQuery ? `${this.searchQuery}` : `${this.getResourceType}`,
      meta: [
        { name: 'description', vmid: 'description', content: `` },
        { name: 'keywords', vmid: 'keywords', content: `` },
        { name: 'robots', content: 'noindex, follow' },
      ],
    };
  },
  computed: {
    ...mapStores(useResourceStore),
    ...mapState(useResourceStore, [
      'getResourceType', 
      'getSearchResults', 
      'getLoading', 
      'getLimit', 
      'getPage', 
      'getItemsCount', 
      'getPageCount', 
      'getFacets', 
      'getSelectedFacets', 
    ]),
    baseUrl() {
      return this.$env.api.baseUrl;
    },
    searchQuery() {
      return this.$route.query.query;
    },
    resourceType() {
      return this.$route.name;
    },
    getResource() {
      let resource = {};

      Object.keys(this.resources).forEach(resourceType => {
        if (this.resourceType.toLowerCase() === resourceType.toLowerCase()) {
          resource = this.resources[resourceType];
        }
      });

      return resource;
    },

  },
  methods: {
    ...mapActions(useResourceStore, [
      'setInitialState',
      'setResourceType',
      'clearFilters',
      'loadSearchResults',
      'setQuery',
      'setSort',
      'setPage',
      'setPageLimit',
      'setItemsCount',
      'setPageCount',
      'setSelectedFacets',
    ]),
    setSelectedFacetsFromQuery() {
      Object.keys(this.$route.query)
        .filter(key => this.defaultFacetOrder.includes(key))
        .forEach(key => this.setSelectedFacets(key, this.$route.query[key]));
    },
    setFilters(query, sort, page, limit) {
      if (query) this.setQuery(query);
      if (sort) this.setSort(sort);
      if (page) this.setPage(page);
      if (limit) this.setPageLimit(limit);
      this.setSelectedFacetsFromQuery();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$Progress.start();
      this.setResourceType(this.resourceType);
      this.setFilters(this.$route.query.query, this.$route.query.sort, this.$route.query.page, this.$route.query.limit);
      this.loadSearchResults(this.baseUrl, this.getResource.endpoint)
        .then(() => {
          this.$Progress.finish();
          this.$router.push({ query: { ...this.$route.query, locale: this.$route.query.locale } }).catch(() => {});
        })
        .catch(() => {
          this.$Progress.fail();
        });
    });
  },
};
</script>
