<template>
  <div>
    <div class="download-container">
      <div class="download-container--button">
        <span>Download</span>
        <i class="material-icons expand">expand_more</i>
        <i class="material-icons less">expand_less</i>
      </div>
      <div class="download-container--content">
        <app-link class="download-container--content__link"
                  :to="replaceHttp(distribution.accessUrl[0])"
                  target="_blank"
                  rel="dcat:distribution noopener"
                  @after-click="$emit('trackGoto')">
          <i class="material-icons align-bottom">open_in_new</i>
          <small class="px-2" property="dcat:mediaType" :content="getDistributionFormat">accessURL</small>
        </app-link>
      </div>
    </div>
  </div>
</template>

<script>

import {
  AppLink, DistributionDownloadAs, DistributionDropdownDownload
} from '@piveau/piveau-hub-ui-modules';

export default {
  name: "DistributionDownload",
  props: [
    "getDownloadUrl",
    "showAccessUrls",
    "isOnlyOneUrl",
    "trackGoto",
    "replaceHttp",
    "getDistributionFormat",
    "distribution"
  ],
  components: {
    DistributionDropdownDownload,
    DistributionDownloadAs,
    AppLink
  },
  methods: {
    setClipboard(value) {
      const input = document.createElement('INPUT');
      // input.style = "position: absolute; left: -1000px; top: -1000px";
      input.value = value;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
  }
}
</script>

<style scoped lang="scss">
  .download-container {
    @apply relative inline-block;

    &:hover {
      .download-container--content {
        @apply block;
      }
      .less {
        @apply block;
      }
      .expand {
        @apply hidden;
      }
    }

    .less {
      @apply hidden;
    }

    .download-container--button {
      @apply text-primary-700 cursor-pointer flex items-center;
    }
    .download-container--content {
      @apply hidden absolute max-w-max right-auto left-auto bg-white;

      &__link {
        @apply flex px-4 py-2 items-center;

        i {
          @apply mr-4;
        }
      }
    }
  }
</style>
