<template>
  <div class="">
    <div class="" v-if="showObject(catalog)">
      <div class="small cat-container">
        <!-- TITLE -->
        <dl v-if="has(catalog, 'title') && showObject(catalog.title)">
          <dt>
                  <span :title="$t('message.tooltip.catalogDetails.title')"
                        data-toggle="tooltip"
                        data-placement="right">
                        {{ $t('message.metadata.title') }}
                  </span>
          </dt>
          <dd>{{ getTranslationFor(catalog.title, $route.query.locale, catalog.languages) }}</dd>
          <dd>
            <app-link :to="catalog.homepage">
              {{ catalog.homepage }}
            </app-link>
          </dd>
        </dl>
        <!-- DESCRIPTION -->
        <dl v-if="has(catalog, 'description') && showObject(catalog.description)">
          <dt>
            <span :title="$t('message.tooltip.catalogDetails.description')"
                  data-toggle="tooltip"
                  data-placement="right">
                  {{ $t('message.metadata.description') }}
            </span>
          </dt>
          <dd>{{ getTranslationFor(catalog.description, $route.query.locale, catalog.languages) }}</dd>
        </dl>
        <!-- PUBLISHER -->
        <dl v-if="has(catalog, 'publisher') && showObject(catalog.publisher)">
          <dt>
                  <span :title="$t('message.tooltip.catalogDetails.publisher')"
                        data-toggle="tooltip"
                        data-placement="right">
                          {{ $t('message.metadata.publisher') }}
                  </span>
          </dt>
          <dd v-if="has(catalog, 'publisher.name')  && showString(catalog.publisher.name)">{{ catalog.publisher.name }}</dd>
          <dd>
            <app-link v-if="has(catalog, 'publisher.homepage') && showString(catalog.publisher.homepage)" :to="catalog.publisher.homepage">
              {{ catalog.publisher.homepage }}
            </app-link>
          </dd>
          <dd>
            <app-link v-if="has(catalog, 'publisher.email') && showString(catalog.publisher.email)" :to="catalog.publisher.email">
              {{ catalog.publisher.email }}
            </app-link>
          </dd>
        </dl>
        <!-- CREATOR -->
        <dl v-if="has(catalog, 'creator') && showObject(catalog.creator)">
          <dt>{{ $t('message.metadata.creator') }}</dt>
          <dd v-if="has(catalog, 'creator.name')  && showString(catalog.creator.name)">{{ catalog.creator.name }}</dd>
          <dd>
            <app-link v-if="has(catalog, 'creator.homepage') && showString(catalog.creator.homepage)" :to="catalog.creator.homepage">
              {{ catalog.creator.homepage }}
            </app-link>
          </dd>
          <dd>
            <app-link v-if="has(catalog, 'creator.email') && showString(catalog.creator.email)" :to="catalog.creator.email">
              {{ catalog.creator.email }}
            </app-link>
          </dd>
        </dl>
        <!-- LANGUAGES -->
        <dl v-if="has(catalog, 'languages') && showArray(catalog.languages)">
          <dt>
            <span :title="$t('message.tooltip.catalogDetails.language')"
                  data-toggle="tooltip"
                  data-placement="right">
                    {{ $t('message.metadata.languages') }}
            </span>
          </dt>
          <dd v-for="lang in catalogLanguageIds" :key="lang">{{ lang }}</dd>
        </dl>
        <!-- Availability -->
        <dl v-if="has(catalog, 'availability') && showObject(catalog.availability)">
          <dt>
            <!-- TODO Tooltip string -->
            <span :title="$t('message.tooltip.catalogDetails.availability')"
                data-toggle="tooltip"
                data-placement="right">
                {{ $t('message.metadata.availability') }}
            </span>
          </dt>
          <dd v-if="has(catalog.availability, 'label') && !isNil(catalog.availability.label)"> {{ $t('message.metadata.label') }}: {{ catalog.availability.label }}</dd>
          <dd v-if="has(catalog.availability, 'resource') && !isNil(catalog.availability.resource)"> {{ $t('message.metadata.resource') }}: <a :href="catalog.availability.resource">{{ catalog.availability.resource }}</a></dd>
        </dl>
        <!-- LICENCE -->
        <dl v-if="has(catalog, 'licence') && showObject(catalog.licence)">
          <dt>{{ $t('message.catalogFacets.facets.licences') }}</dt>
          <dd v-if="has(catalog, 'licence.label') && showString(catalog.licence.label)">{{ catalog.licence.label }}</dd>
          <dd v-if="has(catalog, 'licence.description') && showString(catalog.licence.description)">{{ catalog.licence.description }}</dd>
          <dd>
            <app-link v-if="has(catalog, 'licence.resource') && showString(catalog.licence.resource)" :to="catalog.licence.resource">
              {{ catalog.licence.resource }}
            </app-link>
          </dd>
          <dd>
            <app-link v-if="has(catalog, 'licence.la_url') && showString(catalog.licence.la_url)" :to="catalog.licence.la_url">
              {{ catalog.licence.la_url }}
            </app-link>
          </dd>
        </dl>
        <!-- ISSUED -->
        <dl v-if="has(catalog, 'issued') && !isNil(catalog.issued)">
          <dt>
            <span :title="$t('message.tooltip.catalogDetails.created')"
                    data-toggle="tooltip"
                    data-placement="right">
              {{ $t('message.metadata.created') }}
            </span>
          </dt>
          <dd>{{ formatDate(catalog.issued) }}</dd>
        </dl>
        <!-- MODIFIED -->
        <dl v-if="has(catalog, 'modified') && !isNil(catalog.modified)">
          <dt :title="$t('message.tooltip.catalogDetails.updated')">
              <span :title="$t('message.tooltip.catalogDetails.updated')"
                      data-toggle="tooltip"
                      data-placement="right">
              {{ $t('message.metadata.updated') }}
            </span>
          </dt>
          <dd>{{ formatDate(catalog.modified) }}</dd>
        </dl>
        <!-- <dl v-if="has(catalog, 'availability') && !isNil(catalog.availability)">
          <dt :title="$t('message.tooltip.catalogDetails.updated')">
              <span :title="$t('message.tooltip.catalogDetails.updated')"
                      data-toggle="tooltip"
                      data-placement="right">
              {{ 'Availability' }}
            </span>
          </dt>
          <dd>{{ catalog.availability }}</dd>
        </dl> -->
        <!-- RIGHTS -->
        <dl v-if="has(catalog, 'rights') && showObject(catalog.rights)">
          <dt>{{ $t('message.metadata.rights') }}</dt>
          <dd v-if="has(catalog, 'rights.label')  && showString(catalog.rights.label)">{{ catalog.rights.label }}</dd>
          <dd>
            <app-link v-if="has(catalog, 'rights.resource') && showString(catalog.rights.resource)" :to="catalog.rights.resource">
              {{ catalog.rights.resource }}
            </app-link>
          </dd>
        </dl>
        <!-- IS PART OF -->
        <dl v-if="has(catalog, 'isPartOf') && showString(catalog.isPartOf)">
          <dt>{{ $t('message.metadata.isPartOf') }}</dt>
          <dd>{{ catalog.isPartOf }}</dd>
        </dl>
        <!-- HAS PART -->
        <dl v-if="has(catalog, 'hasPart') && showArrayOfStrings(catalog.hasPart)">
          <dt>{{ $t('message.metadata.hasPart') }}</dt>
          <dl v-for="(hasPart, i) of catalog.hasPart" :key="i">
            {{ hasPart }}
          </dl>
        </dl>
        <!-- THEME TAXONOMY -->
        <dl v-if="has(catalog, 'themeTaxonomy') && showArrayOfStrings(catalog.themeTaxonomy)">
          <dt>{{ $t('message.metadata.themeTaxonomy') }}</dt>
          <dl v-for="(themeTaxonomy, i) of catalog.themeTaxonomy" :key="i">
            {{ themeTaxonomy }}
          </dl>
        </dl>
        <!-- SPATIAL -->
        <dl v-if="has(catalog, 'spatial') && showObjectArray(catalog.spatial)">
          <dt>{{ $t('message.metadata.spatial') }}</dt>
          <dl v-for="(spatial, i) of catalog.spatial" :key="i">
            <dd v-if="has(spatial, 'coordinates') && showString(spatial.coordinates)">{{ $t('message.metadata.coordinates') }}: {{ spatial.coordinates }}</dd>
            <dd v-if="has(spatial, 'type') && showString(spatial.type)">{{ $t('message.metadata.type') }}: {{ spatial.type }}</dd>
          </dl>
        </dl>
        <!-- SPATIAL RESOURCE -->
        <dl v-if="has(catalog, 'spatialResource') && showArray(catalog.spatialResource)">
          <dt>{{ $t('message.metadata.spatialResource') }}</dt>
          <dl v-for="(spatialResource, i) of catalog.spatialResource" :key="i">
            <dd>
              <app-link v-if="showString(spatialResource)" :to="spatialResource">
                {{ spatialResource }}
              </app-link>
            </dd>
          </dl>
        </dl>
        <!-- LINKED DATA -->
        <dl>
          <div class="linked-container">
            <div class="linked-container--button">
              <app-link class="text-dark p-0 font-weight-bold nav-link underline dropdown-toggle text-nowrap" fragment="#" role="button" id="linkedDataDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span :title="$t('message.tooltip.catalogDetails.metadata')"
                              data-toggle="tooltip"
                              data-placement="right">
                {{ $t('message.catalogs.downloadAsLinkedData') }}
                </span>
              </app-link>
            </div>
            <div class="linked-container--content">
              <ul>
                <li>
                  <resource-details-linked-data-button class="dropdown-item" format="rdf" text="RDF/XML" resources="catalogues" v-bind:resources-id="catalog.id"></resource-details-linked-data-button>
                </li>
                <li>
                  <resource-details-linked-data-button class="dropdown-item" format="ttl" text="Turtle" resources="catalogues" v-bind:resources-id="catalog.id"></resource-details-linked-data-button>
                </li>
                <li>
                  <resource-details-linked-data-button class="dropdown-item" format="n3" text="Notation3" resources="catalogues" v-bind:resources-id="catalog.id"></resource-details-linked-data-button>
                </li>
                <li>
                  <resource-details-linked-data-button class="dropdown-item" format="nt" text="N-Triples" resources="catalogues" v-bind:resources-id="catalog.id"></resource-details-linked-data-button>
                </li>
                <li>
                  <resource-details-linked-data-button class="dropdown-item" format="jsonld" text="JSON-LD" resources="catalogues" v-bind:resources-id="catalog.id"></resource-details-linked-data-button>
                </li>
              </ul>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
  
<script>
import {
  has, isArray, isNil, isObject, isString
} from 'lodash';

import { AppLink, dateFilters, getTranslationFor, getImg } from '@piveau/piveau-hub-ui-modules';

import ResourceDetailsLinkedDataButton from "../datasetDetails/ResourceDetailsLinkedDataButton";

export default {
  name: "CatalogDetailsFacet",
  props: [
    "catalog",
    "catalogLanguageIds"
  ],
  components: {
    AppLink,
    ResourceDetailsLinkedDataButton
  },
  methods: {
    has,
    isNil,
    getImg,
    getTranslationFor,
    formatDate(date) {
      return dateFilters.formatEU(date);
    },
    getCatalogImage(catalog) {
      return this.$env.content.catalogs.useCatalogCountries
        ? `${this.$env.content.catalogs.defaultCatalogImagePath}/${has(catalog, 'country.id') ? catalog.country.id : this.$env.content.catalogs.defaultCatalogCountryID}`
        : `${this.$env.content.catalogs.defaultCatalogImagePath}/${has(catalog, 'id') ? catalog.id : this.$env.content.catalogs.defaultCatalogID}`;
    },
    showObject(object) {
      return !isNil(object) && isObject(object) && !Object.values(object).reduce((keyUndefined, currentValue) => keyUndefined && currentValue === undefined, true);
    },
    showArrayOfStrings(stringArray) {
      return this.showArray(stringArray) && stringArray.every(currentString => this.showString(currentString));
    },
    showString(string) {
      return !isNil(string) && isString(string);
    },
    showArray(array) {
      return !isNil(array) && isArray(array) && array.length > 0;
    },
    showObjectArray(objectArray) {
      return this.showArray(objectArray) && !objectArray.reduce((objectUndefined, currentObject) => objectUndefined && Object.values(currentObject).reduce((keyUndefined, currentValue) => keyUndefined && currentValue === undefined, true), true);
    },
    testLogger() {
      console.log("test", this.catalog)
    }
  }
}
</script>

<style lang="scss">
  .cat-container {
    @apply bg-white p-4 mb-4;

    dl {
      @apply pb-4 text-primary-700;

      dt {
        @apply font-bold mb-2 text-sm;
      }

      dd {
        @apply text-sm pb-2;

        &:last-child {
          @apply pb-0;
        }
      }
    }

    .linked-container {
      @apply relative inline-block;

      &:hover {
        .linked-container--content {
          @apply block;
        }
        .less {
          @apply block;
        }
        .expand {
          @apply hidden;
        }
      }

      .less {
        @apply hidden;
      }

      .linked-container--button {
        @apply text-primary-700 cursor-pointer flex items-center text-sm;
      }
      .linked-container--content {
        @apply hidden absolute w-full right-auto left-auto bg-white;

        ul {
          @apply px-4 py-2 w-48 w-full;

          li {
            @apply cursor-pointer border-b-2 border-primary-100 mb-2 pb-2 text-primary-700 w-full;

            &:hover {
              @apply border-primary-700;
            }

            &:last-child {
              @apply mb-0;
            }
          }
        }
      }
    }

  }

  .catalog-details-table {
    overflow: auto;
    margin-bottom: 15px;
  }
</style>
