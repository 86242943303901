<template>
  <button @click="action">
    {{ label }}
    <i v-if="upArrow" class="material-icons small-icon">keyboard_arrow_up</i>
    <i v-else class="material-icons small-icon">keyboard_arrow_down</i>
  </button>
</template>

<script>
export default {
  name: "PvShowMore",
  props: {
    label: String,
    upArrow: Boolean,
    action: Function
  }
}
</script>

<style scoped>
  button {
    @apply text-white flex items-center;
  }
</style>
