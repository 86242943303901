<template>
  <div class="dataset-footer">
    <div class="dataset-footer--date">
      <div v-if="updatedDate">
        updated: <dataset-date class="" :date="updatedDate"/>
      </div>
      <div v-if="createdDate">
        created: <dataset-date class="" :date="createdDate"/>
      </div>
    </div>
    <div v-if="catalog" class="dataset-footer--catalog">
      <img
        class=""
        :src="src"
        :alt="catalog">
      <span class=""> {{ catalog }} </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import { DatasetDate, Tooltip } from '@piveau/piveau-hub-ui-modules';

export default Vue.extend({
  name: 'PvDataInfoBoxFooter',
  components: {
    DatasetDate,
    Tooltip,
  },
  props: {

    createdDate: {
      type: String,
      default: null,
    },

    updatedDate: {
      type: String,
      default: null,
    },

    catalog: {
      type: String,
      required: false,
    },

    /**
     * Image source for the catalog logo/flag.
     */
    src: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
  .dataset-footer {
    @apply flex justify-between border-b-2 border-primary-100 pb-2;

    .dataset-footer--date {
      @apply text-primary-400 flex text-sm;

      div:first-child {
        @apply mr-4;
      }
    }
    .dataset-footer--catalog {
      @apply text-primary-400 flex text-sm;

      img {
        @apply w-6 mr-4;
      }
    }
  }
</style>