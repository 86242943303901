<template>
  <button :class="`${filterButton ? 'filter-button' : ''}`" @click="action">
    {{ label }}
    <slot />
  </button>
</template>

<script>
export default {
  name: "PvButton",
  props: {
    primary: Boolean,
    label: String,
    action: {
      type: Function,
      default: () => {}
    },
    small: Boolean,
    rounded: Boolean,
    filterButton: Boolean,
  }
}
</script>

<style lang="scss" scoped>
  button {
    @apply text-white;
  }

  .filter-button {
    @apply text-primary-700 bg-white px-8 py-4 border-2 border-white;

    &:hover {
      @apply bg-primary-700 text-white transition-all duration-75 border-2 border-white;
    }
  }
</style>
