<template>
  <div class="header">
    <nav class="header-bar">
      <div class="header-bar--logo">
        <a :href="portalUrl">
          <img id="logo" src="@/assets/img/possible_logo_blue.svg" width="142" />
        </a>
      </div>
      <div class="header-bar--nav">
        <router-link
          v-for="(navItem, i) in navItems"
          :key="`navItem@${i}`"
          :to="navItem.to"
          active-class="header-bar--nav__active"
          >{{ navItem.title }}</router-link
        >
        <a :href="portalUrl">About</a>
        <a :href="portalUrl">Consortium</a>
        <a :href="portalUrl">Contact</a>
        <a :href="portalUrl" class="icon">
          Login
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import LanguageSelector from "./LanguageSelector";

export default {
  name: "PossibleHeader",
  data() {
    return {
      isNuxt: false,
      useDropdown: this.$env.routing.navigation.useDropdown,
    };
  },
  components: {
    FontAwesomeIcon,
    LanguageSelector,
  },
  props: {
    locale: {
      type: String,
      default: "en",
    },
    useLanguageSelector: {
      type: Boolean,
      default: true,
    },
    headerFooterBackground: {
      default: "#6610f2",
    },
    enableAuthentication: {
      type: Boolean,
      default: false,
    },
    authenticated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navItems() {
      let navItems = [
        {
          title: "Datasets",
          to: { name: "Datasets", query: { locale: 'en' } },
          show: true,
        },
        {
          title: "Catalogues",
          to: { name: "Catalogues", query: { locale: 'en' } },
          show: true,
        },
      ];

      return navItems;
    },
    portalUrl() {
      return this.$env.portal.portalUrl;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  @apply bg-white;
}

.header-bar {
  @apply text-primary-700 flex items-end justify-between w-full max-w-screen-2xl mx-auto py-4 xl:px-20 px-8;
}

.header-bar--logo {
  @apply w-1/4 2xl:w-80 flex items-center;
}

.header-bar--nav {
  @apply flex items-center justify-end gap-4;

  &__active {
    @apply bg-primary-700 text-white;
  }

  a {
    @apply flex items-center gap-2;
    @apply px-4 font-medium transition-all duration-150;
    @apply border-2 border-transparent;
    line-height: 2.25rem;

    &:hover {
      @apply bg-primary-100;
    }

    &.header-bar--nav__active:hover {
      @apply bg-primary-900 text-white;
    }

  }
}
</style>
