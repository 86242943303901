<template>
  <div class="d-flex w-100 heading">
    <div class="col-11 py-2">
      <span class="dsd-feature-title" :data-cy="tag">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatasetDetailsFeatureHeader",
  props: {
    onClick: Function,
    title: String,
    tag: String,
    arrowDown: Boolean
  }
}
</script>

<style lang="scss" scoped>
.heading {
  cursor: pointer;
}
.arrow {
  padding-right: 0;
}
@media screen and (max-width: 991px) {
  .dsd-feature-header-arrow-container {
    width: 48px !important;
    order: 2;
    padding-right: 15px;
  }
}
</style>
