<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-possible">
        <div class="footer-circleVisual"></div>
        <img id="logo" src="@/assets/img/possible_logo_white.svg" width="190" />
      </div>
      <div class="footer-copy">
        <h5>POSSIBLE-X Catalogue</h5>
        <p>Phoenix Open software stack for interoperable engagement in Dataspaces</p>
        <div>© 2023 POSSIBLE Consortium</div>
      </div>
      <div class="footer-info">
        <h5>Information</h5>
        <ul>
          <li>
            <a href="https://www.possible-gaia-x.eu/" target="_blank">POSSIBLE Project</a>
          </li>
          <li>
            <router-link to="/imprint">Impressum</router-link>
          </li>
          <li>
            <router-link to="/privacypolicy">Datenschutz</router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PossibleFooter",
};
</script>

<style lang="scss" scoped>
footer {
  @apply text-white bg-primary-700 overflow-hidden;
}

.footer-wrapper {
  @apply grid grid-cols-3 mt-auto w-full max-w-screen-2xl mx-auto xl:px-20 px-8 py-10 gap-8;

  .footer-possible {
    @apply self-end relative;

    .footer-circleVisual {
      @apply absolute;
      @apply rounded-full border border-white;
      @apply h-[435px] w-[436px];
      @apply -left-[262px] -top-[60px];
    }
  }

  .footer-copy p {
    @apply mb-8;
  }

  .footer-info,
  .footer-copy {
    h5 {
      @apply text-2xl mb-2 leading-normal;
    }

    > *:not(h5) {
      @apply text-sm leading-normal;
    }
  }
}

a:link {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}
</style>
