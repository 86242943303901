<template>
    <div class="container-fluid datasets">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title text-primary">Provider View</h1>
          <div class="alert alert-info" role="alert">
            This page allows you to create and publish data assets.<br>
            If all steps are processed sequentially with existing data the transfer will be successful.
          </div>
          <h3>Step 1 - Create Asset</h3>
          <p>In a first step the data asset can described and the location and filename have to be specified.</p>
          <form>
            <div class="form-group">
              <label for="assetName">Asset Name</label>
              <input
                type="text"
                class="form-control"
                id="assetName"
                v-model="assetName"
              />
            </div>
            <div class="form-group">
              <label for="assetDescription">Asset Description</label>
              <input
                type="text"
                class="form-control"
                id="assetDescription"
                v-model="assetDescription"
              />
            </div>
            <div class="form-group">
              <label for="contentType">Content Type</label>
              <select class="form-control" id="contentType" v-model="contentType">
                <option>application/json</option>
                <option>plain/text</option>
                <option>text/csv</option>
              </select>
            </div>
            <div class="form-group">
              <label for="bucketName">S3 Bucket Name</label>
              <input
                type="text"
                class="form-control"
                id="bucketName"
                v-model="bucketName"
              />
            </div>
            <div class="form-group">
              <label for="fileName">File Name</label>
              <input
                type="text"
                class="form-control"
                id="fileName"
                v-model="fileName"
              />
            </div>
          </form>
          <button class="btn btn-primary mb-3" @click="createAsset">Create Asset</button>
          <div v-if="preview" class="alert alert-success"> {{ preview }}</div>
          <h3>Step 2 - Create Policy</h3>
          <p>The second step requires to select a specific permission for the data asset.</p>
          <form>
            <div class="form-group">
              <label for="contentType">Permission</label>
              <select class="form-control" id="permissionEdctype" v-model="permissionEdctype">
                <option>dataspaceconnector:permission</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
          </form>
          <button class="btn btn-primary mb-3" @click="createPolicy">Create Policy</button>
          <div v-if="previewPolicy" class="alert alert-success"> {{ previewPolicy }}</div>

          <h3>Step 3 - Create ContractOffering</h3>
          <p>Finally, the contract offer is created with the pre-defined data asset and selected policy.<br>
            Navigating to Explore > Datasets shows all successfully created datasets.</p>
          <button class="btn btn-primary mb-3" @click="createContractOffer">Create Contract Offer</button>
          <div v-if="previewContractOffer" class="alert alert-success"> {{ previewContractOffer }}</div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "Provider",
  data() {
    return {
      result: null,
      assetDescription: null,
      assetName: null,
      contentType: "application/json",
      bucketName: null,
      fileName: null,
      preview: null,
      previewPolicy: null,
      previewContractOffer: null,
      contractOfferId: null,
      assetId: null,
      policyId: null,
      policyUUID: null,
      config: {
        headers: {
            'X-API-Key': this.$env.edc.password,
            'content-type': "application/json"
        }
      },
      permissionEdctype: null,
    };
  },
  methods: {
    update() {
      // "https://possible.fokus.fraunhofer.de/api/hub/search/search?filter=resource_software-offering"
      let baseUrl = this.$env.api.baseUrl;
      let endpoint =  this.$env.content.resources.softwareOfferings.endpoint;

      axios
        .get(`${baseUrl}${endpoint}` 
        )
        .then((response) => {
          this.result = response.data;
        });
    },
    createAsset() {
      this.assetId = uuidv4()
      var url = this.$env.edc.managementUrl + "assets"
      var payload = {
        "@context": {
          "@vocab": "https://w3id.org/edc/v0.0.1/ns/",
          "edc": "https://w3id.org/edc/v0.0.1/ns/",
          "odrl": "http://www.w3.org/ns/odrl/2/"
        },
        "edc:asset": {
          "@id": this.assetId,
          "@type": "edc:Asset",
          "edc:properties": {
            "edc:name": this.assetName,
            "edc:description": this.assetDescription,
            "edc:contenttype": this.contentType,
            "edc:version": "v1.2.3",
          },
        },
        "edc:dataAddress": {
          "@type": "edc:DataAddress",
          "edc:bucketName": this.bucketName,
          "edc:container": this.bucketName,
          "edc:blobName": this.fileName,
          "edc:keyName": this.fileName,
          "edc:storage": "s3-eu-central-1.ionoscloud.com",
          "edc:name": this.fileName,
          "edc:type": "IonosS3",
        },
      };
      axios.post(url, payload, this.config).then((response) => {
        this.preview = "Created Asset with ID: " + response.data["@id"]
      })
    },
    createPolicy() {
      this.policyId = uuidv4()
      this.policyUUID = uuidv4()

      var url = this.$env.edc.managementUrl + "policydefinitions"

      var payload = {
          "@context": {
            "@vocab": "https://w3id.org/edc/v0.0.1/ns/",
            "edc": "https://w3id.org/edc/v0.0.1/ns/",
            "odrl": "http://www.w3.org/ns/odrl/2/"
          },
           "@id": this.policyId,
           "edc:policy": {
             "@context": "http://www.w3.org/ns/odrl.jsonld",
             "@type": "odrl:Set",
             "odrl:permission": [
               {
                 "odrl:target": this.assetId,
                 "odrl:action": {
                   "odrl:type": "USE"
                 },
                 "odrl:edctype": this.permissionEdctype
               }
             ],
           }
         }

      axios.post(url, payload, this.config).then((response) => {
        this.previewPolicy = "Created Policy with ID: " + response.data["@id"]
      });
    },
    createContractOffer() {
      this.contractOfferId = uuidv4()

      var url = this.$env.edc.managementUrl + "contractdefinitions"

      var payload = {
        "@context": {
          "@vocab": "https://w3id.org/edc/v0.0.1/ns/",
          "edc": "https://w3id.org/edc/v0.0.1/ns/",
          "odrl": "http://www.w3.org/ns/odrl/2/"
        },
        "@id": this.contractOfferId,
        "@type": "edc:ContractDefinition",
        "edc:accessPolicyId": this.policyId,
        "edc:contractPolicyId": this.policyId,
        "edc:assetsSelector": []
      };
      axios.post(url, payload, this.config).then((response) => {
        this.previewContractOffer = "Created Contract Offer with ID: " + response.data["@id"]
      });
    },
  },
};
</script>
