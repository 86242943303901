<template>
  <div>
<!--    <distribution-options-dropdown
      :showTooltipVisualiseButton="showTooltipVisualiseButton"
      :isUrlInvalid="isUrlInvalid"
      :getVisualisationLink="getVisualisationLink"
      :distribution="distribution"
      :openIfValidUrl="openIfValidUrl"
      :previewLinkCallback="previewLinkCallback"
      class="distribution-action"
    />-->
    <distribution-download
      v-if="showDownloadDropdown(distribution)"
      :getDownloadUrl="getDownloadUrl"
      :showAccessUrls="showAccessUrls"
      :isOnlyOneUrl="isOnlyOneUrl"
      :trackGoto="trackGoto"
      :getDistributionFormat="getDistributionFormat"
      :replaceHttp="replaceHttp"
      :distribution="distribution"
      class="distribution-action"
    />
    <linked-data-buttons-dropdown
      :distributions="distributions"
      :distribution="distribution"
      resourceType="distributions"
      class="distribution-action"
    />
  </div>
</template>

<script>

import {
  AppLink
} from '../../../widgets/AppLink';

import DistributionDownload from "./DistributionDownload";
import LinkedDataButtonsDropdown from "./LinkedDataButtonsDropdown";
import DistributionOptionsDropdown from "./DistributionOptionsDropdown";

export default {
  name: "DistributionActions",
  components: {AppLink, LinkedDataButtonsDropdown, DistributionDownload, DistributionOptionsDropdown},
  props: {
    distribution: Object,
    distributions: Object,
    isUrlInvalid: Function,
    getVisualisationLink: Function,
    showTooltipVisualiseButton: Function,
    previewLinkCallback: Function,
    openIfValidUrl: Function,
    showDownloadDropdown: Function,
    getDownloadUrl: Function,
    showAccessUrls: Function,
    isOnlyOneUrl: Function,
    trackGoto: Function,
    getDistributionFormat: Function,
    replaceHttp: Function,
  },
  computed: {
    showValidateButton() {
      return this.$env?.datasetDetails?.distributions?.showValidationButton;
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    @apply grid grid-cols-3 xl:grid-cols-4;
  }
</style>
