






























































































// @ts-ignore
import { Resource } from "types/global";
import { PropType, defineComponent } from "vue";
import type RouteLocationRaw from "vue-router";

import PvBadge from '../widgets/PvBadge.vue';
import PvDataInfoBoxFooter from "./PvDataInfoBoxFooter.vue";
import PvDataInfoBoxDescription from "./PvDataInfoBoxDescription.vue";

export default defineComponent({
  name: "PvDataInfoBox",
  components: {
    PvBadge,
    PvDataInfoBoxFooter,
    PvDataInfoBoxDescription,
  },
  props: {
    /**
     * The resource to display.
     * TODO: Replace Type definition of Dataset by generic solution
     */
    resource: {
      type: Object as PropType<Resource>,
      default: () =>
        ({
          id: "",
          description: "",
        } as Resource),
    },

    /**
     * The route to navigate to when clicking on the resource.
     */
    to: {
      type: [Object, String] as PropType<RouteLocationRaw | string>,
      required: true,
    },

    /**
     * Specified the maximum length of the description before it is cut off.
     */
    descriptionMaxLength: {
      type: Number,
      default: 200,
    },

    /**
     * Image source for the catalog logo/flag.
     */
    src: {
      type: String,
      default: "",
    },

    /**
     * If true, swaps to catalog display mode
     */
    catalogMode: {
      type: Boolean,
      default: false,
    },

    /**
     * If true switches from two column to one column layout
     * and decreases vertical distancing between elements.
     */
    compact: {
      type: Boolean,
      default: false,
    },

    dataset: {
      type: Object,
      default: {},
    },
  },

  computed: {},
});
