<template>
  <div
    class="dataset-wrapper"
    :data-cy="getRepresentativeLocaleOf(getTitle, $route.query.locale, getLanguages)
    && `dataset@${getRepresentativeLocaleOf(getTitle, $route.query.locale, getLanguages)}`"
  >
    <dataset-details-navigation v-if="topTitle" :dataset-id="getID"/>
    <div>
      <router-view name="datasetDetailsSubpages"></router-view>
    </div>
  </div>
</template>


<script>
import { getCurrentInstance } from 'vue';
import { mapGetters } from 'vuex';

import {
  getTranslationFor,
  getRepresentativeLocaleOf,
} from '../utils/helpers.ts';
import { useDownloadDatasetOnMount } from '../composables/useDownloadDatasetOnMount';
import DatasetDetailsNavigation from './DatasetDetailsNavigation';
import DatasetDetailsHeader from './DatasetDetailsHeader';


export default {
  name: 'datasetDetails',
  components: {
    DatasetDetailsNavigation,
    DatasetDetailsHeader
  },
  metaInfo() {
    const datasetTitleTranslated = this.getTranslationFor(this.getTitle, this.$route.query.locale, this.getLanguages);
    const title = datasetTitleTranslated ? `${datasetTitleTranslated} - ${this.$env.metadata.title}` : undefined;
    return {
      titleTemplate(chunk) {
        return chunk ? `${chunk} - ${title}` : title;
      },
    };
  },
  data() {
    return {
      topTitle: this.$env.content.datasetDetails.header.navigation === "top"
    };
  },
  props: {
    activeTab: {
      type: Number,
      default: 0
    },
    citationStyle: String
  },
  computed: {
    ...mapGetters('datasetDetails', [
      'getID',
      'getLanguages',
      'getTitle',
      'getDescription',
    ]),
  },
  methods: {
    getRepresentativeLocaleOf,
    getTranslationFor,
  },
  setup() {
    const vm = getCurrentInstance();
    const router = vm.proxy.$router;
    const hubUrl = vm.proxy.$env.api.hubUrl;
    // useDownloadDatasetOnMount({ router, hubUrl });
  }
};
</script>

<style lang="scss" scoped>
  .dataset-wrapper {
    @apply mx-auto px-8 pb-8 w-full max-w-screen-2xl mb-20;
  }

</style>
