<template>
  <div class="catalogs-wrapper">
    <div class="catalogs-container">
      <div class="catalogs-container--filter">
        <catalogues-facets v-if="useCatalogFacets"></catalogues-facets>
      </div>
      <div class="catalogs-container--sets">
        <div class="catalogs-container--header">
          <h1>{{ $t('message.header.navigation.data.catalogs') }}</h1>
          <datasets-filters></datasets-filters>
          <div class="catalogs-infobar" role="status">
            <div>
              {{ getLoading ? $t('message.catalogs.loadingMessage'):`${$t('message.catalogs.countMessage')} (${getCatalogsCount.toLocaleString('fi')})`}}
            </div>
            <div class="loading-spinner ml-3" v-if="getLoading"></div>
          </div>
        </div>
        <div class="dataset-container--items">
          <pv-data-info-box
            v-for="catalog in getCatalogs"
            :key="`data-info-box@${catalog.id}`"
            catalog-mode
            :to="{
              path: `/catalogues/${catalog.id}?locale=en`,
              query: {
                locale: $route.query.locale
              },
            }"
            :src="getImg(getCatalogImage(catalog))"
            :dataset="{
              title: getTranslationFor(catalog.title, $route.query.locale, getCatalogLanguages(catalog)),
              description:
                getTranslationFor(catalog.description, $route.query.locale, getCatalogLanguages(catalog)),
              catalog: getTranslationFor(catalog.title, $route.query.locale, getCatalogLanguages(catalog)),
              createdDate: null,
              updatedDate: null,
              formats: [],
            }"
            :description-max-length="1000"
            :data-cy="`catalog@${catalog.id}`"
            class="catalogs-box"
          />
          <div class="loading-spinner mx-auto mt-3 mb-3" v-if="getLoading"></div>
        </div>
        <div class="catalogs-container--pagination">
          <pagination class="mt-3"
            :items-count="getCatalogsCount"
            :items-per-page="getLimit"
            :get-page="getPage"
            :get-page-count="getPageCount"
            @setPageLimit="setPageLimit"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';
  import { mapActions, mapGetters } from 'vuex';
  import { debounce, has } from 'lodash-es';

  import { SelectedFacetsOverview, getTranslationFor, getCountryFlagImg, getImg, fileTypes, dateFilters } from '@piveau/piveau-hub-ui-modules';

  import CataloguesFacets from './CataloguesFacets'
  import DatasetsFilters from '../datasets/DatasetsFilters'
  import Pagination from '../widgets/Pagination'
  import PvDataInfoBox from '../datasets/PvDataInfoBox'

  export default {
    name: 'catalogs',
    dependencies: ['catalogService'],
    components: {
      DatasetsFilters,
      SelectedFacetsOverview,
      CataloguesFacets,
      Pagination,
      PvDataInfoBox
    },
    props: {
      infiniteScrolling: {
        type: Boolean,
        default: false,
      },
      pagination: {
        type: Boolean,
        default: true,
      },
    },
    metaInfo() {
      return {
        title: this.currentSearchQuery ? `${this.currentSearchQuery}` : `${this.$t('message.header.navigation.data.catalogs')}`,
        meta: [
          { name: 'description', vmid: 'description', content: `${this.$t('message.catalogs.meta.description')}` },
          { name: 'keywords', vmid: 'keywords', content: `${this.$env.metadata.keywords} ${this.$t('message.catalogs.meta.description')}` },
          { name: 'robots', content: 'noindex, follow' },
        ],
      };
    },
    data() {
      return {
        autocompleteData: {
          suggestions: {},
          show: true,
        },
        debouncedOnBottomScroll: debounce(this.onBottomScroll, 500),
        facetFields: [],
        query: '',
        sortSelected: `relevance desc, modified desc, title.${this.$route.query.locale} asc`,
        sortSelectedLabel: this.$t('message.sort.relevance'),
        currentSearchQuery: this.$route.query.query,
        useEditorialContent: this.$env.content.useEditorialContent,
        useCatalogFacets: this.$env.content.catalogs.facets.useCatalogFacets,
      };
    },
    computed: {
      ...mapGetters('catalogs', [
        'getCatalogs',
        'getCatalogsCount',
        'getFacets',
        'getLimit',
        'getLoading',
        'getOffset',
        'getPage',
        'getPageCount',
        'getAvailableFacets',
      ]),
      page() {
        return this.$route.query.page;
      },
      facets() {
        const facets = {};
        for (const field of this.facetFields) {
          let urlFacets = this.$route.query[field];
          if (!urlFacets) urlFacets = [];
          else if (!Array.isArray(urlFacets)) urlFacets = [urlFacets];
          facets[field] = urlFacets;
        }
        return facets;
      },
    },
    methods: {
      ...mapActions('catalogs', [
        'autocompleteQuery',
        'loadCatalogs',
        'loadAdditionalCatalogs',
        'setQuery',
        'setPage',
        'useService',
        'addFacet',
        'setFacets',
        'setFacetOperator',
        'setFacetGroupOperator',
        'setPageCount',
        'setSort',
        'setLimit',
        'setLoading',
      ]),
      has,
      getImg,
      getTranslationFor,
      getCountryFlagImg,
      initLimit() {
        const limit = parseInt(this.$route.query.limit, 10);
        if (limit > 0) this.setLimit(limit);
      },
      setPageLimit(value) {
          this.setLimit(value);
          this.initCatalogues();
      },
      initPage() {
        const page = parseInt(this.$route.query.page, 10);
        if (page > 0) this.setPage(page);
        else this.setPage(1);
      },
      initQuery() {
        let query = this.$route.query.query;
        if (!query) {
          query = '';
          this.setQuery('');
        } else {
          this.query = query;
          this.setQuery(query);
        }
      },
      initSort() {
        let sort = this.$route.query.sort;
        if (sort) {
          sort = sort.split(',')[0].toLowerCase();
          if (sort === 'relevance+desc') this.sortSelectedLabel = this.$t('message.sort.relevance');
          if (sort.includes('title') && sort.includes('asc')) this.sortSelectedLabel = this.$t('message.sort.nameAZ');
          if (sort.includes('title') && sort.includes('desc')) this.sortSelectedLabel = this.$t('message.sort.nameZA');
          if (sort === 'modified+desc') this.sortSelectedLabel = this.$t('message.sort.lastUpdated');
          if (sort === 'issued+desc') this.sortSelectedLabel = this.$t('message.sort.lastCreated');
          this.sortSelected = this.$route.query.sort;
        }
      },
      initFacetOperator() {
        const op = this.$route.query.facetOperator;
        if (op === 'AND' || op === 'OR') this.setFacetOperator(op);
      },
      initFacetGroupOperator() {
        // const op = this.$route.query.facetGroupOperator;
        const op = this.$route.query.facetOperator;
        if (op === 'AND' || op === 'OR') this.setFacetGroupOperator(op);
      },
      initFacets() {
        const fields = this.$env.content.catalogs.facets.defaultFacetOrder;
        for (const field of fields) {
          this.facetFields.push(field);
          if (!Object.prototype.hasOwnProperty.call(this.$route.query, [field])) {
            this.$router.replace({
              query: Object.assign({}, this.$route.query, { [field]: [] }),
            });
          } else {
            for (const facet of this.$route.query[field]) {
              this.addFacet({ field, facet });
            }
          }
        }
      },
      initCatalogues() {
        this.$nextTick(() => {
          this.$Progress.start();
          this.loadCatalogs({})
            .then(() => {
              this.setPageCount(Math.ceil(this.getCatalogsCount / this.getLimit));
              this.$Progress.finish();
              $('[data-toggle="tooltip"]').tooltip({
                  container: 'body',
              });
            })
            .catch(() => this.$Progress.fail());
        });
      },
      initInfiniteScrolling() {
          if (this.infiniteScrolling) window.addEventListener('scroll', this.onScroll);
      },
      setSortMethod(method, order, label) {
        this.sortSelectedLabel = label;
        if (method === 'relevance') this.sortSelected = `${method}+${order}, modified+desc, title.${this.$route.query.locale}+asc`;
        if (method === 'modified') this.sortSelected = `${method}+${order}, relevance+desc, title.${this.$route.query.locale}+asc`;
        if (method === `title.${this.$route.query.locale}`) this.sortSelected = `${method}+${order}, relevance+desc, modified+desc`;
        if (method === `title.${this.$route.query.locale}`) this.sortSelected = `${method}+${order}, relevance+desc, modified+desc`;
        if (method === 'issued') this.sortSelected = `${method}+${order}, relevance+desc, title.${this.$route.query.locale}+asc`;
      },
      changeQuery(query) {
        // this.autocomplete(query);
        this.$router.replace({ query: Object.assign({}, this.$route.query, { query }) });
        this.setQuery(query);
      },
      autocomplete(query) {
        this.autocompleteQuery(query)
          .then((response) => {
            this.autocompleteData.suggestions = [];
            const suggestions = response.data.result;
            const displayedSuggestions = [];
            for (const ds of suggestions.results) {
              displayedSuggestions.push(ds);
            }
            this.autocompleteData.suggestions = displayedSuggestions;
            this.autocompleteData.show = query.length !== 0;
          })
          .catch(error => { console.error(error); });
      },
      handleSuggestionSelection(suggestion) {
        this.$router.push({ path: this.$route.path.slice(-1) === '/' ? `${this.$route.path}${suggestion.idName}` : `${this.$route.path}/${suggestion.idName}` });
      },
      onScroll() {
        const items = this.$el.querySelectorAll('.catalog');
        const lastItem = items[items.length - 1];
        if (lastItem) {
          const lastItemPos = lastItem.getBoundingClientRect();
          if (lastItemPos.bottom - window.innerHeight <= 0) {
            this.debouncedOnBottomScroll();
          }
        }
      },
      onBottomScroll() {
        this.$nextTick(() => {
          this.$Progress.start();
          this.setLoading(true);
          this.loadAdditionalCatalogs()
            .then(() => {
              this.$Progress.finish();
              this.setLoading(false);
            })
            .catch(() => {
              this.$Progress.fail();
              this.setLoading(false);
            });
        });
      },
      removeDuplicatesOf(array) {
        return [...new Set(array)];
      },
      getFileTypeColor(format) {
        return fileTypes.getFileTypeColor(format);
      },
      filterDateFormatUS(date) {
        return dateFilters.formatUS(date);
      },
      filterDateFormatEU(date) {
        return dateFilters.formatEU(date);
      },
      filterDateFromNow(date) {
        return dateFilters.fromNow(date);
      },
      getCatalogLink(catalog) {
        return `/catalogues/${catalog.id}&locale=${this.$route.query.locale}`;
      },
      getCatalogImage(catalog) {
        return this.$env.content.catalogs.useCatalogCountries
          ? `${this.$env.content.catalogs.defaultCatalogImagePath}/${has(catalog, 'country.id') ? catalog.country.id : this.$env.content.catalogs.defaultCatalogCountryID}`
          : `${this.$env.content.catalogs.defaultCatalogImagePath}/${has(catalog, 'id') ? catalog.id : this.$env.content.catalogs.defaultCatalogID}`;
      },
      getFooterTags(catalog) {
        return [`${has(catalog, 'count') ? catalog.count : 0}`];
      },
      getCatalogLanguages(catalog) {
        return has(catalog, 'country.id') ? [catalog.country.id].concat(catalog.languages) : catalog.languages;
      },
      isSortSelectedLabelInDropdown() {
        if (this.sortSelectedLabel === this.$t('message.sort.nameAZ')
          || this.sortSelectedLabel === this.$t('message.sort.nameZA')
          || this.sortSelectedLabel === this.$t('message.sort.lastCreated')) {
          return true;
        }
        return false;
      },
      isSortSelectedLabelActive(label) {
        if (label === this.sortSelectedLabel) return true;
        return false;
      },
    },
    watch: {
      facets: {
        handler(facets) {
          this.setFacets(facets);
        },
        deep: true,
      },
      page(pageStr) {
        const page = parseInt(pageStr, 10);
        if (page > 0) this.setPage(page);
        else this.setPage(1);
      },
      sortSelected: {
        handler(sort) {
          this.$router.replace({ query: Object.assign({}, this.$route.query, { sort }) });
          this.setSort(sort);
        },
        deep: true,
      },
      $route(to) {
        this.currentSearchQuery = to.query.query;
      },
    },
    created() {
      this.useService(this.catalogService);
      this.initLimit();
      this.initPage();
      this.initQuery();
      this.initSort();
      this.initFacetOperator();
      this.initFacetGroupOperator();
      this.initFacets();
      this.initCatalogues();
      this.initInfiniteScrolling();
    },
    beforeDestroy() {
      $('.tooltip').remove();
      if (this.infiniteScrolling) window.removeEventListener('scroll', this.onScroll);
    },
  };
</script>

<style lang="scss" scoped>
  .catalogs-container {
    @apply flex w-full max-w-screen-2xl mx-auto xl:px-12;
  }

  .catalogs-container--filter {
    @apply w-1/4 2xl:w-80 bg-primary-700 py-12;
  }

  .catalogs-container--sets {
    @apply w-3/4 pt-12 pb-8;

    .catalogs-container--header {
      @apply px-32;
      h1 {
        @apply text-primary-700 text-5xl font-bold mb-8;
      }

      .catalogs-infobar {
        @apply text-primary-700 text-xl py-2 border-b-2 border-primary-700 my-8;
      }
    }

    .catalogs-box {
      @apply px-32 pt-8 mb-8;

      &:hover {
        @apply bg-primary-100 shadow-md shadow-primary-400 transition-all duration-200;
      }
    }

    .catalogs-container--pagination {
      @apply px-32;
    }
  }

  .content {
    padding: 0 !important;
    margin: 0 !important;
    background-color: inherit !important;
  }
</style>