import { defineStore } from "pinia";
import { Resource } from "../../types/global";
import axios from 'axios';

const defaultState = {
  resourceType: '', // SoftwareOffering | LegalPerson | ...
  resourceTypes: [],
  searchResults: <Resource[]>[],
  query: '',
  sort: 'relevance+desc, modified+desc, name+asc',
  loading: false, // Loading animation
  page: 1, // Current page
  limit: 10, // Items per page
  itemsCount: 0, // Number items
  pageCount: 1, // Number pages (itemsCount / limit)
  facets: [], // Available Facets
  selectedFacets: {}, // Facets selected by user
};

export const useResourceStore = defineStore('resource', {
  state: () => ({
    resourceType: '', // SoftwareOffering | LegalPerson | ...
    resourceTypes: [],
    searchResults: <Resource[]>[],
    query: '',
    sort: 'relevance+desc, modified+desc, name+asc',
    loading: false, // Loading animation
    page: 1, // Current page
    limit: 10, // Items per page
    itemsCount: 0, // Number items
    pageCount: 1, // Number pages (itemsCount / limit)
    facets: [], // Available Facets
    selectedFacets: {}, // Facets selected by user
  }),
  getters: {
    getResourceType: (state) => state.resourceType,
    getResourceTypes: (state) => state.resourceTypes,
    getSearchResults: (state) => state.searchResults,
    getQuery: (state) => state.query,
    getSort: (state) => state.sort,
    getLoading: (state) => state.loading,
    getPage: (state) => state.page,
    getLimit: (state) => state.limit,
    getItemsCount: (state) => state.itemsCount,
    getPageCount: (state) => state.pageCount,
    getFacets: (state) => {
      let facets = {};
      
      state.facets.map(facet => {
        facets[facet['id']] = facet;
      });

      return facets;
    },
    getSelectedFacets: (state) => state.selectedFacets,
  },
  actions: {
    setInitialState() {
      this.$state = defaultState;
    },
    setResourceType(resourceType: string) {
      this.resourceType = resourceType;
    },
    clearFilters() {
      this.query = '';
      this.sort = 'relevance+desc, modified+desc, name+asc';
      this.page = 1;
      this.limit = 10;
      this.itemsCount = 0;
      this.pageCount = 1;
      this.selectedFacets = {};
    },
    loadResourceTypes(baseUrl: string, endpoint: string) {
      return new Promise((resolve, reject) => {
        const url = `${baseUrl}${endpoint}`;
        axios.get(url, {})
          .then((response) => {
            resolve(response);

            this.resourceTypes = response.data;
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadSearchResults(baseUrl: string, endpoint: string) {

      // Save search parameters
      let query = this.query;
      let sort = this.sort;
      let page = this.page;
      let limit = this.limit;
      let selectedFacets = this.selectedFacets;

      // Start loading
      this.setLoading(true);

      // Reset state
      this.setInitialState();

      return new Promise((resolve, reject) => {
        const params = {
          q: query,
          sort: sort,
          page: (page - 1),
          limit: limit,
          facets: selectedFacets,
        };
        const reqStr = `${baseUrl}${endpoint}`;
        axios.get(reqStr, {
          params,
        })
          .then((response) => {
            resolve(response);

            // Empty search results
            this.searchResults = <Resource[]>[];
            
            // Set items count
           if (response.data.result.count) {
            this.setItemsCount(response.data.result.count);
            this.setPageCount(Math.ceil(response.data.result.count / limit));
           } else {
            this.setItemsCount(0);
            this.setPageCount(1);
           }

            // Set facets
            if (response.data.result.facets) {
              this.facets = response.data.result.facets;
            } else this.facets = [];
            
            // Set resource properties
            for (const resource of response.data.result.results) {
              this.searchResults.push({
                id: resource.id,
                details: resource,

                // TODO: Other Resource-specific properties ?
              });
            }

            // Stop loading
            this.setLoading(false);
          })
          .catch((error) => {

            // Stop loading
            this.setLoading(false);

            reject(error);
          });
      });
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setQuery(query: string) {
      this.query = query;
    },
    setSort(sort: string) {
      this.sort = sort;
    },
    setPage(page: number) {
      this.page = page;
    },
    setPageLimit(limit: number) {
      this.limit = limit;
    },
    setItemsCount(itemsCount: number) {
      this.itemsCount = itemsCount;
    },
    setPageCount(pageCount: number) {
      this.pageCount = pageCount;
    },
    setSelectedFacets(field: string, facets: object) {
      this.selectedFacets = { ...this.selectedFacets, [field]: facets};
    },
  }
})
