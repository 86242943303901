








import Vue from 'vue';

export default Vue.extend({
    name: 'PvBadge',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
});
