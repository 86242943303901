<template>
  <ul>
    <li v-for="(page, index) in pages" :key="index" >
      <dataset-details-navigation-page
        :path="page.path"
        :title="page.title"
        :tooltip="page.tooltip"
        :exact="page.exact"
      />
    </li>
  </ul>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import DatasetDetailsNavigationPage from './DatasetDetailsNavigationPage';

export default {
  name: "DatasetDetailsNavigationPages",
  components: {DatasetDetailsNavigationPage},
  computed: {
    ...mapGetters('datasetDetails', [
      'getID'
    ]),
    pages() {
      const id = this.getID;
      const createPage = (key, noPathKey, exact, cName) => ({
        path: `/datasets/${id}${noPathKey ? "" : `/${key}`}`,
        title: Vue.i18n.t(`message.datasetDetails.subnav.${key}`),
        tooltip: Vue.i18n.t(`message.tooltip.datasetDetails.${key}`),
        exact,cName
      });
      return [
        createPage("dataset", true, true),
        createPage("categories", false, false, "resource-nav-cat"),
      ];
    }
  }
}
</script>

<style scoped lang="scss">
  ul {
    @apply flex mb-8;
    li {
      @apply mr-4;
    }
  }
</style>
