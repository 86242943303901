<template>
  <div class="">
    <div class="">
      <div class="datasets-filter">
        <div class="datasets-search">
          <label>
            <input
              type="text"
              class="datasets-search--input"
              :aria-label="$t('message.datasets.searchBar.placeholder')"
              :placeholder="$t('message.datasets.searchBar.placeholder')"
              v-model="query"
              @keyup.enter="changeQuery(query)"
              @click="autocompleteData.show = autocompleteData.suggestions.length > 0 && query.length > 0 ? !autocompleteData.show : false"
            />
            <i
              class="material-icons datasets-search--icon"
              @click="changeQuery(query)"
            >search</i>
          </label>
        </div>
        <div class="datasets-filter--sort">
          <h5>
            Sort by
            <i class="material-icons expand">expand_more</i>
            <i class="material-icons less">expand_less</i>
          </h5>
          <div class="datasets-sort--button">
            <span>{{ sortSelectedLabel }}</span>
          </div>
          <div class="datasets-sort--list">
            <ul>
              <li @click="setSortMethod('modified', 'desc', $t('message.sort.lastModified'))">Last modified</li>
              <li @click="setSortMethod('relevance', 'desc', $t('message.sort.relevance'))">Relevance</li>
              <li @click="setSortMethod(`title.${$route.query.locale}`, 'asc', $t('message.sort.nameAZ'))">Name ascending</li>
              <li @click="setSortMethod(`title.${$route.query.locale}`, 'desc', $t('message.sort.nameZA'))">Name descending</li>
              <li @click="setSortMethod('issued', 'desc', $t('message.sort.lastCreated'))">Last created</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <input type="text" class="form-control rounded-lg ds-input"
            :aria-label="$t('message.datasets.searchBar.placeholder')"
            :placeholder="$t('message.datasets.searchBar.placeholder')"
            v-model="query"
            @keyup.enter="changeQuery(query)"
            @click="autocompleteData.show = autocompleteData.suggestions.length > 0 && query.length > 0 ? !autocompleteData.show : false">
      <div class="input-group-append ml-2">
        <button class="btn btn-sm btn-primary d-flex align-items-center search-button ds-input" type="button" @click="changeQuery(query)">
          <i class="material-icons align-bottom">search</i>
        </button>
      </div>
      <div class="suggestion-list-group" v-if="autocompleteData.show">
        <ul class="list-group suggestion-list">
          <button class="list-group-item list-group-item-action"
                  v-for="suggestion in autocompleteData.suggestions"
                  :key="suggestion.id"
                  @click="handleSuggestionSelection(suggestion)">
            {{ getTranslationFor(suggestion.title, $route.query.locale, suggestion.languages) }}
          </button>
        </ul>
      </div> -->
    </div>
    <!-- <ul class="mt-3 d-flex justify-content-between flex-wrap-reverse nav nav-tabs" id="datasets-filters-tab" role="tablist">
      <div v-if="useTabs" class="datasets-filters-navigation d-flex cursor-pointer">
        <li class="nav-item mb-0" role="tab"
              :title="$t('message.tooltip.datasets')"
              data-toggle="tooltip"
              data-placement="top">
            <router-link
              :to="{name: 'Datasets', query: { locale: $route.query.locale }}"
              class="nav-link"
              :class="{ 'router-link-active': $route.name === 'Datasets' }"
              role="presentation">
                  {{ $t('message.header.navigation.data.datasets') }}
            </router-link>
        </li>
        <li class="nav-item mb-0" role="tab"
            :title="$t('message.tooltip.catalogues')"
            data-toggle="tooltip"
            data-placement="top">
          <router-link
            :to="{name: 'Catalogues', query: { locale: $route.query.locale }}"
            v-if="useCatalogs"
            class="nav-link"
            :class="{ 'router-link-active': $route.name === 'Catalogues' }"
            role="presentation">
            {{ $t('message.header.navigation.data.catalogs') }}
          </router-link>
        </li>
        <li class="nav-item mb-0" role="tab"
              :title="$t('message.tooltip.softwareOfferings')"
              data-toggle="tooltip"
              data-placement="top">
            <router-link
              :to="{name: 'SoftwareOfferings', query: { locale: $route.query.locale }}"
              class="nav-link router-link-inactive"
              role="presentation">
                  {{ $t('message.header.navigation.data.softwareOfferings') }}
            </router-link>
        </li>
        <li class="nav-item mb-0" role="tab"
              :title="$t('message.tooltip.legalPerson')"
              data-toggle="tooltip"
              data-placement="top">
            <router-link
              :to="{name: 'LegalPerson', query: { locale: $route.query.locale }}"
              class="nav-link router-link-inactive"
              role="presentation">
                  {{ $t('message.header.navigation.data.legalPerson') }}
            </router-link>
        </li>
      </div>
      <div v-if="useSort" class="datasets-filters-filters btn-group border-1 mb-1 double-button" role="group" aria-label="Button group with nested dropdown">
        <button
          type="button"
          class="custom-button pl-2 pr-2 border-radius-start d-flex align-items-center inactive-styles"
          :class="{'active-styles': isSortSelectedLabelActive($t('message.sort.lastUpdated'))}"
          :title="$t('message.tooltip.lastModified')"
          data-toggle="tooltip"
          data-placement="top"
          @click="setSortMethod('modified', 'desc', $t('message.sort.lastModified'))"
        >
          {{ $t('message.sort.lastUpdated') }}
        </button>
        <button
          type="button"
          class="custom-middle-button pl-2 pr-2 d-flex align-items-center inactive-styles"
          :class="{'active-styles': isSortSelectedLabelActive($t('message.sort.relevance'))}"
          :title="$t('message.tooltip.relevance')"
          data-toggle="tooltip"
          data-placement="top"
          @click="setSortMethod('relevance', 'desc', $t('message.sort.relevance'))"
        >
          {{ $t('message.sort.relevance') }}
        </button>
        <div class="btn-group" role="group">
          <button v-if="isSortSelectedLabelInDropdown()" class="active-styles d-flex align-items-center custom-dropdown-button border-radius-end" id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-expanded="false">
            <div class="pl-2 h-100 d-flex align-items-center">
              {{ sortSelectedLabel }}
            </div>
            <i class="pr-2 material-icons small-icon dropdown-icon">arrow_drop_down</i>
          </button>
          <button v-else class="d-flex align-items-center custom-dropdown-button border-radius-end inactive-styles" id="btnGroupDrop1" type="button" data-toggle="dropdown" aria-expanded="false">
            <div class="pl-2">
              {{ $t('message.catalogsAndDatasets.more') }}
            </div>
            <i class="pr-2 material-icons small-icon dropdown-icon">arrow_drop_down</i>
          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
          <button class="dropdown-item" @click="setSortMethod(`title.${$route.query.locale}`, 'asc', $t('message.sort.nameAZ'))">
            {{ $t('message.sort.nameAZ') }}</button>
          <button class="dropdown-item" @click="setSortMethod(`title.${$route.query.locale}`, 'desc', $t('message.sort.nameZA'))">
            {{ $t('message.sort.nameZA') }}</button>
          <button class="dropdown-item" @click="setSortMethod('issued', 'desc', $t('message.sort.lastCreated'))">
            {{ $t('message.sort.lastCreated') }}</button>
          </ul>
        </div>
      </div>
    </ul> -->
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { getTranslationFor } from '@piveau/piveau-hub-ui-modules';

  export default {
    name: "DatasetsFilters",
    data() {
      return {
        query: '',
        autocompleteData: {
          suggestions: {},
          show: true,
        },
        useCatalogs: this.$env.content.datasets.useCatalogs,
        useEditorialContent: this.$env.content.useEditorialContent,
        useTabs: this.$env.content.useTabs,
        useSort: this.$env.content.datasets.useSort,
        sortSelected: '',
        sortSelectedLabel: this.$t('message.sort.relevance'),
        sortDropdown: false,
      }
    },
    methods: {
      getTranslationFor,
      ...mapActions('datasets', [
        'setQuery',
        'autocompleteQuery',
        'setSort'
      ]),
      /**
      * @description Initialize the query String by checking the route parameters
      */
      initQuery() {
        let query = this.$route.query.query;
        if (!query) {
          query = '';
          this.setQuery('');
        } else {
          this.query = query;
          this.setQuery(query);
        }
      },
      changeQuery(query) {
        this.$router.replace(
          { query: Object.assign({}, this.$route.query, { query }, { page: 1 }) }
        ).catch(
          error => {}
        );
        this.setQuery(query);
      },
      handleSuggestionSelection(suggestion) {
        /* eslint-disable no-underscore-dangle */
        this.$router.push(
          { path: this.$route.path.slice(-1) === '/' ? `${this.$route.path}${suggestion.idName}` : `${this.$route.path}/${suggestion.idName}` }
        ).catch(
          error => {}
        );
      },
      initSort() {
        let sort = this.$route.query.sort;
        if (sort) {
          sort = sort.split(',')[0].toLowerCase();
          if (sort.includes('title')) {
            if (sort.includes('desc')) {
              this.sortSelectedLabel = this.$t('message.sort.nameZA');
              this.setSortMethod(`title.${this.$route.query.locale}`, 'desc', this.$t('message.sort.nameZA'));
            } else {
              this.sortSelectedLabel = this.$t('message.sort.nameAZ');
              this.setSortMethod(`title.${this.$route.query.locale}`, 'asc', this.$t('message.sort.nameAZ'));
            }
          } else {
            if (sort === 'relevance+desc') {
              this.sortSelectedLabel = this.$t('message.sort.relevance');
              this.setSortMethod('relevance', 'desc', this.$t('message.sort.relevance'));
            }
            if (sort === 'modified+desc') {
              this.sortSelectedLabel = this.$t('message.sort.lastUpdated');
              this.setSortMethod('modified', 'desc', this.$t('message.sort.lastUpdated'));
            }
            if (sort === 'issued+desc') {
              this.sortSelectedLabel = this.$t('message.sort.lastCreated');
              this.setSortMethod('issued', 'desc', this.$t('message.sort.lastCreated'));
            }
          }
        } else this.setSort(`relevance+desc, modified+desc, title.${this.$route.query.locale}+asc`);
      },
      setSortMethod(method, order, label) {
        this.sortSelectedLabel = label;
        if (method === 'relevance') this.sortSelected = `${method}+${order}, modified+desc, title.${this.$route.query.locale}+asc`;
        if (method === 'modified') this.sortSelected = `${method}+${order}, relevance+desc, title.${this.$route.query.locale}+asc`;
        if (method === `title.${this.$route.query.locale}`) this.sortSelected = `${method}+${order}, relevance+desc, modified+desc`;
        if (method === 'issued') this.sortSelected = `${method}+${order}, relevance+desc, title.${this.$route.query.locale}+asc`;
        return this.sortSelected;
      },
      isSortSelectedLabelActive(label) {
        if (label === this.sortSelectedLabel) return true;
        return false;
      },
      isSortSelectedLabelInDropdown() {
        if (this.sortSelectedLabel === this.$t('message.sort.nameAZ')
          || this.sortSelectedLabel === this.$t('message.sort.nameZA')
          || this.sortSelectedLabel === this.$t('message.sort.lastCreated')) {
          return true;
        }
        return false;
      }
      // autocomplete(query) {
      //   this.autocompleteQuery(query)
      //     .then((response) => {
      //       this.autocompleteData.suggestions = [];
      //       const suggestions = response.data.result;
      //       const displayedSuggestions = [];
      //       for (const ds of suggestions.results) {
      //         displayedSuggestions.push(ds);
      //       }
      //       this.autocompleteData.suggestions = displayedSuggestions;
      //       this.autocompleteData.show = query.length !== 0;
      //     })
      //     .catch(() => {});
      // }
    },
    watch: {
      sortSelected: {
        handler(sort) {
          this.$router.replace({ query: Object.assign({}, this.$route.query, { sort }) })
            .catch(error => {});
          this.setSort(sort);
        },
        deep: true,
      }
    },
    created() {
      this.initQuery();
      this.$nextTick(() => {
        this.initSort();
      });
    }
  }
  </script>

<style lang="scss" scoped>
  .datasets-filter {
    @apply flex justify-between items-center;

    .datasets-search {
      @apply relative w-64;

      &--input {
        @apply border-primary-700 border-2 p-4 text-primary-700 w-full bg-primary-50;

        &:focus {
          @apply bg-white;
        }
      }
      &--icon {
        @apply absolute top-5 right-4 z-10 cursor-pointer;
      }
    }

    .datasets-filter--sort {
      @apply relative inline-block;

      &:hover {
        .datasets-sort--list {
          @apply block;
        }
        .less {
          @apply block;
        }
        .expand {
          @apply hidden;
        }
      }

      .less {
        @apply hidden;
      }

      h5 {
        @apply text-primary-700 text-2xl cursor-pointer flex justify-end items-center;

        i {
          @apply ml-4;
        }
      }

      .datasets-sort--button {
        @apply text-primary-700 text-xl cursor-pointer flex items-center;
      }
      .datasets-sort--list {
        @apply hidden absolute w-48 right-auto left-auto bg-white;

        ul {
          @apply px-4 py-2;

          li {
            @apply cursor-pointer border-b-2 border-primary-100 mb-2 pb-2 text-primary-700;

            &:hover {
              @apply border-primary-700;
            }

            &:last-child {
              @apply mb-0;
            }
          }
        }
      }
    }
  }
</style>
