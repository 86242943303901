<template>
    <div>
        <!-- RESOURCE DETAILS ADDITIONAL HEADING -->
        <div class="d-flex col-12 mb-2 heading">
            <div class="d-flex my-auto text-right arrow resource-feature-header-arrow-container" @click="toggleFeature()">
                <i class="material-icons resource-feature-header-arrow">{{ !infoVisible ? "keyboard_arrow_down" : "keyboard_arrow_up" }}</i>
            </div>
            <div>
                <span class="resource-feature-title">{{ title }}</span>
            </div>
        </div>
        <!-- RESOURCE DETAILS ADDITIONAL CONTENT -->
        <div v-if="infoVisible">
            <div class="position-relative resource-item additional-information">
                <table class="table table-borderless table-responsive" ref="resourceProperties">
                    <tr class="col-12" v-for="key in featureKeys">
                        <td class="col-4">{{ key }}</td>
                        <td class="col-8">{{ getDetails[key] }}</td>
                    </tr>
                </table>
            </div>
        </div>
    
    </div>
</template>
  
<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceDetailsStore } from "@/store/resourceDetailsStore";

export default {
    name: "ResourceDetailsAdditional",
    props: {
        title: String,
        featureKeys: Array,
    },
    data() {
        return {
            infoVisible: true,
        };
    },
    computed: {
    ...mapStores(useResourceDetailsStore),
    ...mapState(useResourceDetailsStore, [
        'getDetails', 
    ]),
},
    methods: {
        toggleFeature() {
            this.infoVisible = !this.infoVisible;
        },
    }
}
</script>
  
<style lang="scss" scoped>
.arrow {
    cursor: pointer;
}

table {
    background: #F5F5F5;
}

.resource-feature-header-arrow-container {
    cursor: pointer;
}
.arrow {
    padding-right: 0;
}
@media screen and (max-width: 991px) {
    .resource-feature-header-arrow-container {
    width: 48px !important;
    order: 2;
    padding-right: 15px;
    }
}
</style>
  