<template>
  <!-- Component for a collapsible facet -->
  <div class="list-group" role="group" :aria-labelledby="myTitleId" >
    <template v-if="header">
      <facet-title
        :title="header"
        :tooltip="toolTipTitle"
        :title-id="myTitleId"
      />
    </template>
    <div
      :id="myListId"
      class="select-container">
      <div class="select-container--button" @click="toggleDropdown()">
        <span>{{ getSelectedFacet }}</span>
        <i class="material-icons expand" v-show="!dropdown">expand_more</i>
        <i class="material-icons less" v-show="dropdown">expand_less</i>
      </div>
      <div class="select-container--content" v-show="dropdown">
        <ul>
          <template v-if="items && items.length > 0">
            <li
              v-for="(item, index) in items.slice(0, numItemsAllowed)"
              :key="`field@${index}`"
            >
              <div
                class=""
                :count="getFacetCount(item)"
                :hide-count="fieldId === 'dataScope'"
                :class="{active: facetIsSelected(fieldId, item)}"
                @click="facetClicked(fieldId, item)"
              >
                <input type="checkbox" :checked="facetIsSelected(fieldId, item)" />
                {{ getFacetTranslationWrapper(fieldId, item.id, $route.query.locale, item.title) }}
              </div>
            </li>
            <button
              v-if="items.length > minItems"
              class="select-container--expand"
              @click="handleGrowToggle"
            >
              <i class="material-icons align-bottom expand-more animated">{{ isGrown ? 'expand_less' : 'expand_more' }}</i>
            </button>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import DatasetsFacetsItem from "./DatasetsFacetsItem.vue";
import FacetTitle from "./FacetTitle.vue";
export default {
  name: 'ExpandableSelectFacet',
  components: {FacetTitle, DatasetsFacetsItem},
  props: {
    header: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    toolTipTitle: {
      type: String,
      default: '',
    },
    fieldId: {
      type: String,
      default: '',
    },
    getFacetTranslationWrapper: Function,
    facetIsSelected: Function,
    facetClicked: Function
  },
  data() {
    return {
      id: null,
      isExpanded: false,
      isGrown: false,
      numItemsAllowed: this.$env.content.datasets.facets.MIN_FACET_LIMIT || 5,
      minItems: this.$env.content.datasets.facets.MIN_FACET_LIMIT || 5,
      maxItems: this.$env.content.datasets.facets.MAX_FACET_LIMIT || 50,
      dropdown: false
    };
  },
  computed: {
    myId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-${this.id}`;
    },
    myListId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-list-${this.id}`;
    },
    myTitleId() {
      // Use Vue generated uid to set give each facet a unique id
      return `facet-title-${this.id}`;
    },
    getSelectedFacet() {
      const selectedFacet = this.items.filter(item => this.facetIsSelected(this.fieldId, item)).map(item => item.title).join(', ');

      return selectedFacet ? selectedFacet : 'Select';
    },
  },
  methods: {
    handleGrowToggle() {
      this.isGrown = !this.isGrown;
      this.numItemsAllowed = this.isGrown
        ? this.maxItems
        : this.minItems;
    },
    getFacetCount(facet) {
      if (this.fieldId === 'scoring') return '';
      return facet.count;
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    }
  }
};

</script>

<style lang="scss" scoped>
  .select-container {
    @apply relative inline-block w-full mb-16;

    .select-container--button {
      @apply text-white cursor-pointer flex items-center select-none p-2 w-full justify-between bg-primary-600;

      &:hover {
        @apply bg-primary-300 transition-all duration-150;
      }
    }
    .select-container--content {
      @apply w-full text-left bg-white;

      .select-container--expand {
        @apply w-full pt-2;
      }

      ul {
        @apply w-48 w-full;

        li {
          @apply cursor-pointer border-b-2 border-primary-100 p-2 text-primary-700 w-full text-left;

          &:hover {
            @apply bg-primary-100 transition-all duration-150;
          }

          &:last-child {
            @apply mb-0 border-b-0;
          }

          // .active {
          //   @apply text-red-600;
          // }
        }
      }
    }
  }

.facet-header {
  // background-color: #fffade;
  // background-color: rgba(255, 204, 0, 0.1);
  background-color: rgba(0, 29, 133,0.05);
  // background-color: #cddbe8;

  // background-color: #001d85;
  // color: white !important;
}
.tooltip-icon {
  font-size: 15px;
}

@media (min-width: 768px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.list-item-container {
  margin-bottom: -1px;
}

.btn-color {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover {
    background-color: #196fd2;
    border-color: #196fd2;
  }
}
</style>
