<template>
  <div class="app">
    <div class="app-snackbar position-fixed fixed-bottom m-3 m-md-5 py-5 d-flex justify-content-center w-100">
      <app-snackbar></app-snackbar>
    </div>
    <vue-progress-bar></vue-progress-bar>
    <div class="site-wrapper">
      <possible-header
        :enable-authentication="authEnabled"
        :authenticated="isAuthenticated"
        :useLanguageSelector="useLanguageSelector"
        @login="login"
        @logout="logout"
      />
      <router-view
        class="content"
        :key="`${$route.fullPath}`"
      />
      <possible-footer></possible-footer>
    </div>
    <dpi-menu v-if="keycloak && keycloak.authenticated"></dpi-menu>
  </div>

</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters, mapActions } from 'vuex';
import { isNumber } from 'lodash';

import {
  DpiMenu,
  usePiwikSuspendFilter,
} from '@piveau/piveau-hub-ui-modules';
import PossibleHeader from '@/components/header-footer/Header.vue';
import PossibleFooter from '@/components/header-footer/Footer.vue';

export default {
  name: 'app',
  components: {
    PossibleHeader,
    PossibleFooter,
    DpiMenu,
  },
  mixins: [
    usePiwikSuspendFilter,
  ],
  metaInfo() {
    return {
      titleTemplate(chunk) {
        return chunk ? `${chunk} - ${this.$env.metadata.title}` : this.$env.metadata.title;
      },
      meta: [
        { name: 'description', vmid: 'description', content: this.$env.metadata.description },
        { name: 'keywords', vmid: 'keywords', content: this.$env.metadata.keywords },
      ],
      htmlAttrs: {
        lang: this.$route.query.locale,
      },
    };
  },
  data() {
    return {
      keycloak: this.$keycloak,
      useLanguageSelector: this.$env.languages.useLanguageSelector,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'securityAuth',
      'getRTPToken',
      'getKeycloak',
    ]),
    authEnabled() {
      return this.$env?.authentication?.useService && this.$env.authentication?.login?.useLogin;
    },
    isAuthenticated() {
      return this.authEnabled && this.keycloak?.authenticated;
    }
  },
  methods: {
    ...mapActions('auth', [
      'authLogin',
      'authLogout',
      'rtpToken',
      'setKeycloak',
    ]),
    isNumber,
    resume() {
      if (typeof this.$piwik?.resume === "function") this.$piwik.resume();
    },
    login() {
      this.$keycloak.loginFn();
    },
    logout() {
      this.$keycloak.logoutFn();
    },
    handleFollowClick(url) {
      if (typeof this.$piwik?.resume === "function") this.$piwik.trackOutlink(url);
    },
  },
};
</script>

<style lang="scss">
@import './styles/utils/normalize.css';

* {
  box-sizing: border-box;
}

.site-wrapper header {
  display: initial;
}

.container-fluid {
  max-width: 1340px !important;
}
</style>

<style lang="scss" scoped>

.app {
  @apply bg-primary-50;
}

.site-wrapper {
  border: 0;
  max-width: none;
  box-shadow: none;
  display: initial;
}

.app-snackbar {
  z-index: 9999;
  pointer-events: none;
}
</style>
