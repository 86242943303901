<template>
  <div>
    <div class="dataset-header">
      <img v-if="showCountryFlag(getCountry)" class="mr-2 border border-dark flag" :class="{ 'io': getCountry.id === 'io' }" :src="getCountryFlagImg(getCountry.id)" alt="Catalog Flag">
      <!--         :title="$t('message.tooltip.datasetDetails.catalogue')" -->
      <app-link
        :to="getCatalogLink(getCatalog)"
        data-toggle="tooltip"
        data-placement="top">
        {{ getTranslationFor(getCatalog.title, $route.query.locale, getLanguages) }}
      </app-link>
    </div>

    <div class="dataset-keywords-wrapper" v-if="getKeywords.length">
      <div class="dataset-keywords-label">
        Keywords ({{getKeywords.length}})
      </div>
      <hr>
      <div class="dataset-keywords">
        <div class="dataset-keyword" v-for="keyword in getKeywords">
            {{keyword.label}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { has, isNil } from "lodash";
import { mapGetters } from "vuex";

import { AppLink, getTranslationFor, getCountryFlagImg } from '@piveau/piveau-hub-ui-modules';
import ResourceDetailsFeature
  from '@/components/gaiax/ResourceDetailsFeature.vue';

export default {
  name: "DatasetDetailsHeaderCatalogue",
  components: { ResourceDetailsFeature, AppLink},
  computed: {
    ...mapGetters('datasetDetails', [
      'getCatalog',
      'getCountry',
      'getLanguages',
      'getKeywords',
    ]),
  },
  methods: {
    getTranslationFor,
    getCountryFlagImg,
    showCountryFlag(country) {
      return has(country, 'id') && !isNil(country.id);
    },
    getCatalogLink(catalog) {
      return { name: 'Datasets', query: { locale: this.$route.query.locale, catalog: catalog?.id } };
    }
  }
}
</script>

<style scoped lang="scss">
  .dataset-header {
    @apply flex items-center text-primary-400;
    img {
      @apply mr-4;
    }
  }

  .dataset-keywords-wrapper {
    @apply pr-16 my-10;

    hr {
      @apply border-t-2 my-2;
    }
  }

  .dataset-keywords-label {
    @apply text-primary-400 text-sm;
  }
  .dataset-keywords {
    @apply flex text-primary-700 gap-6;
  }

  .dataset-keyword {
    @apply border-b-2 border-primary-700 py-2;
  }

  .flag {
    max-width: 30px;
    max-height: 19px;
  }
</style>
