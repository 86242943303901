<template>
  <app-link class="text-decoration-none" :to="createLinkedDataURL()" target="_blank" >
    <div>{{ text }}</div>
  </app-link>
</template>

<script>
  import { AppLink } from '@piveau/piveau-hub-ui-modules';

  export default {
    name: 'ResourceDetailsLinkedDataButton',
    components: {
      AppLink,
    },
    data() {
      return {};
    },
    props: {
      text: {
        type: String,
        default: '',
      },
      format: {
        type: String,
        default: '',
        required: true,
      },
      resourcesId: {
        type: String,
        default: '',
        required: true,
      },
      resources: {
        type: String,
        default: '',
        required: true,
      },
    },
    computed: {},
    methods: {
      createLinkedDataURL() {
        if (this.resources === 'distributions') {
          return `${this.$env.api.hubUrl}distributions/${this.resourcesId}.${this.format}`;
        } if (this.resources === 'datasets') {
          return `${this.$env.api.hubUrl}datasets/${this.resourcesId}.${this.format}?useNormalizedId=true&locale=${this.$route.query.locale}`;
        } if (this.resources === 'catalogues') {
          return `${this.$env.api.hubUrl}catalogues/${this.resourcesId}.${this.format}`;
        }
        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
