var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catalogs-wrapper"},[_c('div',{staticClass:"catalogs-container"},[_c('div',{staticClass:"catalogs-container--filter"},[(_vm.useCatalogFacets)?_c('catalogues-facets'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"catalogs-container--sets"},[_c('div',{staticClass:"catalogs-container--header"},[_c('h1',[_vm._v(_vm._s(_vm.$t('message.header.navigation.data.catalogs')))]),_vm._v(" "),_c('datasets-filters'),_vm._v(" "),_c('div',{staticClass:"catalogs-infobar",attrs:{"role":"status"}},[_c('div',[_vm._v("\n            "+_vm._s(_vm.getLoading ? _vm.$t('message.catalogs.loadingMessage'):((_vm.$t('message.catalogs.countMessage')) + " (" + (_vm.getCatalogsCount.toLocaleString('fi')) + ")"))+"\n          ")]),_vm._v(" "),(_vm.getLoading)?_c('div',{staticClass:"loading-spinner ml-3"}):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"dataset-container--items"},[_vm._l((_vm.getCatalogs),function(catalog){return _c('pv-data-info-box',{key:("data-info-box@" + (catalog.id)),staticClass:"catalogs-box",attrs:{"catalog-mode":"","to":{
            path: ("/catalogues/" + (catalog.id) + "?locale=en"),
            query: {
              locale: _vm.$route.query.locale
            },
          },"src":_vm.getImg(_vm.getCatalogImage(catalog)),"dataset":{
            title: _vm.getTranslationFor(catalog.title, _vm.$route.query.locale, _vm.getCatalogLanguages(catalog)),
            description:
              _vm.getTranslationFor(catalog.description, _vm.$route.query.locale, _vm.getCatalogLanguages(catalog)),
            catalog: _vm.getTranslationFor(catalog.title, _vm.$route.query.locale, _vm.getCatalogLanguages(catalog)),
            createdDate: null,
            updatedDate: null,
            formats: [],
          },"description-max-length":1000,"data-cy":("catalog@" + (catalog.id))}})}),_vm._v(" "),(_vm.getLoading)?_c('div',{staticClass:"loading-spinner mx-auto mt-3 mb-3"}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"catalogs-container--pagination"},[_c('pagination',{staticClass:"mt-3",attrs:{"items-count":_vm.getCatalogsCount,"items-per-page":_vm.getLimit,"get-page":_vm.getPage,"get-page-count":_vm.getPageCount},on:{"setPageLimit":_vm.setPageLimit}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }