<template>
  <div class="facet-title">
    <h2 :id="titleId">{{ title }}</h2>
    <i v-if="tooltip"
       class="tooltip-icon material-icons"
       ref="tooltip-icon"
       data-toggle="tooltip"
       data-placement="right"
       :title="tooltip">
      help_outline
    </i>
  </div>
</template>

<script>


export default {
  name: "FacetTitle",
  props: {
    title: String,
    tooltip: String,
    titleId: {
      type: String,
      default: null,
    },
  }
}
</script>

<style scoped lang="scss">
.facet-title {
  @apply text-white flex items-center border-b-2 border-white pb-4 mb-4;

  i {
    @apply ml-2;
  }
}

.tooltip-icon {
  font-size: 15px;
}
</style>
