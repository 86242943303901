<template>
  <div class="selected">
    <h2>{{ $t('message.datasetDetails.subnav.categories') }}</h2>
    <div class="selected-container">
      <div class="selected-facet" v-for="(category, i) in getCategories" :key="i">
        <app-link :to="getCategoryLink(category)" v-if="showCategory(category)">
          {{ getTranslationFor(category.title, $route.query.locale) }}
        </app-link>
      </div>
      <div v-if="getCategories && getCategories.length === 0">
        <p>{{ $t('message.datasetCategories.noCategories') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { has } from 'lodash-es';
  import { getTranslationFor } from '../utils/helpers';
  import AppLink from "../widgets/AppLink.vue";

  export default {
    name: 'datasetDetailsCategories',
    dependencies: 'DatasetService',
    components: {
      appLink: AppLink,
    },
    metaInfo() {
      return {
        title: this.$t('message.metadata.categories'),
        meta: [
          {
            name: 'description',
            vmid: 'description',
            content: (`${this.$t('message.metadata.categories')} - ${this.getTranslationFor(this.getTitle, this.$route.query.locale, this.getLanguages)} - ${this.$env.metadata.description}`).substr(0, 4999),
          },
          {
            name: 'keywords',
            vmid: 'keywords',
            content: this.getKeywords.map(k => k.title).join(' ').substring(0, 4999),
          },
        ],
      };
    },
    data() {
      return {};
    },
    computed: {
      // import store-getters
      ...mapGetters('datasetDetails', [
        'getCategories',
        'getKeywords',
        'getLanguages',
        'getTitle',
      ]),
    },
    methods: {
      has,
      getTranslationFor,
      // import store-actions
      ...mapActions('datasetDetails', [
        'loadDatasetDetails',
        'useService',
      ]),
      showCategory(category) {
        return has(category, 'id');
      },
      getCategoryLink(category) {
        const categoryID = category.id.toUpperCase();
        return { path: `/catalogues/${categoryID}`, query: Object.assign({}, { locale: this.$route.query.locale }) };
      },
    },
    created() {
      this.useService(this.DatasetService);
      this.$nextTick(() => {
        this.$Progress.start();
        this.loadDatasetDetails(this.$route.params.ds_id)
          .then(() => {
            this.$Progress.finish();
          })
          .catch(() => {
            this.$Progress.fail();
            this.$router.replace({
              name: 'NotFound',
              query: { locale: this.$route.query.locale, dataset: this.$route.params.ds_id },
            });
          });
      });
    },
  };
</script>

<style scoped lang="scss">
  .selected {
    h2 {
      @apply text-primary-700 text-4xl mb-8 mt-16;
    }
    .selected-container {
      @apply flex flex-wrap gap-2 w-full;

      .selected-facet {
        @apply border-b-2 border-primary-700 py-3 px-3 max-w-max cursor-default;

        .selected-facet--header {
          @apply mb-1;
        }

        a {
          @apply px-2 py-2;
        }

        &:hover {
          @apply bg-primary-700 text-white transition-all duration-150;
        }
      }
    }
  }

.tag-color {
  background-color: var(--tag-color);
}
.keywords__item > div {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
</style>
