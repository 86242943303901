<template>
    <div>
        <!-- RESOURCE DETAILS FEATURE HEADING -->
        <div class="d-flex col-12 mb-2 heading">
            <div class="d-flex my-auto text-right arrow resource-feature-header-arrow-container" @click="toggleFeature()">
                <i class="material-icons resource-feature-header-arrow">{{ !featureVisible ? "keyboard_arrow_down" : "keyboard_arrow_up" }}</i>
            </div>
            <div>
                <span class="resource-feature-title">{{ title }}</span>
            </div>
        </div>
        <!-- RESOURCE DETAILS FEATURE CONTENT -->
        <!-- TODO: Implement generic component for special resource details properties (RESOURCE DETAILS FEATURES) -->
        <div class="d-flex col-12" v-if="featureVisible">
            <div>{{ getDetails[featureKey] }}</div>
        </div>
    
    </div>
</template>
  
<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceDetailsStore } from "@/store/resourceDetailsStore";

export default {
    name: "ResourceDetailsFeature",
    props: {
        id: String,
        title: String,
        featureKey: String,
    },
    data() {
        return {
            featureVisible: true,
        };
    },
    computed: {
        ...mapStores(useResourceDetailsStore),
        ...mapState(useResourceDetailsStore, [
            'getDetails', 
        ]),
    },
    methods: {
        toggleFeature() {
            this.featureVisible = !this.featureVisible;
        },
    }
}
</script>
  
<style lang="scss" scoped>
    .resource-feature-header-arrow-container {
        cursor: pointer;
    }
    .arrow {
        padding-right: 0;
    }
    @media screen and (max-width: 991px) {
        .resource-feature-header-arrow-container {
        width: 48px !important;
        order: 2;
        padding-right: 15px;
        }
    }
</style>
  