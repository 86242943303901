<template>
  <base-page>
  <h1>Impressum</h1>

      <p>
        Das Fraunhofer-Institut für Offene Kommunikationssysteme FOKUS<br/>
        Kaiserin-Augusta-Allee 31<br/>
        10589 Berlin<br/>
        Tel: +49 30 3463-7000<br/>
        <br/>
        info@fokus.fraunhofer.de
        <br/><br/>
        ist eine rechtlich nicht selbstständige Einrichtung der
        <br/><br/>
        Fraunhofer-Gesellschaft<br/>
        zur Förderung der angewandten Forschung e.V.<br/>
        Hansastraße 27 c<br/>
        80686 München<br/>
        <br/>
        http://www.fraunhofer.de
      </p>
      <p>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a<br/>
        Umsatzsteuergesetz: DE 129515865<br/>
        <br/>
        Registergericht<br/>
        Amtsgericht München<br/>
        Eingetragener Verein<br/>
        Register-Nr. VR 4461<br/>
        <br/>
        Verantwortlicher Redakteur:<br/>
        fabian.kirstein@fokus.fraunhofer.de<br/>
        <br/>
        Hinweis: Bei allgemeinen Fragen zum Institut, wenden Sie sich bitte an die unter Kontakt angegebenen Ansprechpartner<br/>
        <br/>
        Vorstand<br/>
        <br/>
        Prof. Dr.-Ing. Reimund Neugebauer | Präsident<br/>
        Prof. Dr. Alexander Kurz | Mitglied des Vorstands<br/>
        Prof. Dr. Axel Müller-Groeling | Mitglied des Vorstands<br/>
        Ass. jur. Elisabeth Ewen | Mitglied des Vorstands<br/>
        Dr. Sandra Krey | Mitglied des Vorstands<br/>
      </p>
      <h2>Nutzungsrechte</h2>
      <p>
        Copyright © by<br/>
        Fraunhofer-Gesellschaft<br/>
        <br/>
        Alle Rechte vorbehalten.<br/>
        Die Urheberrechte dieser Web-Site liegen vollständig bei der Fraunhofer-Gesellschaft.<br/>
        <br/>
        Ein Download oder Ausdruck dieser Veröffentlichungen ist ausschließlich für den persönlichen Gebrauch gestattet. Alle darüber hinaus gehenden Verwendungen, insbesondere die kommerzielle Nutzung und Verbreitung, sind grundsätzlich nicht gestattet und bedürfen der schriftlichen Genehmigung. Anfragen richten Sie Bitte an folgende Adresse:<br/>
        <br/>
        Das Fraunhofer-Institut für Offene Kommunikationssysteme FOKUS<br/>
        Kaiserin-Augusta-Allee 31<br/>
        10589 Berlin<br/>
        Tel: +49 30 3463-7000<br/>
        info@fokus.fraunhofer.de<br/>
        <br/>
        Ein Download oder Ausdruck ist darüber hinaus lediglich zum Zweck der Berichterstattung über die Fraunhofer-Gesellschaft und Ihrer Institute nach Maßgabe unten stehender Nutzungsbedingungen gestattet:<br/>
        <br/>
        Grafische Veränderungen an Bildmotiven- außer zum Freistellen des Hauptmotivs - sind nicht gestattet. Es ist stets die Quellenangabe und Übersendung von zwei kostenlosen Belegexemplaren an die oben genannte Adresse erforderlich. Die Verwendung ist honorarfrei.
      </p>
      <h2>Haftungshinweis</h2>
      <p>
        Wir übernehmen keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        <br/><br/>
        Wir sind bemüht, das Webangebot stets aktuell und inhaltlich richtig sowie vollständig anzubieten. Dennoch ist das Auftreten von Fehlern nicht völlig auszuschließen. Das Fraunhofer-Institut bzw. die Fraunhofer-Gesellschaft übernimmt keine Haftung für die Aktualität, die inhaltliche Richtigkeit sowie für die Vollständigkeit der in ihrem Webangebot eingestellten Informationen. Dies bezieht sich auf eventuelle Schäden materieller oder ideeller Art Dritter, die durch die Nutzung dieses Webangebotes verursacht wurden.
        <br/><br/>
        Geschützte Marken und Namen, Bilder und Texte werden auf unseren Seiten in der Regel nicht als solche kenntlich gemacht. Das Fehlen einer solchen Kennzeichnung bedeutet jedoch nicht, dass es sich um einen freien Namen, ein freies Bild oder einen freien Text im Sinne des Markenzeichenrechts handelt.
      </p>
  </base-page>
</template>

<script>
import BasePage from './BasePage.vue';

export default {
  components: { BasePage },

}
</script>

<style>

</style>