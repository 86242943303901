<template>
  <app-link
    class="nav-link"
    :to="{ path, query: Object.assign({}, { locale: $route.query.locale }) }"
    :exact="exact"
  >
    <!-- :title="tooltip" -->
    <span
          data-toggle="tooltip"
          data-placement="top">
            {{ title }}
    </span>
  </app-link>
</template>

<script>
import AppLink from '../widgets/AppLink';
export default {
  name: "DatasetDetailsNavigationPage",
  components: {AppLink},
  props: {
    title: String,
    tooltip: String,
    path: String,
    exact: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .nav-link {
    @apply border-b-2 border-primary-700 py-3 px-3;

    &:hover {
      @apply bg-primary-700 text-white transition-all duration-150;

      &.active {
        color: white !important;
      }
    }
  }
</style>
