<template>
  <div class="d-flex flex-column p-0 bg-transparent">
    <div class="container-fluid mt-5 mb-5 pt-1 content resource-content">
      <resource-details-header></resource-details-header>
      <resource-details-description></resource-details-description>
      <hr>
      <resource-details-properties></resource-details-properties>
    </div>
  </div>
</template>

<script>
import { mapState, mapStores, mapActions } from "pinia";
import { useResourceDetailsStore } from "@/store/resourceDetailsStore";

import ResourceDetailsHeader from './ResourceDetailsHeader'
import ResourceDetailsDescription from './ResourceDetailsDescription'
import ResourceDetailsProperties from './ResourceDetailsProperties'

export default {
  name: "ResourceDetailsPage",
  components: {
    ResourceDetailsHeader,
    ResourceDetailsDescription,
    ResourceDetailsProperties,
  },
  data() {
      return {
        resources: this.$env.content.resources,
      }
  },
  computed: {
    ...mapStores(useResourceDetailsStore),
    ...mapState(useResourceDetailsStore, [
      'getResourceType', 
    ]),
    baseUrl() {
      return this.$env.api.baseUrl;
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions(useResourceDetailsStore, [
      'setResourceType',
      'loadDetails',
    ]),
    setNewResource() {
      Object.keys(this.resources).forEach(resourceType => {
        if (this.$route.name.toLowerCase().startsWith(resourceType.toLowerCase())) {
          this.setResourceType(resourceType);
        }
      });
    }
  },
  created () {
    this.$nextTick(() => {
      this.$Progress.start();
      this.setNewResource();
      this.loadDetails(this.baseUrl, this.resources[this.getResourceType].details.endpoint, this.id)
        .then(() => {
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.fail();
        });
    });
  },
};
</script>