<template>
  <div class="distribution">
    <div class="distrubtion-wrapper">
      <div class="distrubtion-item">
        <distribution-details
          :getDistributionTitle="getDistributionTitle"
          :distribution="distribution"
          :distributions="distributions"
          :distributionDescriptionIsExpanded="distributionDescriptionIsExpanded"
          :getDistributionDescription="getDistributionDescription"
          :toggleDistributionDescription="toggleDistributionDescription"
          :distributionDescriptionIsExpandable="distributionDescriptionIsExpandable"
          :distributionIsExpanded="distributionIsExpanded"
          :showLicensingAssistant="showLicensingAssistant"
          :showLicence="showLicence"
          :filterDateFormatEU="filterDateFormatEU"
          :showArray="showArray"
          :showNumber="showNumber"
          :showObject="showObject"
          :showObjectArray="showObjectArray"
          :appendCurrentLocaleToURL="appendCurrentLocaleToURL"
          :distributionCanShowMore="distributionCanShowMore"
          :toggleDistribution="toggleDistribution"
        />
      </div>
      <div class="distrubtion-item">
        <distribution-format
          :distribution="distribution"
          :getDistributionFormat="getDistributionFormat"
          :distributionFormatTruncated="distributionFormatTruncated"
        />
      </div>
      <div class="distrubtion-item">
        <distribution-licence
          :distribution="distribution"
          :getDistributionLicence="getDistributionLicence"
        />
      </div>
      <div class="distrubtion-item">
        <distribution-added
          :date="addedDate"
        />
      </div>
      <div class="distrubtion-item">
        <distribution-actions
          :distribution="distribution"
          :distributions="distributions"
          :isUrlInvalid="isUrlInvalid"
          :getVisualisationLink="getVisualisationLink"
          :showTooltipVisualiseButton="showTooltipVisualiseButton"
          :previewLinkCallback="previewLinkCallback"
          :openIfValidUrl="openIfValidUrl"
          :showDownloadDropdown="showDownloadDropdown"
          :getDownloadUrl="getDownloadUrl"
          :showAccessUrls="showAccessUrls"
          :isOnlyOneUrl="isOnlyOneUrl"
          :trackGoto="trackGoto"
          :getDistributionFormat="getDistributionFormat"
          :replaceHttp="replaceHttp"
        />
      </div>
    </div>
    <div class="distribution-more">
      <div class="distribution-more--button" @click="moreContent = !moreContent">
        <button>
          <span v-show="!moreContent">Show more</span>
          <span v-show="moreContent">Show less</span>
          <i class="material-icons" v-show="!moreContent">expand_more</i>
          <i class="material-icons" v-show="moreContent">expand_less</i>
        </button>
      </div>
      <div class="distribution-more--content" v-show="moreContent">
        <div class="distribution-more--licence">
          <div class="licence-wrapper">
            <h3>
              Distribution Details
            </h3>
            <div class="licence-content">
              <div class="detail-wrapper">
                <div class="detail-label">
                  Licence
                </div>
                <div class="detail-content" v-if="distribution.licence && distribution.licence.la_url && distribution.licence.description" >
                  <a :href="distribution.licence.la_url" target="_blank" rel="noopener">{{ distribution.licence.description }}</a> ({{ distribution.licence.id }})
                </div>
                <div class="detail-content" v-else-if="distribution.licence && distribution.licence.description">
                  <span>{{ distribution.licence.description }} ({{ distribution.licence.id }})</span>
                </div>
              </div>
              <div class="detail-wrapper">
                <div class="detail-label">
                  Bytesize
                </div>
                <div class="detail-content">
                  {{ distribution.byteSize }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="distribution-more--policy">
          <h3>Readonly Policy ODRL of Data Resource</h3>
          <textarea v-model="policy" disabled></textarea>
        </div>
        <div class="connector-details" v-if="datasetDetails !== null">
          <h3>
            Connector Details
          </h3>
          <div class="detail-wrapper">
            <div class="detail-label">
              Produced By
            </div>
            <div class="detail-content" v-if="datasetDetails.produced_by !== null">
              {{ datasetDetails.produced_by }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Exposed through
            </div>
            <div class="detail-content" v-if="datasetDetails.exposed_through !== null">
              {{ datasetDetails.exposed_through[0] }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Obsolete Datetime
            </div>
            <div class="detail-content" v-if="datasetDetails.obsolete !== null">
              {{ datasetDetails.obsolete }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Expiration Datetime
            </div>
            <div class="detail-content" v-if="datasetDetails.expiration !== null">
              {{ datasetDetails.expiration }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Contains PII
            </div>
            <div class="detail-content" v-if="datasetDetails.contains_pii !== null">
              {{ datasetDetails.contains_pii }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Legal Basis
            </div>
            <div class="detail-content" v-if="datasetDetails.legal_basis !== null">
              {{ datasetDetails.legal_basis }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Contract Offer ID
            </div>
            <div class="detail-content" v-if="datasetDetails.contract_offer_id !== null">
              {{ datasetDetails.contract_offer_id }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Asset ID
            </div>
            <div class="detail-content" v-if="datasetDetails.produced_by !== null">
              {{ datasetDetails.asset_id }}
            </div>
          </div>
          <div class="detail-wrapper">
            <div class="detail-label">
              Policy ID
            </div>
            <div class="detail-content" v-if="datasetDetails.has_poss_x_policy !== null">
              {{ datasetDetails.has_poss_x_policy.uid }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  has,
  isNil
} from 'lodash';

import {
  DistributionExpand, DistributionVisibleContent, DistributionExpandedContent,
  DistributionDescription, FadingDistributionOverlay,
  DistributionAdded, truncate
} from '@piveau/piveau-hub-ui-modules';

import DistributionDetails from './distributionDetails/DistributionDetails';
import DistributionActions from './distributionActions/DistributionActions';
import DistributionFormat from './DistributionFormat';
import DistributionLicence from './DistributionLicence';

export default {
  name: 'Distribution',
  components: {
    DistributionAdded,
    DistributionActions,
    FadingDistributionOverlay,
    DistributionFormat,
    DistributionLicence,
    DistributionDescription,
    DistributionExpandedContent,
    DistributionVisibleContent,
    DistributionExpand,
    DistributionDetails
  },
  props: {
    fading: Boolean,
    distribution: Object,
    distributions: Object,
    setDistributionsDisplayCount: Function,
    openModal: Function,
    getDistributionFormat: Function,
    getDistributionLicence: Function,
    distributionFormatTruncated: Function,
    getDistributionTitle: Function,
    distributionDescriptionIsExpanded: Function,
    distributionDescriptionIsExpandable: Function,
    getDistributionDescription: Function,
    distributionIsExpanded: Function,
    showObject: Function,
    showNumber: Function,
    distributionCanShowMore: Function,
    showDownloadDropdown: Function,
    showLicence: Function,
    showLicensingAssistant: Function,
    filterDateFormatEU: Function,
    showArray: Function,
    showObjectArray: Function,
    getVisualisationLink: Function,
    isOnlyOneUrl: Function,
    getDownloadUrl: Function,
    trackGoto: Function,
    showAccessUrls: Function,
    replaceHttp: Function,
    previewLinkCallback: Function,
    toggleDistribution: Function,
    toggleDistributionDescription: Function,
    increaseNumDisplayedDistributions: Function,
    nonOverflowingIncrementsForDistributions: Function,
    isUrlInvalid: Function,
    openIfValidUrl: Function,
    showTooltipVisualiseButton: Function,
    appendCurrentLocaleToURL: Function,
    datasetDetails: Object
  },
  data() {
    return {
      moreContent: false,
      policy: this.datasetDetails && this.datasetDetails.has_poss_x_policy ? JSON.stringify(this.datasetDetails.has_poss_x_policy, null, 2) : ''
    }
  },
  computed: {
    addedDate() {
      if (has(this.distribution, 'releaseDate') && !isNil(this.distribution.releaseDate)) {
        return this.filterDateFormatEU(this.distribution.releaseDate);
      }
      return "";
    }
  },
  methods: {
    has,
    isNil,
    truncate
  }
};
</script>


<style lang="scss" scoped>
  .distrubtion-wrapper {
    @apply flex items-center mt-2 p-4;

    .distrubtion-item:nth-child(1) {
      @apply w-3/12;
    }
    .distrubtion-item:nth-child(2) {
      @apply w-1/12;
    }
    .distrubtion-item:nth-child(3) {
      @apply w-3/12;
    }
    .distrubtion-item:nth-child(4) {
      @apply w-1/12;
    }
    .distrubtion-item:nth-child(5) {
      @apply w-4/12;
    }
  }

  .distribution-more {
    .distribution-more--button {
      button {
        @apply text-primary-700 cursor-pointer flex items-center max-w-max py-2 px-4 mt-4;

        &:hover {
          @apply bg-primary-700 text-white transition-all duration-150;
        }
      }
    }

    .distribution-more--content {
      @apply mt-8 p-4;

      h3 {
        @apply text-primary-400 text-sm border-b-2 border-primary-100 pb-4 mb-4;
      }

      .distribution-more--licence {
        .licence-wrapper {
          @apply text-primary-700;

          .licence-content {

          }
        }
      }

      .distribution-more--policy {
        @apply mt-16;

        p {
          @apply text-primary-700 mb-4;
        }

        textarea {
          @apply w-full p-8 h-40;

          &:disabled {
            @apply bg-white;
          }
        }
      }

      .detail-wrapper {
        @apply grid gap-3 text-primary-700 text-sm mb-2;
        grid-template-columns: 1fr 2fr;
      }

      .detail-label,
      .detail-content {
        @apply break-all;
      }

      .connector-details {
        @apply mt-16;
      }
    }
  }

.text-break {
  word-break: break-word;
}

td {
  padding-left: 0 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}

/*** BOOTSTRAP ***/
button:focus {
  outline:0;
}
.options, .download {
  .dropdown-menu {
    .dropdown-item {
      &:hover {
        color: initial;
        background-color: initial;
      }
    }
  }
}

.material-icons.small-icon {
  font-size: 20px;
}

.distributions__item {
  //position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


</style>
