<template>
<div v-if="useDatasetFacetsMap && !showCatalogDetails">
  <div class="map-search-container">
    <label class="map-search">
      <input type="text" class="map-search--input" 
        :aria-label="$t('message.datasets.findLocation')"
        :placeholder="$t('message.datasets.findLocation')"
        data-toggle="tooltip"
        data-placement="right"
        v-model="gazetteer.searchbarText"
        @focus="gazetteer.selected = false; gazetteer.searchbarText = ''"
        @input="getAutocompleteSuggestions(gazetteer.searchbarText)"
        @keyup.enter="getAutocompleteSuggestions(gazetteer.searchbarText)">
        <i class="material-icons map-search--icon">search</i>
    </label>
    <div class="suggestion-list-group" v-if="!gazetteer.selected">
      <ul class="list-group suggestion-list">
        <button class="list-group-item list-group-item-action"
          v-for="(suggestion, i) in gazetteer.suggestions.slice(0, 10)"
          :key="i"
          @click="handleSuggestionSelection(suggestion)">
          {{suggestion.name}}
        </button>
      </ul>
    </div>
  </div>
  <div class="map-box">
    <map-bounds-receiver
      class="border-secondary map focus-border"
      :start-bounds="map.receiver.startBounds"
      :height="map.receiver.height"
      :width="map.receiver.width"
      :map-container-id="map.receiver.mapContainerId"
      :bounds-id="map.geoBoundsId"
      data-toggle="tooltip"
      data-placement="top"
      ref="mapReceiver"
    ></map-bounds-receiver>
    <button class="map-box--button__reset" v-if="getGeoBoundsById(map.geoBoundsId)" @click="resetBoundsFor(map.geoBoundsId)">
      Reset Bounds
    </button>
    <button class="map-box--button__fullscreen" data-toggle="modal" data-target=".map-modal" @click="toggleModal()">
      <i class="material-icons">fullscreen</i>
    </button>
  </div>
  <div class="map-box--modal" v-show="consumeModal">
    <div class="map-box--modal--bg" @click="toggleModal()"></div>
    <div class="map-box--modal--box">
      <div class="map-box--modal--box__header">
        <h5>{{ $t('message.mapModal.drawRectangleMsg') }}</h5>
        <button @click="toggleModal()">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="map-box--modal--box__content">
        <map-bounds-sender :start-bounds="map.sender.startBounds"
          :height="map.sender.height"
          :width="map.sender.width"
          :map-container-id="map.sender.mapContainerId"
          :bounds-id="map.geoBoundsId"
          ref="mapSender"></map-bounds-sender>
      </div>
      <div class="map-box--modal--box__actions">
        <button type="button" class="btn btn-danger" @click="toggleModal()">{{ $t('message.mapModal.close') }}</button>
        <button type="button" class="btn btn-highlight" data-dismiss="modal" @click="resetBoundsFor(map.geoBoundsId)">{{ $t('message.mapModal.reset') }}</button>
        <button type="button" class="btn btn-primary" @click="applyHoldedBounds()">{{ $t('message.mapModal.findDatasets') }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { MapBoundsReceiver } from '@piveau/piveau-hub-ui-modules';

import MapBoundsSender from '../../maps/MapBoundsSender';

import {isNil} from "lodash";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "DatasetFacetMap",
  dependencies: ['GazetteerService'],
  components: {
    MapBoundsReceiver,
    MapBoundsSender
  },
  props: [
    "showCatalogDetails"
  ],
  data() {
    return {
      useDatasetFacetsMap: this.$env.content.datasets.facets.useDatasetFacetsMap,
      gazetteer: {
        searchbarText: '',
        suggestions: [],
        selected: false,
        bounds: [],
      },
      map: {
        sender: {
          startBounds: this.$env.content.maps.sender.startBounds,
          height: this.$env.content.maps.sender.height,
          width: this.$env.content.maps.sender.width,
          mapContainerId: this.$env.content.maps.sender.mapContainerId,
        },
        receiver: {
          startBounds: this.$env.content.maps.receiver.startBounds,
          height: this.$env.content.maps.receiver.height,
          width: this.$env.content.maps.receiver.width,
          mapContainerId: this.$env.content.maps.receiver.mapContainerId,
        },
        geoBoundsId: this.$env.content.maps.geoBoundsId,
      },
      browser: {
        /* eslint-disable-next-line */
        isIE: /*@cc_on!@*/!!document.documentMode,
      },
      consumeModal: false
    }
  },
  computed: {
    ...mapGetters('gazetteer', [
      'getSuggestions',
    ]),
    ...mapGetters('geo', [
      'getGeoBoundsById',
      'getHoldedGeoBoundsById',
    ]),
    geoStateBoundsWatcher() {
      return this.getGeoBoundsById(this.map.geoBoundsId);
    }
  },
  methods: {
    ...mapActions('gazetteer', [
      'autocomplete',
      'useService',
    ]),
    ...mapActions('geo', [
      'setGeoBoundsForId',
      'resetGeoBoundsForId',
      'resetHoldedGeoBoundsForId',
    ]),
    ...mapActions('datasets', [
      'setDatasetGeoBounds'
    ]),
    toggleModal() {
      this.consumeModal = !this.consumeModal

      if (this.consumeModal) {
        document.body.style = 'overflow: hidden;';
      } else {
        document.body.style = '';
      }
    },
    applyHoldedBounds() {
      const holdedBounds = this.getHoldedGeoBoundsById(this.map.geoBoundsId);
      this.setGeoBoundsForId({
        bounds: holdedBounds,
        boundsId: this.map.geoBoundsId,
      });

      this.toggleModal();
    },
    resetBoundsFor(boundsId) {
      this.$refs.mapSender.resetBounds();
      this.$refs.mapReceiver.resetBounds();
      this.resetGeoBoundsForId(boundsId);
      this.resetHoldedGeoBoundsForId(boundsId);
    },
    getAutocompleteSuggestions(query) {
      if (!query || isNil(query)) this.clearAutocompleteSuggestions();
      else {
        this.autocomplete(query).then(() => {
          this.$nextTick(() => {
            this.gazetteer.suggestions = this.getSuggestions;
          });
        });
      }
    },
    clearAutocompleteSuggestions() {
      this.gazetteer.suggestions = [];
    },
    handleSuggestionSelection(suggestion) {
      this.gazetteer.searchbarText = suggestion.name;
      this.gazetteer.selected = true;
      const location = suggestion.geometry.split(',');
      this.gazetteer.bounds = [[location[1], location[0]], [location[3], location[2]]]
        .map(point => point.map(coord => parseFloat(coord)));
      this.setGeoBoundsForId({
        bounds: this.gazetteer.bounds,
        boundsId: this.map.geoBoundsId,
      });
    },
    triggerResize() {
      if (this.browser.isIE) {
        // Note: Trigger resize after 500ms (IE11 needs longer than modern browsers) in IE11 when Modal element is visible to properly display the map component
        setTimeout(() => {
          const evt = document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        }, 500);
      } else {
        // Note: Trigger resize after 200ms when Modal element is visible to properly display the map component
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 200);
      }
    }
  },
  watch: {
    geoStateBoundsWatcher: {
      deep: true,
      handler(bounds) {
        this.setDatasetGeoBounds(bounds);
      },
    }
  },
  created() {
    this.useService(this.GazetteerService);
  }
}
</script>

<style lang="scss" scoped>
  .map-box {
    @apply my-8 relative;

    .map-box--button__reset {
      @apply absolute bottom-0 left-0 p-2 bg-white text-primary-700 cursor-pointer;

      &:hover {
        @apply bg-primary-400 text-white transition-all duration-150;
      }
    }

    .map-box--button__fullscreen {
      @apply absolute bottom-0 right-0 px-2 pt-2 bg-white text-primary-700 cursor-pointer;

      &:hover {
        @apply bg-primary-400 text-white transition-all duration-150;
      }
    }
  }

  .map-box--modal {
    @apply z-20 fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-50 overflow-hidden;

    .map-box--modal--bg {
      @apply fixed top-0 left-0 w-screen h-screen z-30 cursor-pointer;
    }

    .map-box--modal--box {
      @apply bg-white p-8 z-40 w-[80%] h-[80%];

      .map-box--modal--box__header {
        @apply flex justify-between mb-12;

        h5 {
          @apply text-possible-info text-3xl mr-4;
        }
      }

      .map-box--modal--box__content {
        @apply mb-12 h-[80%];
      }

      .map-box--modal--box__actions {
        @apply flex justify-end;

        a {
          @apply bg-possible-info py-2 px-6 text-white;

          &:hover {
            @apply bg-primary-700 transition-all duration-150;
          }
        }

        button {
          @apply bg-possible-info py-2 px-6 text-white mr-4;

          &:last-child {
            @apply mr-0;
          }

          &:hover {
            @apply bg-primary-700 transition-all duration-150;
          }
        }
      }
    }
  }

  .map-search-container {
    @apply relative;

    .map-search {
      @apply relative w-64;

      &--input {
        @apply border-white border-2 p-4 bg-primary-700 w-full text-white;

        &:focus {
          @apply bg-white text-primary-700;

          + i {
            @apply text-primary-700;
          }
        }
      }
      &--icon {
        @apply absolute top-0 right-4 z-10 cursor-pointer text-white;
      }
    }

    .suggestion-list-group {
      @apply absolute z-10 bg-white w-full;

      .list-group {
        button {
          @apply text-black block text-left p-2 border-primary-700 border-b-2 w-full;

          &:hover {
            @apply bg-primary-200 text-white transition-all duration-150;
          }

          &:last-child {
            @apply border-b-0;
          }
        }
      }
    }
  }

  /*** MATERIAL ICONS ***/
  .material-icons.small-icon {
    font-size: 20px;
  }
</style>
