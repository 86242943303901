/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import { glueConfig as GLUE_CONFIG } from '../config/user-config';
import {
  NotFound,
  DataProviderInterface,
  DataFetchingComponent,
  OverviewPage,
  InputPage,
  DraftsPage,
  LinkedDataViewer,
  UserProfilePage,
  UserCataloguesPage,
  decode,
} from "@piveau/piveau-hub-ui-modules";


import DatasetDetailsCategories from "@/components/modules/datasetDetails/DatasetDetailsCategories";
import Datasets from "@/components/modules/datasets/Datasets.vue";
import DatasetDetails from "@/components/modules/datasetDetails/DatasetDetails.vue";
import DatasetDetailsDataset from "@/components/modules/datasetDetails/DatasetDetailsDataset.vue";

import Catalogues from "@/components/modules/catalogues/Catalogues.vue";

import ResourceSearchPage from "@/components/gaiax/ResourceSearchPage.vue";
import ResourceDetailsPage from "@/components/gaiax/ResourceDetailsPage.vue";

import Imprint from './components/modules/pages/Imprint.vue';
import PrivacyPolicy from './components/modules/pages/PrivacyPolicy.vue';

import Provider from './components/possible/Provider.vue';
import Consumer from './components/possible/Consumer.vue';

Vue.use(Router);

const title = GLUE_CONFIG.metadata.title;

const router = new Router({
  base: GLUE_CONFIG.routing.routerOptions.base,
  mode: GLUE_CONFIG.routing.routerOptions.mode,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some(route => route.meta.scrollTop)) return { x: 0, y: 0 };
    else if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'Datasets', query: { locale: GLUE_CONFIG.languages.locale }},
    },
    {
      path: '/provider',
      name: 'Provider',
      component: Provider,
    },
    {
      path: '/consumer',
      name: 'Consumer',
      component: Consumer,
    },
    {
      path: '/datasets',
      name: 'Datasets',
      component: Datasets,
    },
    {
      path: '/datasets/:ds_id',
      component: DatasetDetails,
      children: [
        {
          path: '',
          name: 'DatasetDetailsDataset',
          components: {
            datasetDetailsSubpages: DatasetDetailsDataset,
          },
        },
        {
          path: 'categories',
          name: 'DatasetDetailsCategories',
          components: {
            datasetDetailsSubpages: DatasetDetailsCategories,
          },
        },
      ],
    },
    {
      path: '/catalogues',
      name: 'Catalogues',
      component: Catalogues,
    },
    {
      path: '/catalogues/:ctlg_id',
      name: 'CatalogueDetails',
      component: Datasets,
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Imprint,
    },
    {
      path: '/privacypolicy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/404',
      alias: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ]
});


// Dynamically add resource routes
Object.keys(GLUE_CONFIG.content.resources).forEach(resourceType => {
  let resourceData = GLUE_CONFIG.content.resources[resourceType];

  router.addRoute({
    path: `/${resourceData.path}`,
    name: resourceData.name,
    component: ResourceSearchPage,
  });

  router.addRoute({
    path: `/${resourceData.details.path}`,
    name: resourceData.details.name,
    component: ResourceDetailsPage,
  });
});

if (GLUE_CONFIG.content.dataProviderInterface.useService) {
  router.addRoute({
    path: '/dpi/draft',
    name: 'DataProviderInterface-Draft',
    component: DraftsPage,
    meta: {
      requiresAuth: true,
    }
  }),
  router.addRoute({
    path: '/dpi/draft/:name.:format',
    name: 'DataProviderInterface-LinkedData',
    component: LinkedDataViewer,
    props: true,
    meta: {
      requiresAuth: true,
    }
  }),
  router.addRoute({
    path: '/dpi/user/',
    name: 'DataProviderInterface-UserProfile',
    component: UserProfilePage,
    meta: {
      requiresAuth: true,
    }
  }),
  router.addRoute({
    path: '/dpi/user-catalogues',
    name: 'DataProviderInterface-UserCatalogues',
    component: UserCataloguesPage,
    meta: {
      requiresAuth: true,
    }
  }),
  router.addRoute({
    path: '/dpi/edit/:catalog/:property/:id',
    name: "DataProviderInterface-Edit",
    component: DataFetchingComponent,
    props: true
  }),
  router.addRoute({
    path: "/dpi",
    name: "DataProviderInterface",
    component: DataProviderInterface,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":property",
        name: "DataProviderInterface-Home",
        redirect: { path: ':property/step1' },
        props: true
      },
      {
        path: ":property/overview",
        name: "DataProviderInterface-Overview",
        component: OverviewPage,
        props: true
      },
      {
        path: ":property/:page",
        name: "DataProviderInterface-Input",
        component: InputPage,
        props: true,
        children: [
          {
            path: ":id",
            name: "DataProviderInterface-ID",
            component: InputPage,
            props: true,
          },
        ],
      },
    ]
  },
  );
}

router.beforeEach((to, from, next) => {
  // Hash mode backward-compatibility
  // Fixes https://gitlab.fokus.fraunhofer.de/viaduct/organisation/issues/432
  if (to?.redirectedFrom?.substring(0, 3) === '/#/') {
    let path = to.redirectedFrom.substring(2);
    const base = `${GLUE_CONFIG.routing.routerOptions.base}/`;
    if (path.startsWith(base)) {
      // Restore standard Vue behavior when navigated to '/#/base'
      // so you are redirected to '/base' instead of '/base/base'
      path = '/';
    }
    next({ path, replace: true });
    return;
  }

  let isLinkedDataRequest = false;

  // RDF|N3|JSON-LD redirects
  if (/^\/(data\/)?datasets\/[a-z0-9-_]+(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/.test(to.path)) {
    isLinkedDataRequest = true;
    let locale = to.query.locale ? `&locale=${to.query.locale}` : '';
    window.location = `${router.app.$env.api.hubUrl}${to.path}?useNormalizedId=true${locale}`;
  }

  if (/^\/(data\/)?api\/datasets\/[a-z0-9-_]+(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/.test(to.path)) {
    isLinkedDataRequest = true;
    let locale = to.query.locale ? `?locale=${to.query.locale}` : '';
    let returnPath = to.path.replace('/api', '')
      .replace(/(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/, '')
      .replace('?useNormalizedId=true', '');
    window.location = `${window.location.protocol}//${window.location.host}${GLUE_CONFIG.routerOptions.base}${returnPath}${locale}`;
  }

  if (isLinkedDataRequest) {
    // Redirect to the same page but without linked data file ending suffix
    // to prevent the 404 redirection due to app trying to fetch the wrong dataset id
    const datasetIdWithoutSuffix = to.params?.ds_id.replace(/(\.rdf|\.n3|\.jsonld|\.ttl|\.nt)/, '');
    const newRoute = { ...to, params: { ...to.params, ds_id: datasetIdWithoutSuffix } };
    next(newRoute);
    return;
  }

  // Authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = router.app.$env.authentication.useService
      ? router.app.$keycloak.authenticated
      : null;
    if (!auth) {
      // TODO: Show unauthorized page here
    } else {
      router.app.$keycloak.getRtpToken().then((rtpToken) => {
        const decodedAccessToken = decode(rtpToken);
        let isAuthenticated = false;
        decodedAccessToken.authorization.permissions.forEach((permission) => {
          if (permission.scopes.find(scope => scope === 'dataset:create')) isAuthenticated = true;
        });
        isAuthenticated
          ? next()
          : next({ name: 'Datasets' });
      });
    }
  } else if (!to.query.locale && from.query.locale) {
    const pathWithCurrentLocale = `${to.path}?locale=${from.query.locale}`;
    next({ path: pathWithCurrentLocale });
  } else {
    document.title = title;
    next();
  }
});

export default router;
